export const setReservationId = (reservationId: string) => {
  return {
    type: "SET_RESERVATION_ID",
    payload: reservationId,
  };
};

export const refreshReservationId = () => {
  return {
    type: "REFRESH_RESERVATION_ID",
  };
};
