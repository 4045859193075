import React from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import styles from "./styles.module.css";
import castlePhoto from "../../../assets/P1060835_hdr.jpg";

const HomePicture: React.FC<{
  purchseTiketsClick?: () => void;
}> = ({ purchseTiketsClick }) => {
  const { t } = useTranslation();
  console.log(purchseTiketsClick);
  return (
    <Box style={{ marginBottom: "-80px" }}>
      <Box className={styles.box}>
        <Box className={styles.overlayPic}></Box>
        <Box className={styles.imageContainer}>
          <img alt="castlePhoto" src={castlePhoto} className={styles.image} />
        </Box>
        <Box className={styles.overlay}>
          <Box className={styles.textContainer}>
            <span
              className={`${styles.text}`}
              dangerouslySetInnerHTML={{ __html: t("homepage_title") }}
            />
            <br />
            <span
              className={`${styles.sub_text}`}
              dangerouslySetInnerHTML={{ __html: t("homepage_sub_title") }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default HomePicture;
