import React from "react";
import {
  Circle,
  ContnentCenter,
  Counter,
  SubWrapper,
  Wrapper,
} from "src/components/atoms/MainImagePagination/styledComponents";

const MainImagePagination = ({
  currentImage,
  fullPageGallery,
}: {
  currentImage: number;
  fullPageGallery: string[];
}): JSX.Element => {
  return (
    <Wrapper>
      <ContnentCenter>
        <SubWrapper>
          <Counter>{`${currentImage + 1}/${fullPageGallery.length}`}</Counter>
        </SubWrapper>
        <SubWrapper>
          {fullPageGallery.map((el, index) => (
            <Circle key={el} isActive={index === currentImage} />
          ))}
        </SubWrapper>
      </ContnentCenter>
    </Wrapper>
  );
};

export default MainImagePagination;
