import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const FlagEN: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon {...props} width="21.368" height="14" viewBox="0 0 21.368 14">
    <svg xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21,102.276H.368A.368.368,0,0,1,0,101.908V88.644a.368.368,0,0,1,.368-.368H21a.368.368,0,0,1,.368.368v13.263A.369.369,0,0,1,21,102.276Z"
        transform="translate(0 -88.276)"
        fill="#41479b"
      />
      <path
        d="M21.368,88.644A.368.368,0,0,0,21,88.276H19.352l-6.825,4.472V88.276H8.842v4.472L2.017,88.276H.368A.368.368,0,0,0,0,88.644V89.6l5.856,3.837H0v3.684H5.856L0,100.955v.953a.368.368,0,0,0,.368.368H2.017L8.842,97.8v4.472h3.684V97.8l6.825,4.472H21a.368.368,0,0,0,.368-.368v-.953l-5.856-3.837h5.856V93.434H15.513L21.368,89.6Z"
        transform="translate(0 -88.276)"
        fill="#f5f5f5"
      />
      <g transform="translate(0 0)">
        <path
          d="M21.368,94.171H11.789V88.276H9.579v5.895H0v2.211H9.579v5.895h2.211V96.381h9.579Z"
          transform="translate(0 -88.276)"
          fill="#ff4b55"
        />
        <path
          d="M7.708,300.138.25,304.979a.364.364,0,0,0,.358.317h.507l7.946-5.158H7.708Z"
          transform="translate(-0.24 -291.296)"
          fill="#ff4b55"
        />
        <path
          d="M315.313,300.138H313.96l7.934,5.15a.365.365,0,0,0,.331-.361v-.3Z"
          transform="translate(-300.857 -291.296)"
          fill="#ff4b55"
        />
        <path
          d="M0,89.876l6.752,4.383H8.105L.215,89.137A.367.367,0,0,0,0,89.47Z"
          transform="translate(0 -89.101)"
          fill="#ff4b55"
        />
        <path
          d="M301.491,93.435l7.476-4.853a.363.363,0,0,0-.356-.305h-.528l-7.946,5.158Z"
          transform="translate(-287.612 -88.277)"
          fill="#ff4b55"
        />
      </g>
    </svg>
  </SvgIcon>
);
