import React from "react";
import styles from "./styles.module.css";
import InformationBlock from "src/components/atoms/InformationBlock";
import { IimageCSS } from "src/core/types/InfoBlock";

const InformationBlockDesktop = ({
  header,
  content,
  withImages,
  imageCSS,
  images,
  margin,
}: {
  header: string;
  content: string;
  withImages?: boolean;
  images?: string[];
  imageCSS: IimageCSS | undefined;
  margin?: string;
}): JSX.Element => {
  return (
    <div className={styles.wrapper} style={{ margin: margin }}>
      <InformationBlock header={header} content={content} />
      {withImages && (
        <div className={styles.images_wrapper}>
          {images &&
            images.map((image, index) => (
              <img
                className={styles.image}
                style={imageCSS}
                src={image}
                alt={`image ${index}`}
                key={index}
              />
            ))}
        </div>
      )}
    </div>
  );
};

export default InformationBlockDesktop;
