import React, { useEffect, useRef, useState } from "react";

//components
import styles from "./styles.module.css";
import { Box } from "@mui/material";
import { Persons } from "src/core/helpers/persons";
import PersonComponent from "src/components/atoms/PersonComponent";
import SubPageHeader from "src/components/atoms/SubPageHeader";
import Footer from "src/components/molecules/Footer";
import PersonsComponentDesktop from "src/components/pages/PersonsComponentDesktop";
import { useTranslation } from "react-i18next";
const PagePersons = (): JSX.Element => {
  const topRef = useRef<any>(null);
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768);
  const { t } = useTranslation();
  const updateMedia = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    if (topRef) {
      window.scrollTo({ top: topRef.current.offsetTop - 150, behavior: "smooth" });
    }
  }, [topRef]);
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);
  return (
    <>
      <Box className={styles.wrapper} ref={topRef}>
        <SubPageHeader pageName={t("main_page_persons")} />
        {Persons.map((person, index) =>
          isMobile ? (
            <PersonComponent
              key={index}
              header={t(person.header)}
              photo={person.image}
              text={t(person.main_text)}
              split={person.split_length}
            />
          ) : (
            <PersonsComponentDesktop
              key={index}
              header={t(person.header)}
              photo={person.image}
              text={t(person.main_text)}
              split={person.split_length}
              flexDirection={person.flexDirection}
              imgHeight={person.imgHeight}
            />
          ),
        )}
      </Box>
      <Footer />
    </>
  );
};

export default PagePersons;
