import { Action } from "src/core/types/reduxTypes";

interface IInitialState {
  indexOfImage: number | null;
}

const initialState: IInitialState = {
  indexOfImage: null,
};

//eslint-disable-next-line @typescript-eslint/default-param-last
export const GalleryReducer = (state = initialState, action: Action<any, any>) => {
  switch (action.type) {
    case "SET_IMAGE":
      return {
        ...state,
        indexOfImage: action.payload,
      };
    case "RESET_IMAGE":
      return {
        ...state,
        indexOfImage: null,
      };
    default:
      return state;
  }
};
