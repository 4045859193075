export const setImage = (data: number) => {
  return {
    type: "SET_IMAGE",
    payload: data,
  };
};

export const resetImage = () => {
  return {
    type: "RESET_IMAGE",
  };
};
