import React from "react";
import GalleryComponent from "src/components/atoms/GalleryComponent";
import { galleryDesktop, galleryDesktopNext } from "src/core/helpers/galleryDesktop";
import noPhoto from "../../../assets/Rectangle 26 (2).png";
import styles from "./styles.module.css";
import { useTranslation } from "react-i18next";

export const GalleryDesktopMain = (): JSX.Element => {
  const { t } = useTranslation();
  const additionalCSS = {
    marginTop: "140px",
  };

  const unusualGalleryCSS = {
    marginTop: "70px",
  };

  const getStyle = (index: number) => {
    switch (index) {
      case 0:
        return unusualGalleryCSS;
      case 1:
        return additionalCSS;
      default:
        return {};
    }
  };

  const getBasicPhoto = (image: string | string[] | undefined) => {
    if (Array.isArray(image)) {
      return image[0]; // or any other logic to select the appropriate image
    }
    return image || noPhoto;
  };

  return (
    <div className={styles.wrapper}>
      {galleryDesktop.map((item, index) => (
        <GalleryComponent
          key={item.id}
          usual={item.usual}
          basicPhoto={getBasicPhoto(item.image)}
          gallery={Array.isArray(item.image) ? item.image : []}
          headerText={t(`${item.header}`)}
          href={item.href}
          additionalCSS={getStyle(index)}
        />
      ))}
    </div>
  );
};

export const GalleryDesktopSub = (): JSX.Element => {
  const { t } = useTranslation();
  const reconstructionCss = {
    marginBottom: "40px",
  };

  const getSubCss = (index: number) => {
    switch (index) {
      case 1:
        return reconstructionCss;
      default:
        return {};
    }
  };

  const getBasicPhoto = (image: string | string[] | undefined) => {
    if (Array.isArray(image)) {
      return image[0];
    }
    return image || noPhoto;
  };

  return (
    <div className={styles.wrapper_sub}>
      {galleryDesktopNext.map((item, index) => (
        <GalleryComponent
          key={item.id}
          usual={item.usual}
          basicPhoto={getBasicPhoto(item.image)}
          gallery={Array.isArray(item.image) ? item.image : []}
          headerText={t(`${item.header}`)}
          href={item.href}
          additionalCSS={getSubCss(index)}
        />
      ))}
    </div>
  );
};
