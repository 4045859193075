import React from "react";

export const ButtonArrow = (): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.0771 5L13.6671 6.41L18.2471 11H2.07715V13H18.2471L13.6571 17.59L15.0771 19L22.0771 12L15.0771 5Z"
        fill="white"
      />
    </svg>
  );
};
