import { Box } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.css";
import linePoint from "../../../assets/list_item_red.svg";
import schoolIcon from "../../../assets/school_ikon.svg";
import phoneIcont from "../../../assets/phone_icon.svg";

export const SchoolContentText = ({ numref }: { numref?: React.Ref<HTMLDivElement> }) => {
  const { t } = useTranslation();
  return (
    <Box className={styles.container}>
      <Box className={styles.mainBox}>
        <Box className={styles.titleBox}>
          <img alt="image" src={schoolIcon} style={{ margin: "0 20px 0 0" }} />
          <span className={styles.titleString}>{t("school_title")}</span>
        </Box>
        <Box className={styles.subtitleBox}>
          <span
            className={styles.subtitleText1}
            dangerouslySetInnerHTML={{ __html: t("school_subtitle_text") }}
          />
        </Box>
        <Box className={styles.noticeBox}>
          <div className={styles.line}></div>
          <Box className={styles.noticeWrapper}>
            <Box className={styles.notice}>
              <img alt="image" src={linePoint} style={{ margin: "0 1.5vw 0 0" }} />
              <span
                className={styles.text}
                dangerouslySetInnerHTML={{
                  __html: t("school_condition_one", { phone_number: t("phone_number_group") }),
                }}
              />
            </Box>
            <Box className={styles.notice}>
              <img alt="image" src={linePoint} style={{ margin: "0 1.5vw 0 0" }} />
              <span
                className={styles.text}
                dangerouslySetInnerHTML={{
                  __html: t("school_condition_two"),
                }}
              />
            </Box>
            <Box className={styles.notice}>
              <img alt="image" src={linePoint} style={{ margin: "0 1.5vw 0 0" }} />
              <span
                className={styles.text}
                dangerouslySetInnerHTML={{
                  __html: t("school_condition_three"),
                }}
              />
            </Box>
            <Box className={styles.notice}>
              <img alt="image" src={linePoint} style={{ margin: "0 1.5vw 0 0" }} />
              <span
                className={styles.text}
                dangerouslySetInnerHTML={{
                  __html: t("school_condition_four"),
                }}
              />
            </Box>
          </Box>
          <div className={styles.line}></div>
        </Box>

        <Box className={styles.buttonBox} ref={numref}>
          <img alt="image" src={phoneIcont} />
          <span className={styles.phone}>{t("phone")}</span>
        </Box>
      </Box>
    </Box>
  );
};
