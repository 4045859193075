import React from "react";
import styles from "src/components/atoms/GalleryComponent/styles.module.css";
import { Box } from "@mui/material";

const UnusualGalleryComponent = ({ gallery }: { gallery: string[] | undefined }): JSX.Element => {
  return (
    <Box className={styles.gallery_wrapper}>
      <Box className={styles.wrapper_unusual}>
        {gallery &&
          gallery.map((image, index) => (
            <img
              key={index}
              className={`${styles[`image_${index + 1}`]} ${styles.images}`}
              src={image}
              alt={`image-${index}`}
            />
          ))}
      </Box>
    </Box>
  );
};

export default UnusualGalleryComponent;
