//photos
import photoOne from "../../assets/Rectangle 25 (4).png";
import photoTwo from "../../assets/Rectangle 26 (1).png";
import photoFour from "../../assets/Rectangle 32.png";
import photoSix from "../../assets/costline.png";
import photoSeven from "../../assets/presons.png";
import photoEight from "../../assets/recon.png";
import photoNine from "../../assets/peopleM.png";
import photoTen from "../../assets/bigcostel.png";
import photoElev from "../../assets/picturecostel.png";
import photoTW from "../../assets/pictureseccostel.png";
import photoThr from "../../assets/icona.png";
import photoFourt from "../../assets/sculpture.png";
import photoFivth from "../../assets/columns.png";
import photoSixth from "../../assets/colocl.png";
import photoSeventh from "../../assets/newcols.png";
//text
const contentOne = "history_one";
const contentTwo = "history_two";
const contentThree = "history_three";
const contentFour = "history_four";
const contentSix = "history_six";
const contentSeven = "history_seven";
const contentEight = "history_eight";
const contentNine = "history_nine";
const contentTen = "history_ten";
const contentEit = "history_eit";
const contentTw = "history_tw";
const contentFrth = "history_frth";
const contentSixt = "history_sixt";
const contentSevent = "history_sevent";
const contentEighteen = "history_eighteen";
const contentNinet = "history_ninet";
const contentTwent = "history_twent";
const contentTwentOne = "history_twentone";
const contentTwentThree = "history_twentthree";
const contentTwentFour = "history_twentfour";
const contentTwentTwo = "history_twenttwo";
const contentTwentThr = "history_twentthr";
export const history = [
  {
    id: "box",
    header: "1091",
    content: contentOne,
  },
  {
    id: "box",
    header: "1201 - 1222",
    content: contentTwo,
  },
  {
    id: "box",
    header: "1231",
    content: contentTwentThr,
  },
  {
    id: "box",
    header: "1300",
    content: contentThree,
  },
  {
    id: "img",
    header: "image",
    content: photoSeventh,
    height: 320,
  },
  {
    id: "box",
    header: "1456 - 1473",
    content: contentFour,
  },
  {
    id: "img",
    header: "image",
    content: photoSixth,
    height: 437,
  },
  {
    id: "box",
    header: "1515",
    content: contentSix,
  },
  {
    id: "box",
    header: "1532",
    content: contentSeven,
  },
  {
    id: "img",
    header: "image",
    content: photoFivth,
    height: 437,
  },
  {
    id: "box",
    header: "asi 1570",
    content: contentEight,
  },
  {
    id: "box",
    header: "1592",
    content: contentNine,
  },
  {
    id: "box",
    header: "1694-1730",
    content: contentTen,
  },
  {
    id: "img",
    header: "image",
    content: photoFourt,
    height: 437,
  },
  {
    id: "box",
    header: "1727",
    content: contentEit,
  },
  {
    id: "img",
    header: "image",
    content: photoThr,
  },
  {
    id: "box",
    header: "1750-1769.",
    content: contentTw,
  },
  {
    id: "img",
    header: "image",
    content: photoOne,
    height: 320,
  },
  {
    id: "box",
    header: "1784",
    content: contentFrth,
  },
  {
    id: "img",
    header: "image",
    content: photoTW,
  },
  {
    id: "box",
    header: "1870-1879",
    content: contentSixt,
  },
  {
    id: "img",
    header: "image",
    content: photoElev,
    height: 437,
  },
  {
    id: "box",
    header: "1881",
    content: contentSevent,
  },
  {
    id: "img",
    header: "image",
    content: photoTen,
    height: 437,
  },
  {
    id: "img",
    header: "image",
    content: photoTwo,
    height: 320,
  },
  {
    id: "box",
    header: "1945",
    content: contentEighteen,
  },
  {
    id: "box",
    header: "1964-1989",
    content: contentNinet,
  },
  {
    id: "box",
    header: "1995",
    content: contentTwent,
  },
  {
    id: "img",
    header: "image",
    content: photoNine,
    height: 320,
  },
  {
    id: "box",
    header: "2006",
    content: contentTwentOne,
  },
  {
    id: "img",
    header: "image",
    content: photoSeven,
    height: 320,
  },
  {
    id: "img",
    header: "image",
    content: photoEight,
    height: 320,
  },
  {
    id: "box",
    header: "2021",
    content: contentTwentTwo,
  },
  {
    id: "box",
    header: "2024 - červen",
    content: contentTwentFour,
  },
  {
    id: "img",
    header: "image",
    content: photoFour,
    height: 437,
  },
  {
    id: "img",
    header: "image",
    content: photoSix,
    height: 437,
  },
  {
    id: "box",
    header: "2024 - podzim",
    content: contentTwentThree,
  },
];
