import React from "react";

//components
import { fullpagetext } from "src/core/helpers/fullpagetext";
import styles from "./styles.module.css";
import { useTranslation } from "react-i18next";

const FullPageText = ({ order }: { order: number }): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div className={styles.wrapper}>
      <span
        className={styles.text}
        dangerouslySetInnerHTML={{ __html: t(fullpagetext[order - 1]) }}
      ></span>
    </div>
  );
};

export default FullPageText;
