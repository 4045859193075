import React from "react";

export const KostelIcon = (): JSX.Element => {
  return (
    <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.6888 17.0289C12.6788 17.0124 12.6685 16.9958 12.6579 16.9791L13.214 16.7966L14.3409 16.4267L13.5236 15.5672C13.078 15.0987 12.8973 14.7392 12.8317 14.492C12.7695 14.2578 12.8003 14.0885 12.8554 13.9577C12.975 13.6737 13.2454 13.4875 13.3403 13.4285C13.3499 13.4249 13.3697 13.4174 13.4038 13.4057C13.4212 13.3997 13.4448 13.3919 13.4719 13.3828C13.5334 13.3622 13.6135 13.3354 13.685 13.3098C13.9005 13.2328 14.2174 13.1071 14.4931 12.8957C15.0641 12.458 15.5428 11.8968 15.6592 11.1003L15.6612 11.0865L15.6627 11.0726L15.9992 7.96548L16.308 11.0314C16.3156 11.383 16.4633 11.7353 16.6189 12.0059C16.7956 12.3133 17.0471 12.6282 17.3542 12.8802C17.6218 13.0999 17.9728 13.2521 18.1979 13.3392C18.3214 13.3869 18.4318 13.4243 18.5116 13.4499C18.5412 13.4594 18.567 13.4674 18.5881 13.4738C18.5934 13.4765 18.5995 13.4795 18.6062 13.483C18.6457 13.5034 18.7043 13.5362 18.7708 13.5812C18.9101 13.6753 19.0514 13.8009 19.144 13.9495C19.2273 14.0831 19.2778 14.2427 19.2421 14.4624C19.2036 14.699 19.0532 15.0716 18.5965 15.5863L17.8464 16.4317L18.9169 16.7944L19.5025 16.9928C19.1822 17.4666 19.0324 17.9924 19.0324 18.4681V20.5904V20.9002L19.2511 21.1197L22.7487 24.6308V32H18.6686H13.6539H10.8842H9.67969V24.6142L12.9228 21.099L13.1216 20.8835V20.5904V18.4681C13.1216 18.0059 12.9582 17.4734 12.6888 17.0289Z"
        stroke="#E31340"
        strokeWidth="1.5"
      />
      <ellipse cx="16.0275" cy="3.61517" rx="1.10345" ry="1.10345" fill="#E31340" />
    </svg>
  );
};
