export const styles = {
  title: {
    color: "#062762",
    fontSize: "18px",
    fontWeight: "bold",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    margin: "0 0 20px 0",
  },
  buttonToGo: {
    position: "relative",
    padding: "6px 15px",
    backgroundColor: "#E31340",
    color: "white",
    display: "flex",
    borderRadius: "25px",
    width: "212px",
    height: "50px",
    textDecoration: "none",
  },
};
