import styled from "styled-components";

interface IArrowWrapper {
  active: boolean;
}

interface IDot {
  active: boolean;
}

export const Wrapper = styled.div`
  position: absolute;
  width: 90%;
  top: 85%;
  left: 3%;
  padding: 12px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 60px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(18px);
  z-index: 6;

  @media (min-width: 1023px) {
    width: 50%;
    top: 74%;
    left: 24%;
  }

  @media (min-width: 2100px) {
    width: 30%;
    top: 70%;
    left: 34%;
  }
`;

export const ArrowWrapper = styled.div<IArrowWrapper>`
  display: flex;
  justify-content: center;
  padding: 16px 21px;
  align-items: center;
  border-radius: 30px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
  background: ${(props) => (props.active ? "#fff" : "none")};
  cursor: pointer;
`;

export const MiddleContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const PageCounter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  margin-bottom: 10px;
`;

export const PageDotWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
`;

export const PageDot = styled.div<IDot>`
  width: ${(props) => (props.active ? "20px" : "8px")};
  height: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 60px;
  background: #ffffff;
`;
