import { useMutation } from "react-query";
import axios from "axios";
import { PRE_RESERVATION_API_ROUTE } from "../endpoints";
import { PreReservation } from "../types";
import { clearFormValues, useFormValuesContext } from "../context";
import { useDispatch } from "react-redux";
import { setReservationId } from "src/core/redux/actions/reservationActions";

export const UsePostPreReservation = () => {
  const dispatch = useDispatch();
  const { formValues, setFormValues } = useFormValuesContext();
  const currentLoc = sessionStorage.getItem("loc") || "cs";
  const mutation = useMutation(
    (submitData: PreReservation) => {
      return axios
        .post(`${window.BASE_API_URL}/${PRE_RESERVATION_API_ROUTE}`, submitData)
        .then((res) => {
          return res;
        });
    },
    {
      onSuccess: (response) => {
        let reservation;
        let freeCapacity;

        switch (response.data.result) {
          case "OK":
            reservation = response.data.reservation;
            if (reservation) {
              setFormValues({ ...formValues, reservation: reservation });
              dispatch(setReservationId(reservation));
              sessionStorage.setItem("current_reservation_id", reservation);
            }
            break;

          case "LIMIT_EXCEEDED":
            freeCapacity = response.data.free_capacity;
            setFormValues({ ...formValues, free_capacity: freeCapacity });
            sessionStorage.setItem("free_capacity", freeCapacity);
            break;

          case "RESERVATION_EXPIRED":
            setFormValues(clearFormValues);
            sessionStorage.clear();
            sessionStorage.setItem("loc", currentLoc);
            break;

          case "TIMESLOT_EXPIRED":
            break;

          default:
            break;
        }
      },
      onError: (error) => {
        console.error("Error during reservation:", error);
      },
    },
  );
  return mutation;
};
