import { useMutation } from "react-query";
import axios from "axios";
import { AGENCY_FORM } from "src/core/endpoints";

export const useSentAgencyForm = () => {
  const currentLoc = sessionStorage.getItem("loc") || "cs";

  return useMutation(
    (mutateData: any) => {
      return axios.post(`${window.BASE_API_URL}/${AGENCY_FORM}`, mutateData);
    },
    {
      onSuccess: (response) => {
        const payUrl = response.data.pay_url;
        if (payUrl) {
          sessionStorage.setItem("current_reservation_id", response.data.id);
          // localStorage.setItem("current_reservation_id", response.data.id);
          window.location.href = payUrl;
        }
      },
      onError: (error: { response: unknown }) => {
        sessionStorage.clear();
        sessionStorage.setItem("loc", currentLoc);
      },
    },
  );
};
