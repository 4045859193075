import React, { useEffect, useState } from "react";

//components
import { Box } from "@mui/material";
import { ProgressBar } from "primereact/progressbar";
import styles from "./style.module.css";
import { useGetDebt } from "src/core/queries/useGetDebt";
import { useTranslation } from "react-i18next";

const MoneyCounter = (): JSX.Element => {
  const { data, status } = useGetDebt();
  const [paidPercents, setPaidPercents] = useState<number>(0);
  const [dateEnds, setDateEnds] = useState<string>("");
  const { t } = useTranslation();
  const getZeros = (value: string, dividedNumberObj: any, number: number) => {
    if (value === "thousand" && !dividedNumberObj.hundred) {
      return `${dividedNumberObj.thousand} 000`;
    } else if (value === "million" && !dividedNumberObj.thousand && !dividedNumberObj.hundred) {
      return `${dividedNumberObj.million} 000 000`;
    } else {
      return number;
    }
  };
  const getDividedNumber = (number: number): string => {
    const dividedNumberObj = {
      million: Math.floor((number % 1000000000) / 1000000),
      thousand: Math.floor((number % 1000000) / 1000),
      hundred: Math.floor(number % 1000),
    };
    const { million, thousand, hundred } = dividedNumberObj;
    return `${
      million ? `${getZeros("million", dividedNumberObj, dividedNumberObj.million)}` : ""
    } ${thousand ? getZeros("thousand", dividedNumberObj, dividedNumberObj.thousand) : ""} ${
      hundred ? hundred : ""
    }`;
  };

  const formatDate = (date: string) => {
    if (date) {
      return `${date.slice(5, 7)}/${date.slice(0, 4)}`;
    }
  };

  useEffect((): void => {
    if (data && status === "success") {
      setPaidPercents((data.paid_debt / data.debt) * 100);
      setDateEnds(data.date);
    }
  }, [data, status]);
  return (
    <Box className={styles.wrapper}>
      {data && (
        <Box className={styles.debt_counter}>
          <span className={styles.money_count}>
            {getDividedNumber(data.paid_debt)} {t("cz_currency")}
          </span>
          <span className={`${styles.money_count} ${styles.text_right} ${styles.text_red}`}>
            {getDividedNumber(data.debt)} {t("cz_currency")}
          </span>
          <span className={`${styles.money_count}} ${styles.sub_text_left}`}>
            {`${t("money_count_to_month")} ${formatDate(dateEnds)}`}
          </span>
          <span className={`${styles.money_count} ${styles.text_right} ${styles.sub_text}`}>{`${t(
            "target",
          )}`}</span>
        </Box>
      )}
      <ProgressBar
        style={{
          height: "10px",
          borderRadius: "4px",
          marginBottom: "10px",
          background: "red",
        }}
        color={"#062762"}
        showValue={false}
        value={paidPercents || 0}
      />
      <span className={styles.subscription}>{t("money_count_description")}</span>
    </Box>
  );
};
export default MoneyCounter;
