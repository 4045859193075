import { AppBar, Button, Toolbar, Typography } from "@mui/material";
import React, { useState } from "react";
import styles from "./styles.module.css";
import { style } from "./style";
import { Logo } from "../../atoms/Logo";
import { useTranslation } from "react-i18next";
import { LocSelect } from "src/components/atoms/LocSelect";
import TiketIcon from "../../../assets/tickets_white.svg";
import facebookIconHeader from "../../../assets/facebook.svg";
import { useNavigate } from "react-router-dom";
import MobileMenu from "src/components/atoms/MobileMenu";
import { TYPE_OF_CUSTOMERS } from "src/core/routing";

const HomeHeader: React.FC<{
  castleClick?: () => void;
  VisitClick?: () => void;
  footerClick?: () => void;
  purchseTiketsClick?: () => void;
  CastleFreeVisitingClick?: () => void;
}> = ({ castleClick, footerClick, purchseTiketsClick, VisitClick, CastleFreeVisitingClick }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const navigateTo = (route: string): void => {
    navigate(route);
  };

  const moveTo = (path: string) => {
    navigate(path);
  };

  return (
    <AppBar color="inherit">
      <Toolbar
        className={`${styles.desktop_only} ${styles.toolbar}`}
        style={{
          margin: "auto",
          flexWrap: "nowrap",
        }}
      >
        <Logo />
        <div
          className={styles.desktop_only}
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "space-between",
            flexShrink: 0,
            alignItems: "center",
          }}
        >
          <div className={styles.desktop_only}>
            <div className={styles.buttons_wrapper}>
              <Button
                onClick={() => {
                  navigateTo("/group");
                }}
              >
                <Typography style={style.buttonText}>{t("travel_action")}</Typography>
              </Button>
              <Button
                onClick={() => {
                  navigateTo("/school");
                }}
              >
                <Typography style={style.buttonText}>{t("schools")}</Typography>
              </Button>
              <Button
                onClick={() => {
                  navigateTo("/travel");
                }}
              >
                <Typography style={style.buttonText}>{t("travel_agencies")}</Typography>
              </Button>
            </div>
          </div>

          <div className={styles.desktop_only}>
            <div className={styles.primary}>
              <Button
                onClick={() => {
                  moveTo(TYPE_OF_CUSTOMERS);
                }}
                sx={{
                  ...style.primary,
                  textDecoration: "none !important",
                  "&:hover": {
                    backgroundColor: "#F1375F",
                    color: "#FFFFFF !important",
                    textDecoration: "none !important",
                  },
                }}
              >
                <Typography style={{ fontSize: "17px", cursor: "pointer", padding: "0 8px" }}>
                  {t("want_to_buy_button")}
                </Typography>
                <img src={TiketIcon} style={{ padding: "0 8px" }} />
              </Button>
            </div>
          </div>

          <LocSelect style={style.LocSelect} />
          <div className={styles.desktop_only}>
            <div className={styles.contact}>
              <Button className={styles.contact} style={style.contact}>
                <Typography style={{ cursor: "pointer" }}>+420 736 117 117</Typography>
              </Button>
            </div>
          </div>
          <div className={styles.desktop_only}>
            <div className={styles.social}>
              <a
                target={"_blank"}
                rel={"noreferrer"}
                href="https://www.facebook.com/visitjakubbrno"
              >
                <img src={facebookIconHeader} style={{ marginRight: "15px" }} />
              </a>
            </div>
          </div>
          <div className={styles.mobile_only}>
            <MobileMenu
              onContactClick={footerClick}
              onButtonsClick={purchseTiketsClick}
              VisitClick={VisitClick}
            />
          </div>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default HomeHeader;
