import React from "react";
import styles from "./styles.module.css";
import MoneyCounterReconstrution from "src/components/atoms/MoneyCountReconstructionPage";
import person from "src/assets/owner.png";
import UnsualGallery from "src/components/atoms/UnsualGallery";
import { useTranslation } from "react-i18next";

const DonationBlock = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div className={styles.wrapper}>
      <div className={styles.sub_wrapper}>
        <MoneyCounterReconstrution />
        <div className={styles.sub_down_wrapper}>
          <span className={styles.sub_header}>{t("funriser")}</span>
          <img src={person} className={styles.person} alt="" />
          <div className={styles.description_wrapper}>
            <span
              className={styles.description}
              dangerouslySetInnerHTML={{ __html: t("donation_people") }}
            />
          </div>
        </div>
      </div>
      <div className={styles.sub_big_wrapper}>
        <UnsualGallery />
      </div>
    </div>
  );
};

export default DonationBlock;
