import React from "react";
import styles from "./styles.module.css";
import { SelectButton } from "src/components/atoms/MapControl/StyledComponents";
import { useTranslation } from "react-i18next";

const MapControl = ({
  selectFirst,
  selectSecond,
  selectThird,
  currentActive,
}: {
  selectFirst: () => void;
  selectSecond: () => void;
  selectThird: () => void;
  currentActive: number;
}): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div className={styles.wrapper}>
      <div className={styles.left_description}>{t("floor")}</div>
      <div className={styles.line}></div>
      <SelectButton isActive={currentActive === 0} onClick={() => selectFirst()}>
        {t("floor_one")}
      </SelectButton>
      <SelectButton isActive={currentActive === 1} onClick={() => selectSecond()}>
        {t("floor_two")}
      </SelectButton>
      <SelectButton isActive={currentActive === 2} onClick={() => selectThird()}>
        {t("slide")}
      </SelectButton>
    </div>
  );
};

export default MapControl;
