//appstore
import appstore from "../../assets/Download_on_the_App_Store_Badge_CZ_RGB_blk_092917.svg";
import appstoremobile from "../../assets/appstoreMobile.svg";
import appstore_en from "../../assets/app_store_en.svg";
import appstore_de from "../../assets/Download_on_the_App_Store_Badge_DE_RGB_blk_092917.svg";
import appstore_mob_de from "../../assets/appstore_mob_de.svg";
import appstore_mob_en from "../../assets/appstore_en_mob.svg";
//google play
import gplay from "../../assets/rozjedte_to_google_play.svg";
import gplaymobile from "../../assets/gplay_mob_cs.svg";
import gplay_en from "../../assets/gplay_en_badge.svg";
import gplay_de from "../../assets/download_google_play_de.svg";
import gplay_mob_de from "../../assets/gplay_mob_de.svg";
import gplay_mob_en from "../../assets/gplay_mob_en.svg";

type Icons = "gplay" | "appstore";
type Lang = "cs" | "en" | "de";

type BadgeKey =
  | "gplay_cs"
  | "gplay_en"
  | "gplay_de"
  | "appstore_cs"
  | "appstore_en"
  | "appstore_de"
  | "gplay_mob_cs"
  | "gplay_mob_en"
  | "gplay_mob_de"
  | "appstore_mob_cs"
  | "appstore_mob_en"
  | "appstore_mob_de";

export const badges: Record<BadgeKey, string> = {
  gplay_cs: gplay,
  gplay_en: gplay_en,
  gplay_de: gplay_de,
  appstore_cs: appstore,
  appstore_en: appstore_en,
  appstore_de: appstore_de,
  gplay_mob_cs: gplaymobile,
  gplay_mob_en: gplay_mob_en,
  gplay_mob_de: gplay_mob_de,
  appstore_mob_cs: appstoremobile,
  appstore_mob_en: appstore_mob_en,
  appstore_mob_de: appstore_mob_de,
};

export const getTranslatedBadges = ({
  isMobile,
  language,
  icon,
}: {
  isMobile: boolean;
  language: Lang;
  icon: Icons;
}) => {
  const key = `${isMobile ? `${icon}_mob` : icon}_${language}` as BadgeKey;
  return badges[key];
};
