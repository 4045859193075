import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import enTranslations from "./locales/en.json";
import csTranslations from "./locales/cs.json";
import deTranslations from "./locales/de.json";
const translations = {
  en: {
    translation: enTranslations,
  },
  cs: {
    translation: csTranslations,
  },
  de: {
    translation: deTranslations,
  },
};

i18n.use(initReactI18next).init({
  resources: translations,
  lng: sessionStorage.getItem("loc") || "cs",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
