import React from "react";
import styles from "./styles.module.css";
import { useTranslation } from "react-i18next";

const DataComponent = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div className={styles.wrapper}>
      <div className={styles.content_text}>{t("data_security_contact")}</div>
      <a href="tel:+420736117117" className={styles.content_text}>
        {t("data_security_phone")}
      </a>
      <div className={styles.content_text}>
        email:{" "}
        <a className={styles.link} href="mailto:info@jktrust.cz">
          {t("data_security_email")}
        </a>
      </div>
    </div>
  );
};

export default DataComponent;
