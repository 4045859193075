import { Action, TicketFilterPayload, TicketFilterType } from "src/core/types/reduxTypes";

const initialState: { selectedFilter: string } = {
  selectedFilter: "everytime",
};

export const TicketFilterReducer = (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state = initialState,
  action: Action<TicketFilterType, TicketFilterPayload>,
): { selectedFilter: string } => {
  switch (action?.type) {
    case "SELECT_FILTER":
      console.log(state);
      return {
        ...state,
        selectedFilter: action.payload,
      };
    default:
      return state;
  }
};
