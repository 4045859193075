import { Box } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { SubHeader } from "src/components/atoms/SubHeader";
import mainPhoto from "../../../assets/Visualove_puda_u_Jakuba-5.jpg";
import styles from "./styles.module.css";
import Footer from "src/components/molecules/Footer";
import { TIcketConditionsGroup } from "src/components/atoms/TIcketConditionsGroup";
import GroupForm from "src/components/atoms/GroupForm";
import VisitConditions from "src/components/atoms/VisitConditions";
import { useTranslation } from "react-i18next";

export const PageGroup = () => {
  const FormRef = useRef<HTMLDivElement | null>(null);
  const topRef = useRef<any>();
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768);
  const isApp = sessionStorage.getItem("isApp");
  const { t } = useTranslation();

  const updateMedia = (): void => {
    setIsMobile(window.innerWidth <= 768);
  };
  const FormClick = () => {
    if (FormRef.current) {
      const elementPosition = FormRef.current.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - 20;
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };
  const ScrollToTop = (): void => {
    window.scrollTo({
      top: topRef.current.offsetTop - 20,
      behavior: "smooth",
    });
  };

  const getCss = (): string => {
    if (isApp) {
      return styles.mob_wrapper;
    }
    return styles.wrapper;
  };

  useEffect(() => {
    ScrollToTop();
  }, []);

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
  }, []);

  return (
    <Box className={getCss()} ref={topRef}>
      <SubHeader
        mainPhoto={mainPhoto}
        subtitle={t("group_for")}
        title={isMobile ? t("main_title_mob") : t("main_title")}
        FormClick={FormClick}
      />
      <TIcketConditionsGroup formClick={FormClick} />
      <GroupForm ref={FormRef} />
      <VisitConditions />
      <Footer />
    </Box>
  );
};
