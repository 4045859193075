import styled from "styled-components";

interface ISelectButton {
  isActive: boolean;
}

export const SelectButton = styled.div<ISelectButton>`
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  cursor: pointer;
  user-select: none;
  color: ${(props) => (props.isActive ? "#E31240" : "#000")};
`;
