import React from "react";

//components
import { Box } from "@mui/material";
import styles from "./style.module.css";

//assets
import mainPhoto from "../../../assets/Visualove_puda_u_Jakuba-17.jpg";
import { EXPOSITION_ROUTE } from "src/core/routing";
import GalleryComponent from "src/components/atoms/GalleryComponent";
import { useTranslation } from "react-i18next";

const VisitInfo = (): JSX.Element => {
  const { t } = useTranslation();
  const header = t("main_page_exposition");
  const additionalStyle = {
    padding: "10px 0px 10px 9px",
  };
  return (
    <Box className={styles.wrapper}>
      <GalleryComponent
        headerText={header}
        href={EXPOSITION_ROUTE}
        basicPhoto={mainPhoto}
        usual={true}
        headerStyle={additionalStyle}
      />
    </Box>
  );
};

export default VisitInfo;
