import { STOP_RESERVATION_API_ROUTE } from "../endpoints";
import { useQuery } from "react-query";
import axios from "axios";
import { Timer } from "../types/timer";
import { useDispatch } from "react-redux";
import { refreshReservationId } from "src/core/redux/actions/reservationActions";

export const useGetRemoveReservation = ({
  reservationId,
  executeQuery,
}: {
  reservationId: string;
  executeQuery?: boolean;
}) => {
  const dispatch = useDispatch();
  const currentLoc = sessionStorage.getItem("loc") || "cs";
  const fetchStopReservation = (): Promise<Timer> => {
    return axios
      .get(`${window.BASE_API_URL}/${STOP_RESERVATION_API_ROUTE}/${reservationId}`)
      .then((response) => {
        if (reservationId) {
          dispatch(refreshReservationId());

          sessionStorage.clear();

          sessionStorage.setItem("loc", currentLoc);
        }
        return response.data;
      });
  };

  return useQuery<Timer, Error>(`fetchStopReservation-${reservationId}`, fetchStopReservation, {
    refetchOnWindowFocus: false,
    retry: 0,
    // enabled: !!reservationId && executeQuery,
    enabled: false,
  });
};
