import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import styles from "./styles.module.css";
import FullScreenGallery from "src/components/molecules/FullScreenGallery";
import { fullPageGallery } from "src/core/helpers/fullPageGallery";
import SubPageHeader from "src/components/atoms/SubPageHeader";
import { useTranslation } from "react-i18next";

const PageExposition = (): JSX.Element => {
  const { t } = useTranslation();
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768);
  const [gallery, setGallery] = useState(fullPageGallery);

  const updateMedia = (): void => {
    const mobile = window.innerWidth <= 768;
    setIsMobile(mobile);
    setGallery(isMobile ? fullPageGallery.slice(1) : fullPageGallery);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    updateMedia();
    return () => window.removeEventListener("resize", updateMedia);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className={styles.wrapper}>
      <SubPageHeader pageName={t("exposition_header")} />
      <FullScreenGallery photos={gallery} />
    </Box>
  );
};

export default PageExposition;
