import dayjs from "dayjs";
import * as React from "react";
import { createContext, useState, useContext, useEffect } from "react";

type FormValuesType = {
  timer_time: { minutes: number; seconds: number } | null;
  send_promo: boolean;
  free_capacity: number | null;
  agency_name: string;
  reservation: string | null;
  order_type: string;
  time_slot_id: number | null;
  type: string;
  date: Date;
  time: string;
  name: string;
  phone_number: string | number;
  email: string;
  validEmail: string;
  discount: string | null;
  discount_amount: number | null;
  discount_percent_amount: number | null;
  finalPrice: number | null;
  tickets: { type: number; amount: number }[];
  totalTickets: number;
  totalPrice: number | null;
  amount: string;
  timeSlotString: string;
  ticketsCount: number;
  language: string;
  selectedSlotId: number | null;
  lastSubmittedSlotId: number | null;
};

const getTickets = (ticketsJSON: string | null) => {
  const parsed = JSON.parse(sessionStorage.getItem("tickets") || "");
  return parsed;
};

const getTimerTime = (timerJSON: string | null) => {
  const parsed = JSON.parse(sessionStorage.getItem("timer_time") || "");
  return parsed;
};

export const clearFormValues = {
  timer_time: null,
  send_promo: false,
  free_capacity: null,
  agency_name: "",
  reservation: null,
  time_slot_id: null,
  order_type: "",
  type: "",
  date: new Date(),
  time: "",
  name: "",
  phone_number: "",
  amount: "",
  email: "",
  validEmail: "",
  discount: null,
  discount_amount: null,
  discount_percent_amount: null,
  finalPrice: 0,
  timeSlotString: "",
  tickets: [],
  totalTickets: 0,
  totalPrice: 0,
  ticketsCount: 0,
  language: sessionStorage.getItem("loc") || "cs",
  selectedSlotId: null,
  lastSubmittedSlotId: null,
};

export const defaultFormValues = {
  lastSubmittedSlotId: Number(sessionStorage.getItem("selectedSlotId")) || null,
  selectedSlotId: Number(sessionStorage.getItem("selectedSlotId")) || null,
  timer_time: sessionStorage.getItem("timer_time")
    ? getTimerTime(sessionStorage.getItem("timer_time"))
    : null,
  agency_name: "",
  send_promo: Boolean(sessionStorage.getItem("send_promo")),
  free_capacity: sessionStorage.getItem("free_capacity")
    ? Number(sessionStorage.getItem("free_capacity"))
    : null,
  reservation: sessionStorage.getItem("reservation")
    ? String(sessionStorage.getItem("reservation"))
    : null,
  time_slot_id: sessionStorage.getItem("time_slot_id")
    ? Number(sessionStorage.getItem("time_slot_id"))
    : null,
  order_type: sessionStorage.getItem("order_type") || "single",
  type: sessionStorage.getItem("type") || "",
  date: sessionStorage.getItem("date")
    ? dayjs(sessionStorage.getItem("date")).toDate()
    : new Date(),
  time: sessionStorage.getItem("time") || "",
  name: sessionStorage.getItem("name") || "",
  surname: sessionStorage.getItem("surname") || "",
  phone_number: sessionStorage.getItem("phone") || "",
  email: sessionStorage.getItem("email") || "",
  validEmail: sessionStorage.getItem("email") || "",
  discount: sessionStorage.getItem("discount") || null,
  discount_amount: Number(sessionStorage.getItem("discount_percent_amount")) || null,
  discount_percent_amount: Number(sessionStorage.getItem("discount_percent_amount")) || null,
  finalPrice: Number(sessionStorage.getItem("finalPrice")) || 0,
  timeSlotString: sessionStorage.getItem("timeSlotString") || "",
  tickets: sessionStorage.getItem("tickets") ? getTickets(sessionStorage.getItem("tickets")) : [],
  totalTickets: Number(sessionStorage.getItem("totalTickets")) || 0,
  totalPrice: Number(sessionStorage.getItem("totalPrice")) || null,
  ticketsCount: Number(sessionStorage.getItem("ticketsCount")) || clearFormValues.ticketsCount,
  language: sessionStorage.getItem("language") || clearFormValues.language,
  amount: "",
};

type FormValuesContextTypes = {
  formValues: FormValuesType;
  setFormValues: React.Dispatch<React.SetStateAction<FormValuesType>>;
};

export const FormValuesContext = createContext<FormValuesContextTypes>(
  {} as FormValuesContextTypes,
);

export const useFormValuesContext = () => useContext(FormValuesContext);

export const FormValuesContextProvider = ({ children }: { children: any }) => {
  const [formValues, setFormValues] = useState<FormValuesType>(defaultFormValues);

  return (
    <FormValuesContext.Provider value={{ formValues, setFormValues }}>
      {children}
    </FormValuesContext.Provider>
  );
};
