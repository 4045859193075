import { Action, ReservationType } from "src/core/types/reduxTypes";

const initialState: { reservationID: string | null } = {
  reservationID: null,
};

export const ReservationReducer = (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state = initialState,
  action: Action<ReservationType, string>,
) => {
  switch (action.type) {
    case "SET_RESERVATION_ID":
      return {
        ...state,
        reservationID: action.payload,
      };
    case "REFRESH_RESERVATION_ID":
      return {
        ...state,
        reservationID: null,
      };
    default:
      return state;
  }
};
