import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";

//components

import { useSwipeable } from "react-swipeable";
import GalleryHeader from "src/components/atoms/GalleryHeader";
import { useSelector } from "react-redux";
import { RootState } from "src/core/redux/store";
import { galleryPhotos } from "src/core/helpers/galleryPhotos";
import ArrowLeft from "src/components/icons/ArrowLeft";

const PageGalleryOpened = (): JSX.Element => {
  const [currentImage, setCurrentImage] = useState<number>(0);
  const selectedImage = useSelector((state: RootState) => state.gallery?.indexOfImage);

  const nextImage = (): void => {
    if (currentImage < galleryPhotos.length - 1) {
      setCurrentImage(currentImage + 1);
    }
  };

  const prevImage = (): void => {
    if (currentImage > 0) {
      setCurrentImage(currentImage - 1);
    }
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => nextImage(),
    onSwipedRight: () => prevImage(),
    trackMouse: true,
  });

  useEffect(() => {
    setCurrentImage(selectedImage);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <GalleryHeader pageName={`${currentImage + 1}/${galleryPhotos.length}`} />
      <div {...handlers} className={styles.wrapper}>
        <img className={styles.image} src={galleryPhotos[currentImage]} />
        <div className={styles.navigation}>
          <div onClick={prevImage}>
            <ArrowLeft color={"#fff"} />
          </div>
          <div onClick={nextImage}>
            <ArrowLeft rotate={"0deg"} color={"#fff"} />
          </div>
        </div>
      </div>
    </>
  );
};

export default PageGalleryOpened;
