import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const FlagDE: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon {...props} width="21.368" height="14" viewBox="0 0 21.368 14">
    <svg xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.368,92.943H0v-4.3a.368.368,0,0,1,.368-.368H21a.368.368,0,0,1,.368.368v4.3Z"
        transform="translate(0 -88.276)"
        fill="#464655"
      />
      <path
        d="M21,316.576H.368A.368.368,0,0,1,0,316.207v-4.3H21.368v4.3A.368.368,0,0,1,21,316.576Z"
        transform="translate(0 -302.576)"
        fill="#ffe15a"
      />
      <rect width="21.368" height="4.666" transform="translate(0 4.667)" fill="#ff4b55" />
    </svg>
  </SvgIcon>
);
