// eslint-disable-next-line import/no-named-as-default
import styled from "styled-components";

interface ICirlce {
  isActive: boolean;
}

export const Wrapper = styled.div`
  position: absolute;
  top: 105%;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
`;

export const Counter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
`;

export const ContnentCenter = styled.div`
  width: 102px;
  height: 56px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 60px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(7.5px);
  //margin-top: 75px;
  gap: 10px;
`;

export const SubWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;

export const Circle = styled.div<ICirlce>`
  width: ${(props) => (props.isActive ? "19px" : "8px")};
  height: 8px;
  border-radius: 50px;
  background: ${(props) => (props.isActive ? "#fff" : "rgba(255, 255, 255, 0.6)")};
`;
