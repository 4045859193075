import { useQuery } from "react-query";
import axios from "axios";
import { REPAY } from "../endpoints";

export const useGetRepay = ({ reservationId }: { reservationId: string }) => {
  const fetchDebt: () => Promise<{ result: string; pay_url: string }> = async () => {
    const res = await axios.get(`${window.BASE_API_URL}/${REPAY}/${reservationId}`);
    return res.data;
  };

  const { data, refetch } = useQuery(`fetchDebt`, fetchDebt, {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    cacheTime: 0,
    enabled: false,
    // eslint-disable-next-line @typescript-eslint/no-shadow
    onSuccess: (data) => {
      window.location.href = data.pay_url;
    },
  });
  return { refetch };
};
//
