export const styles = {
  box: {
    margin: "auto",
  },
  iconGrey: {
    position: "relative",
    width: "fit-content",
    height: "32px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "1px 10px 1px 5px",
    fontSize: "16px",
    border: "2px solid #B9B9B9 !important",
    color: "#B9B9B9",
    borderRadius: "16px",
  },
  iconGreyMobile: {
    position: "relative",
    width: "36px",
    height: "36px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "2px solid #B9B9B9 !important",
    color: "#B9B9B9",
    borderRadius: "18px",
  },
  iconGreyMobileSmall: {
    position: "relative",
    width: "28px",
    height: "28px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "2px solid #B9B9B9 !important",
    color: "#B9B9B9",
    borderRadius: "18px",
  },
  isTabActive: {
    border: "2px solid #062762 !important",
    color: "#062762",
  },
  isTabDone: {
    border: "2px solid #051C4766 !important",
    color: "#051C4766",
  },
  separator: {
    margin: "0 0.6vw",
  },
  separatorMobile: {
    margin: "0 2px",
  },
};
