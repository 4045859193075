import React, { useEffect, useState } from "react";
import { Box, Button, IconButton, Modal, Typography } from "@mui/material";
import { CrossThinIcon } from "../icons/CrossThinIcon";
import { useTranslation } from "react-i18next";
import { useFormValuesContext } from "src/core/context";

interface LimitModalComponentProps {
  open: boolean;
  handleClose: () => void;
}

const LimitModalComponent: React.FC<LimitModalComponentProps> = ({ open, handleClose }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const updateMedia = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);

  const styles = {
    wrapper: isMobile
      ? {
          width: "300px",
          height: "396px",
          position: "fixed",
          top: "50%",
          left: "48.5%",
          transform: "translate(-52%, -50%)",
          color: "#000",
          zIndex: "1",
          padding: "5px",
          background: "#FFFFFF",
          border: "1px solid #000000",
        }
      : {
          width: "510px",
          height: "466px",
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          color: "#000",
          zIndex: "1",
          padding: "5px",
          background: "#FFFFFF",
          border: "1px solid #000000",
        },

    cancelButton: {
      backgroundColor: "transparent",
      padding: "27.5px",
      position: "absolute",
      top: 0,
      right: 0,
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    backdrop: {
      backgroundColor: "rgba(0, 0, 0, 0)",
    },
    box: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    title: {
      marginTop: "60px",
      fontWeight: "600",
      fontSize: "22px",
    },
    bodyText: {
      fontSize: "18px",
      textAlign: "center",
    },
    body: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "30px",
    },
    time: {
      marginTop: "30px",
      fontWeight: "600",
    },
    timeBox: {
      margin: "5px 0",
      width: "180px",
      height: "80px",
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      border: "1px solid #E1E1E1",
      borderRadius: "5px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    timeString: {
      color: "#062762",
      fontSize: "20px",
      fontWeight: "600",
    },
    availableSpots: {
      fontSize: "16px",
      color: "#E31340",
      fontWeight: "600",
    },
    buttonRedOne: {
      position: "relative",
      width: "148px",
      height: "50px",
      padding: "6px 12px",
      backgroundColor: "#E31340",
      color: "white",
      display: "flex",
      justifyContent: "space-around",
      borderRadius: "5px",
      marginRight: "10px",
    },

    buttonRedOTwo: {
      position: "relative",
      width: "230px",
      height: "50px",
      padding: "6px 12px",
      backgroundColor: "#E31340",
      color: "white",
      display: "flex",
      justifyContent: "space-around",
      borderRadius: "5px",
      marginLeft: "10px",
    },

    buttons: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: "40px 0px 0px 0px",
    },
  };

  const { formValues, setFormValues } = useFormValuesContext();
  const { t } = useTranslation();
  const selectedTime = formValues.time;

  const showDateAndTime = () => {
    setFormValues({
      ...formValues,
      totalTickets: 0,
      totalPrice: null,
      tickets: [],
      date: new Date(),
      time: "",
      time_slot_id: null,
    });
    handleClose();
  };

  useEffect(() => {
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      totalTickets: 0,
      totalPrice: null,
      tickets: [],
      date: new Date(),
      time: "",
      time_slot_id: null,
    }));
  }, [setFormValues]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      BackdropProps={{
        style: styles.backdrop,
      }}
    >
      <Box sx={styles.wrapper}>
        <IconButton sx={styles.cancelButton} onClick={handleClose}>
          <CrossThinIcon />
        </IconButton>
        <Box sx={styles.box}>
          <Typography sx={styles.title}>{t("slot_unavailable")}</Typography>
          <Box sx={styles.body}>
            <Typography sx={styles.bodyText}>{t("slot_unavailable_body")}</Typography>
          </Box>
          <Typography sx={styles.time}>{t("chosen_time")}</Typography>
          <Button
            sx={{
              ...styles.timeBox,
              textDecoration: "none",
              "&:hover": {
                backgroundColor: "#FFFFFF",
              },
            }}
          >
            <Typography sx={styles.timeString}>{selectedTime}</Typography>
          </Button>
          <Box sx={styles.buttons}>
            <Button
              sx={{
                ...styles.buttonRedOne,
                "&:hover": {
                  backgroundColor: "#E31340",
                },
              }}
              style={{ textDecoration: "none" }}
              onClick={showDateAndTime}
            >
              {t("another_time_chose")}
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default LimitModalComponent;
