//photos
import photoOne from "../../assets/Rectangle 25 (4).png";
import photoTwo from "../../assets/Rectangle 26 (1).png";
import photoFour from "../../assets/Rectangle 32.png";
import photoSix from "../../assets/costline.png";
import photoSeven from "../../assets/presons.png";
import photoEight from "../../assets/recon.png";
import photoNine from "../../assets/peopleM.png";
import photoTen from "../../assets/bigcostel.png";
import photoElev from "../../assets/picturecostel.png";
import photoTW from "../../assets/pictureseccostel.png";
import photoThr from "../../assets/icona.png";
import photoFourt from "../../assets/sculpture.png";
import photoFivth from "../../assets/columns.png";
import photoSixth from "../../assets/colocl.png";
import photoSeventh from "../../assets/newcols.png";
import { IimageCSS } from "core/types/InfoBlock";
//text
const contentOne = "history_one";
const contentTwo = "history_two";
const contentThree = "history_three";
const contentFour = "history_four";
const contentSix = "history_six";
const contentSeven = "history_seven";
const contentEight = "history_eight";
const contentNine = "history_nine";
const contentTen = "history_ten";
const contentEit = "history_eit";
const contentTw = "history_tw";
const contentFrth = "history_frth";
const contentSixt = "history_sixt";
const contentSevent = "history_sevent";
const contentEighteen = "history_eighteen";
const contentNinet = "history_ninet";
const contentTwent = "history_twent";
const contentTwentOne = "history_twentone";
const contentTwentThree = "history_twentthree";
const contentTwentFour = "history_twentfour";
const contentTwentTwo = "history_twenttwo";
const contentTwentThr = "history_twentthr";
export const desktopHistory = [
  {
    id: "box",
    header: "1091",
    content: contentOne,
  },
  {
    id: "box",
    header: "1201 - 1222",
    content: contentTwo,
  },
  {
    id: "box",
    header: "1231",
    content: contentTwentThr,
  },
  {
    id: "box",
    header: "1300",
    content: contentThree,
  },
  {
    id: "box",
    header: "1456 - 1473",
    content: contentFour,
    withImages: true,
    imageCSS: { width: "440px", height: "400px" } as IimageCSS,
    images: [photoSeventh],
  },
  {
    id: "box",
    header: "1515",
    content: contentSix,
    withImages: true,
    imageCSS: { width: "443px", height: "100%" } as IimageCSS,
    images: [photoSixth],
  },
  {
    id: "box",
    header: "1532",
    content: contentSeven,
  },
  {
    id: "box",
    header: "asi 1570",
    content: contentEight,
    withImages: true,
    imageCSS: { width: "443px", height: "100%" } as IimageCSS,
    images: [photoFivth],
  },
  {
    id: "box",
    header: "1592",
    content: contentNine,
  },
  {
    id: "box",
    header: "1694-1730",
    content: contentTen,
  },
  {
    id: "box",
    header: "1727",
    content: contentEit,
    withImages: true,
    imageCSS: { width: "440px", height: "100%" } as IimageCSS,
    images: [photoFourt],
  },
  {
    id: "box",
    header: "1750-1769.",
    content: contentTw,
    withImages: true,
    imageCSS: { width: "440px", height: "100%" } as IimageCSS,
    images: [photoThr],
  },
  {
    id: "box",
    header: "1784",
    content: contentFrth,
    withImages: true,
    imageCSS: { width: "440px", height: "100%" } as IimageCSS,
    images: [photoOne],
  },
  {
    id: "box",
    header: "1870-1879",
    content: contentSixt,
    withImages: true,
    imageCSS: { width: "440px", height: "100%" } as IimageCSS,
    images: [photoTW],
  },
  {
    id: "box",
    header: "1881",
    content: contentSevent,
    withImages: true,
    imageCSS: { width: "440px", height: "600px" } as IimageCSS,
    images: [photoElev],
  },
  {
    id: "box",
    header: "1945",
    content: contentEighteen,
    withImages: true,
    imageCSS: { width: "224px", height: "300px" } as IimageCSS,
    images: [photoTwo, photoTen],
  },
  {
    id: "box",
    header: "1964-1989",
    content: contentNinet,
  },
  {
    id: "box",
    header: "1995",
    content: contentTwent,
  },
  {
    id: "box",
    header: "2006",
    content: contentTwentOne,
    withImages: true,
    imageCSS: { width: "440px", height: "300px" } as IimageCSS,
    images: [photoNine],
  },
  {
    id: "box",
    header: "2021",
    content: contentTwentTwo,
    withImages: true,
    imageCSS: { width: "226px", height: "300px" } as IimageCSS,
    images: [photoEight, photoSeven],
  },
  {
    id: "box",
    header: "2024 - červen",
    content: contentTwentFour,
  },
  {
    header: "2024 - podzim",
    content: contentTwentThree,
    withImages: true,
    imageCSS: { width: "226px", height: "100%" } as IimageCSS,
    images: [photoSix, photoFour],
  },
];
