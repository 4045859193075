import { useQuery } from "react-query";
import axios from "axios";
import { TICKETS_API_ROUTE } from "../endpoints";
import { TicketTypes, TourList } from "../types";

export const useGetTickets = () => {
  const fetchTickets: () => Promise<TourList> = async () => {
    const res = await axios.get(`${window.BASE_API_URL}/${TICKETS_API_ROUTE}`);
    const ticketTypes = res.data.types;
    return ticketTypes;
  };

  return useQuery(`fetchTickets`, fetchTickets, {
    refetchOnWindowFocus: false,
  });
};
