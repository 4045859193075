import React, { useState } from "react";

//components
import { Box } from "@mui/material";
import styles from "./styles.module.css";
import SeeMoreButton from "src/components/atoms/SeeMoreButton";

const PersonComponent = ({
  header,
  photo,
  text,
  split,
}: {
  header: string;
  photo: string;
  text: string | undefined;
  split: number;
}): JSX.Element => {
  const [splittedText, setSplittedText] = useState<number>(split);
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const seeMore = (): void => {
    if (isOpened) {
      setSplittedText(split);
      setIsOpened(false);
    } else {
      setSplittedText(text?.length || 0);
      setIsOpened(true);
    }
  };
  return (
    <Box className={styles.wrapper}>
      <span className={styles.header}>{header}</span>
      <img alt="photo" src={photo} className={styles.image} />
      <Box
        className={styles.text}
        dangerouslySetInnerHTML={{ __html: text?.slice(0, splittedText) || "" }}
      />
      {!isOpened && <SeeMoreButton isOpened={isOpened} onClick={seeMore} />}
    </Box>
  );
};

export default PersonComponent;
