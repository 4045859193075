import React, { useEffect, useState } from "react";
import { preferButtons } from "src/core/helpers/preferbuttons";

//components
import TimePreferButton from "src/components/atoms/TimePreferButton";
import styles from "./styles.module.css";
import { Box } from "@mui/material";
import { useDispatch } from "react-redux";
import { ticketFilterAction } from "src/core/redux/actions/ticketFilterAction";
import { TicketFilterPayload } from "src/core/types/reduxTypes";
import { useTranslation } from "react-i18next";

const PreferButtons = (): JSX.Element => {
  const dispatch = useDispatch();
  const [selectedOptions, setSelectedOptions] = useState<string>("everytime");
  const { t } = useTranslation();
  const selectOption = (id: string): void => {
    setSelectedOptions(id);
    dispatch(ticketFilterAction(id as TicketFilterPayload));
  };

  useEffect(() => {
    dispatch(ticketFilterAction("everytime" as TicketFilterPayload));
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className={styles.wrapper}>
      {preferButtons.map((el) => (
        <TimePreferButton
          key={el.id}
          isSelected={selectedOptions === el.id}
          onClick={() => selectOption(el.id)}
        >
          {t(el.text_content)}
        </TimePreferButton>
      ))}
    </Box>
  );
};

export default PreferButtons;
