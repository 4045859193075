import React, { useEffect, useState } from "react";

//components
import { Box } from "@mui/material";
import styles from "./styles.module.css";
import FullPageImage from "src/components/atoms/FullPageImage";
import FullPagePagination from "src/components/atoms/FullPagePagination";
import FullPageText from "src/components/atoms/FullPageText";
import PageExpositionDesktop from "src/components/pages/PageExpositionDesktop";

const FullScreenGallery = ({ photos }: { photos: string[] }): JSX.Element => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const nextPage = (): void => {
    if (currentPage < photos.length) {
      setCurrentPage((prev) => prev + 1);
    }
  };
  const prevPage = (): void => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };
  const updateMedia = () => {
    setIsMobile(window.innerWidth <= 768);
  };
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);
  return (
    <Box className={styles.wrapper}>
      {isMobile ? (
        <FullPageImage photo={photos[currentPage - 1]} />
      ) : (
        <PageExpositionDesktop
          photo={photos[currentPage - 1]}
          prevPhoto={photos[currentPage - 2]}
          nextPhoto={photos[currentPage]}
          lastPhoto={photos[photos.length - 1]}
          firstPhoto={photos[0]}
        />
      )}
      <FullPageText order={currentPage} />
      <FullPagePagination
        pages={photos.length}
        currentPage={currentPage}
        nextPage={nextPage}
        prevPage={prevPage}
      />
    </Box>
  );
};

export default FullScreenGallery;
