import { Action, TravelFormType } from "src/core/types/reduxTypes";

const initialState = {
  name: "",
  agency_name: "",
  email: "",
  phone_number: null,
  send_promo: true,
  discount: "",
};
// eslint-disable-next-line @typescript-eslint/default-param-last
export const TravelFormReducer = (state = initialState, action: Action<TravelFormType, any>) => {
  switch (action?.type) {
    case "SET_USER_FORM":
      return {
        ...state,
        name: action.payload.name,
        email: action.payload.email,
        phone_number: action.payload.phone_number,
        agency_name: action.payload.agency_name,
      };
    case "SET_PROMO":
      return {
        ...state,
        send_promo: action.payload,
      };
    case "SET_DISCOUNT":
      return {
        ...state,
        discount: action.payload,
      };
    default:
      return state;
  }
};
