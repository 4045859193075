import React from "react";

export const WaitingIcon = (): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="157"
      height="156"
      viewBox="0 0 157 156"
      fill="none"
    >
      <path
        d="M97.0534 30.6429C94.7784 30.6429 92.8555 29.8574 91.2847 28.2866C89.7139 26.7158 88.9284 24.7929 88.9284 22.5179C88.9284 20.2429 89.7139 18.3199 91.2847 16.7491C92.8555 15.1783 94.7784 14.3929 97.0534 14.3929C99.3284 14.3929 101.251 15.1783 102.822 16.7491C104.393 18.3199 105.178 20.2429 105.178 22.5179C105.178 24.7929 104.393 26.7158 102.822 28.2866C101.251 29.8574 99.3284 30.6429 97.0534 30.6429ZM100.474 108.55L72.5237 80.6V45.5H85.5237V75.4L109.574 99.45L100.474 108.55Z"
        fill="#E31340"
      />
      <path
        d="M117.594 45.0009C119.165 46.5717 121.088 47.3571 123.363 47.3571C125.638 47.3571 127.561 46.5717 129.131 45.0009C130.702 43.4301 131.488 41.5071 131.488 39.2321C131.488 36.9571 130.702 35.0342 129.131 33.4634C127.561 31.8926 125.638 31.1071 123.363 31.1071C121.088 31.1071 119.165 31.8926 117.594 33.4634C116.023 35.0342 115.238 36.9571 115.238 39.2321C115.238 41.5071 116.023 43.4301 117.594 45.0009Z"
        fill="#E31340"
      />
      <path
        d="M128.737 72.858C130.308 74.4289 132.231 75.2143 134.506 75.2143C136.781 75.2143 138.704 74.4289 140.274 72.858C141.845 71.2872 142.631 69.3643 142.631 67.0893C142.631 64.8143 141.845 62.8914 140.274 61.3205C138.704 59.7497 136.781 58.9643 134.506 58.9643C132.231 58.9643 130.308 59.7497 128.737 61.3205C127.166 62.8914 126.381 64.8143 126.381 67.0893C126.381 69.3643 127.166 71.2872 128.737 72.858Z"
        fill="#E31340"
      />
      <path
        d="M79.0237 143C70.032 143 61.582 141.294 53.6737 137.881C45.7653 134.469 38.8862 129.838 33.0362 123.988C27.1862 118.138 22.5549 111.258 19.1424 103.35C15.7299 95.4417 14.0237 86.9917 14.0237 78C14.0237 69.0083 15.7299 60.5583 19.1424 52.65C22.5549 44.7417 27.1862 37.8625 33.0362 32.0125C38.8862 26.1625 45.7653 21.5312 53.6737 18.1187C61.582 14.7062 70.032 13 79.0237 13V26C64.507 26 52.2112 31.0375 42.1362 41.1125C32.0612 51.1875 27.0237 63.4833 27.0237 78C27.0237 92.5167 32.0612 104.813 42.1362 114.888C52.2112 124.963 64.507 130 79.0237 130C84.5487 130 89.8299 129.188 94.8674 127.563C99.9049 125.938 108.761 120.738 108.761 120.738C108.761 120.738 128.46 106.635 130.64 88.2384C133.024 88.6717 134.626 89.3208 136.63 90.5125C138.634 91.7042 140.372 93.1482 141.911 94.9C137.958 110.127 126.515 123.402 119.649 128.863C112.783 134.323 107.922 136.771 101.042 139.262C94.1633 141.754 86.8237 143 79.0237 143Z"
        fill="#E31340"
      />
    </svg>
  );
};
