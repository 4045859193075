import React from "react";

//svg
import call from "../../../assets/call.svg";
import mail from "../../../assets/mail (2).svg";
//components
import styles from "./styles.module.css";
import { useTranslation } from "react-i18next";

const DidNotFound = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>{t("didnt_find")}</div>
      <div className={styles.sub_wrapper}>
        <img src={call} alt="" />
        <a href="tel:+420736117117" className={styles.text}>
          +420 736 117 117
        </a>
      </div>
      <div className={styles.sub_wrapper}>
        <img src={mail} alt="" />
        <a href="mailto:info@visitjakub.cz" className={styles.text}>
          info@visitjakub.cz
        </a>
      </div>
    </div>
  );
};

export default DidNotFound;
