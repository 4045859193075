import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import { RedPoint } from "src/components/icons/RedPoint";
import { Modal, Popover } from "@mui/material";
import cross from "src/assets/cross_red.png";

const VisitRules = ({
  open,
  handleClose,
  anchorEl,
  headerIcon,
  headerText,
  iconsArr,
  mainText,
  textWithCircle,
}: {
  headerIcon: React.ReactNode;
  headerText: string;
  iconsArr: string[];
  mainText: string;
  textWithCircle: string[];
  open: boolean;
  handleClose: () => void;
  anchorEl: any;
}): JSX.Element => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const editStyle = (): string => {
    if (headerText === "PEVNÁ OBUV a vhodné oblečení" && !isMobile) {
      return styles.header_modified;
    } else {
      return styles.header;
    }
  };

  const updateMedia = () => {
    setIsMobile(window.innerWidth <= 768);
  };
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);

  return (
    <>
      {!isMobile ? (
        <Modal
          open={open}
          onClose={handleClose}
          sx={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className={styles.wrapper}>
            <div className={styles.cross_wrapper}>
              <img src={cross} onClick={handleClose} alt="" />
            </div>
            <div className={styles.header}>{headerText}</div>
            <div className={styles.iconsWrapper}>
              {iconsArr.map((icon, index) => (
                <img key={index} style={{ width: "52px", height: "53px" }} src={icon} alt="" />
              ))}
            </div>
            <div className={styles.content} dangerouslySetInnerHTML={{ __html: mainText }} />
            <div className={styles.list}>
              {textWithCircle.map((text, index) => (
                <div key={index} className={styles.list_content}>
                  <RedPoint />
                  <span className={styles.main_text}>{text}</span>
                </div>
              ))}
            </div>
          </div>
        </Modal>
      ) : (
        <Popover open={open} onClose={handleClose} anchorEl={anchorEl}>
          <div className={styles.wrapper}>
            <div className={editStyle()}>
              {headerText}
              <img src={cross} onClick={handleClose} alt="" />
            </div>
            <div className={styles.iconsWrapper}>
              {iconsArr.map((icon, index) => (
                <img key={index} style={{ width: "52px", height: "53px" }} src={icon} alt="" />
              ))}
            </div>
            <div className={styles.content} dangerouslySetInnerHTML={{ __html: mainText }} />
            <div className={styles.list}>
              {textWithCircle.map((text, index) => (
                <div key={index} className={styles.list_content}>
                  <RedPoint />
                  <span className={styles.main_text}>{text}</span>
                </div>
              ))}
            </div>
          </div>
        </Popover>
      )}
    </>
  );
};

export default VisitRules;
