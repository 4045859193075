import { TicketFilterReducer } from "src/core/redux/reducers/TicketFilterReducer";
import { SelectedTicketReducer } from "src/core/redux/reducers/SelectedTicketReducer";
import { TravelFormReducer } from "src/core/redux/reducers/TravelFormReducer";
import { DateReducer } from "src/core/redux/reducers/DateReducer";
// eslint-disable-next-line import/namespace
import { ReservationReducer } from "src/core/redux/reducers/ReservationReducer";
import { MobileReducer } from "src/core/redux/reducers/MobileReducer";
import { GalleryReducer } from "src/core/redux/reducers/GalleryReducer";

const reducers = {
  ticketFilters: TicketFilterReducer,
  selectedTicket: SelectedTicketReducer,
  travelForm: TravelFormReducer,
  date: DateReducer,
  reservationID: ReservationReducer,
  isApp: MobileReducer,
  gallery: GalleryReducer,
};

export default reducers;
