import React from "react";

export const ScheduleIcon = (): JSX.Element => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.75 12.2327V8.28657C12.75 8.07409 12.6781 7.89597 12.5343 7.75222C12.3904 7.60847 12.2122 7.5366 11.9997 7.5366C11.7871 7.5366 11.609 7.60847 11.4654 7.75222C11.3218 7.89597 11.25 8.07409 11.25 8.28657V12.4635C11.25 12.5812 11.2718 12.6953 11.3154 12.8056C11.359 12.916 11.4276 13.018 11.5212 13.1116L14.9462 16.5366C15.0846 16.675 15.2587 16.7459 15.4683 16.7491C15.6779 16.7523 15.8551 16.6815 16 16.5366C16.1448 16.3917 16.2173 16.2161 16.2173 16.0097C16.2173 15.8033 16.1448 15.6276 16 15.4828L12.75 12.2327ZM12.0016 22.0366C10.6877 22.0366 9.45268 21.7872 8.29655 21.2886C7.1404 20.7899 6.13472 20.1132 5.2795 19.2583C4.42427 18.4035 3.74721 17.3982 3.24833 16.2426C2.74944 15.087 2.5 13.8522 2.5 12.5383C2.5 11.2243 2.74933 9.98931 3.248 8.83317C3.74667 7.67702 4.42342 6.67134 5.27825 5.81612C6.1331 4.96089 7.13834 4.28383 8.29398 3.78495C9.44959 3.28606 10.6844 3.03662 11.9983 3.03662C13.3122 3.03662 14.5473 3.28596 15.7034 3.78462C16.8596 4.28329 17.8652 4.96004 18.7205 5.81487C19.5757 6.66972 20.2527 7.67496 20.7516 8.8306C21.2505 9.98621 21.5 11.221 21.5 12.5349C21.5 13.8489 21.2506 15.0839 20.752 16.24C20.2533 17.3962 19.5765 18.4019 18.7217 19.2571C17.8669 20.1123 16.8616 20.7894 15.706 21.2882C14.5504 21.7871 13.3156 22.0366 12.0016 22.0366ZM12 20.5366C14.2166 20.5366 16.1041 19.7574 17.6625 18.1991C19.2208 16.6408 20 14.7533 20 12.5366C20 10.3199 19.2208 8.43243 17.6625 6.8741C16.1041 5.31576 14.2166 4.5366 12 4.5366C9.78331 4.5366 7.89581 5.31576 6.33748 6.8741C4.77914 8.43243 3.99998 10.3199 3.99998 12.5366C3.99998 14.7533 4.77914 16.6408 6.33748 18.1991C7.89581 19.7574 9.78331 20.5366 12 20.5366Z"
        fill="#E31240"
      />
    </svg>
  );
};
