import React from "react";
import styles from "./styles.module.css";
import {
  ActiveExposition,
  PassiveExpositionLeft,
  PassiveExpositionRight,
} from "src/components/atoms/StyledComponents/ActiveExposition";
const PageExpositionDesktop = ({
  photo,
  prevPhoto,
  nextPhoto,
  lastPhoto,
  firstPhoto,
}: {
  photo: string;
  prevPhoto: string;
  nextPhoto: string;
  lastPhoto: string;
  firstPhoto: string;
}): JSX.Element => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.images}>
        {prevPhoto ? (
          <PassiveExpositionLeft src={prevPhoto} />
        ) : (
          <PassiveExpositionLeft src={lastPhoto} />
        )}
        <ActiveExposition src={photo} />
        {nextPhoto ? (
          <PassiveExpositionRight src={nextPhoto} />
        ) : (
          <PassiveExpositionRight src={firstPhoto} />
        )}
      </div>
    </div>
  );
};

export default PageExpositionDesktop;
