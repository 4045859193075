import React from "react";
import { useNavigate } from "react-router-dom";

//components
import { Box } from "@mui/material";
import styles from "./styles.module.css";
import ArrowLeft from "src/components/icons/ArrowLeft";
import { useTranslation } from "react-i18next";

const SubPageHeader = ({
  pageName,
  background,
}: {
  pageName: string;
  background?: string;
}): JSX.Element => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const navigateToHome = (): void => {
    navigate("/");
  };
  return (
    <Box className={styles.wrapper} sx={{ background: background || "#FFF" }}>
      <Box className={styles.sub_wrapper}>
        <Box className={styles.button_back} onClick={navigateToHome}>
          <ArrowLeft />
          <span className={styles.text_back}>{t("back")}</span>
        </Box>
        <span className={styles.text} dangerouslySetInnerHTML={{ __html: pageName }} />
      </Box>
    </Box>
  );
};

export default SubPageHeader;
