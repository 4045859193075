import React from "react";

export const TimeArrow = ({ reverse }: { reverse: boolean }): JSX.Element => {
  return (
    <svg
      width="140"
      height="24"
      viewBox="0 0 140 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        rotate: reverse ? "180deg" : "0deg",
        width: "136px",
        top: "100px",
        left: reverse ? "470px" : "587px",
      }}
    >
      <path
        d="M139.697 11.9541L129.697 6.1806V17.7276L139.697 11.9541ZM12.3848 12.9541H130.697V10.9541H12.3848V12.9541Z"
        fill="#062762"
      />
      <circle
        cx="11.3022"
        cy="11.9541"
        r="10.3022"
        fill="#062762"
        stroke="#062762"
        strokeWidth="2"
      />
    </svg>
  );
};
