import React, { useState } from "react";
import { useAskQuestion } from "src/core/queries/useAskQuestion";
import styless from "src/components/atoms/TravelForm/style.module.css";
import {
  Box,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Button,
  Checkbox,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import errorIcon from "src/assets/error (1).svg";
import checkmarkIcon from "src/assets/checkmark (1).svg";
import { useTranslation } from "react-i18next";
import { styles } from "src/components/atoms/TravelForm/styles";
import arrowDown from "src/assets/arrow_white.svg";
import ModalCondition from "src/components/atoms/ModalCondition";
import SecurityData from "src/components/atoms/SecurityData";

interface IForm {
  sender: string;
  body: string;
}

const FAQForm = (): JSX.Element => {
  const { t } = useTranslation();
  const [isFormSent, setIsFormSent] = useState<boolean>(false);
  const [checkBoxState, setCheckBoxState] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  // queries
  const { mutate: sendQuestion } = useAskQuestion();

  const questionForm = useForm({
    defaultValues: {
      sender: "",
      body: "",
    },
    reValidateMode: "onChange",
    mode: "all",
  });

  const { control, handleSubmit } = questionForm;

  const handleSendQuestion = (formData: IForm) => {
    if (formData.body.length <= 500) {
      sendQuestion(formData);
      setIsFormSent(true);
      questionForm.reset({ sender: "", body: "" });
    }
  };

  return (
    <>
      <Box className={styless.header}>{t("fill_body")}</Box>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          if (!isFormSent) {
            handleSubmit(handleSendQuestion)();
          }
        }}
      >
        <Box className={styless.textfieldBox}>
          <FormControl className={styless.question} sx={{ marginLeft: "10px" }}>
            <InputLabel
              htmlFor="sender"
              sx={{
                "&.MuiFormLabel-colorPrimary.MuiFormLabel-filled": {
                  fontSize: "18px",
                  fontWeight: "400",
                  color: "#000000",
                  backgroundColor: "white",
                  padding: "0 4px",
                },
                "&.MuiFormLabel-colorPrimary.MuiFormLabel-filled:hover": {
                  fontSize: "18px",
                  fontWeight: "400",
                  color: "#000000",
                  backgroundColor: "white",
                  padding: "0 4px",
                },
                "&.Mui-focused": {
                  fontSize: "18px",
                  fontWeight: "400",
                  color: "#000000",
                  backgroundColor: "white",
                  padding: "0 4px",
                },
                "&.Mui-error": {
                  fontSize: "18px",
                  fontWeight: "400",
                  color: "#E31340",
                  backgroundColor: "white",
                  padding: "0 4px",
                },
              }}
            >
              {t("email_address")}
            </InputLabel>
            <Controller
              name="sender"
              control={control}
              rules={{
                required: t("fill_in_valid_email_address"),
                validate: (v) =>
                  /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                  t("fill_in_valid_email_address"),
              }}
              render={({ field, fieldState: { isTouched, error } }) => (
                <React.Fragment>
                  <OutlinedInput
                    id="sender"
                    value={field.value}
                    onChange={field.onChange}
                    endAdornment={
                      <InputAdornment position="end">
                        {error ? (
                          <img alt="errorIcon" src={errorIcon} style={{ width: "16px" }} />
                        ) : isTouched && !error ? (
                          <img alt="checkmarkIcon" src={checkmarkIcon} style={{ width: "16px" }} />
                        ) : null}
                      </InputAdornment>
                    }
                    error={!!error}
                    label={"sender"}
                    sx={{
                      "& input": {
                        color: error ? "#E31340" : "#000000",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: error ? "#E31340" : "#79747E",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: error ? "#E31340" : "#062762",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: "1px solid #000000",
                        outline: "none",
                        boxShadow: "none",
                      },
                    }}
                  />
                  {error && <FormHelperText error>{error.message}</FormHelperText>}
                </React.Fragment>
              )}
            />
          </FormControl>
        </Box>
        <Box className={styless.textfieldBox}>
          <FormControl className={styless.question} sx={{ marginLeft: "10px" }}>
            <InputLabel
              htmlFor="body"
              aria-multiline={true}
              sx={{
                "&.MuiFormLabel-colorPrimary.MuiFormLabel-filled": {
                  fontSize: "18px",
                  fontWeight: "400",
                  color: "#000000",
                  backgroundColor: "white",
                  padding: "0 4px",
                },
                "&.MuiFormLabel-colorPrimary.MuiFormLabel-filled:hover": {
                  fontSize: "18px",
                  fontWeight: "400",
                  color: "#000000",
                  backgroundColor: "white",
                  padding: "0 4px",
                },
                "&.Mui-focused": {
                  fontSize: "18px",
                  fontWeight: "400",
                  color: "#000000",
                  backgroundColor: "#fff",
                  padding: "0 14px",
                },
                "&.Mui-error": {
                  fontSize: "18px",
                  fontWeight: "400",
                  color: "#E31340",
                  backgroundColor: "white",
                  padding: "0 4px",
                },
              }}
            >
              {t("body")}
            </InputLabel>
            <Controller
              name="body"
              control={control}
              rules={{
                required: t("fill_body"),
                maxLength: {
                  value: 500,
                  message: t("body_max_length"),
                },
              }}
              render={({ field, fieldState: { isTouched, error } }) => (
                <React.Fragment>
                  <OutlinedInput
                    id="body"
                    multiline={true}
                    value={field.value}
                    onChange={field.onChange}
                    endAdornment={
                      <InputAdornment position="end">
                        {error ? (
                          <img alt="errorIcon" src={errorIcon} style={{ width: "16px" }} />
                        ) : isTouched && !error ? (
                          <img alt="checkmarkIcon" src={checkmarkIcon} style={{ width: "16px" }} />
                        ) : null}
                      </InputAdornment>
                    }
                    error={!!error}
                    inputProps={{ maxLength: 500 }}
                    sx={{
                      minHeight: "200px",
                      alignItems: "flex-start",
                      "& .MuiOutlinedInput-input": {
                        textAlign: "left",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: error ? "#E31340" : "#79747E",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: error ? "#E31340" : "#062762",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: "1px solid #000000",
                        outline: "none",
                        boxShadow: "none",
                      },
                    }}
                  />
                  {error && <FormHelperText error>{error.message}</FormHelperText>}
                </React.Fragment>
              )}
            />
          </FormControl>
        </Box>
        <Box className={styless.footerItems}>
          <div className={styless.checkBoxWrapper}>
            <Checkbox
              checked={checkBoxState}
              onChange={(e, value) => {
                if (!isFormSent) setCheckBoxState(value);
              }}
            />
            <span className={styless.checkBoxText}>
              {t("agree_with")}
              <a style={{ textDecoration: "underline" }} onClick={() => setOpenModal(true)}>
                {" "}
                <span dangerouslySetInnerHTML={{ __html: t("agreement_with_personal_data") }} />
              </a>
            </span>
          </div>
          <Button
            type="submit"
            disabled={!checkBoxState}
            className={styless.buttonToGo}
            sx={{
              ...styles.buttonToGo,
              backgroundColor: isFormSent ? "#58BC51" : "#E31340",
              "&:hover": {
                backgroundColor: isFormSent ? "#58BC51" : "#E31340",
                color: "white",
              },
              "&:disabled": {
                backgroundColor: "grey",
                color: "white",
              },
            }}
            style={{ textDecoration: "none" }}
          >
            <span style={{ marginRight: "5px" }}>
              {isFormSent ? t("group_sent_ready") : t("group_sent")}
            </span>
            <img alt="arrowDown" src={arrowDown} />
          </Button>
        </Box>
      </form>
      <ModalCondition open={openModal} handleClose={() => setOpenModal(false)}>
        {({ handleClose }) => <SecurityData handleClose={handleClose} isModal={true} />}
      </ModalCondition>
      {isFormSent && (
        <Box className={styless.thanks_text}>
          {t("thanks_faq")}
          <br />
          {t("feedback_faq")}
        </Box>
      )}
    </>
  );
};

export default FAQForm;
