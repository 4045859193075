import React from "react";
import styles from "./styles.module.css";

const TransportNumber = ({ number }: { number: number }): JSX.Element => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.number}>{number}</div>
    </div>
  );
};
export default TransportNumber;
