import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const FlagCS: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon {...props} width="21.368" height="14" viewBox="0 0 21.368 14">
    <svg xmlns="http://www.w3.org/2000/svg">
      <path
        id="Path_19"
        data-name="Path 19"
        d="M.136,104.232l10.548-6.911L.136,90.41A.362.362,0,0,0,0,90.689v13.263A.362.362,0,0,0,.136,104.232Z"
        transform="translate(0 -90.321)"
        fill="#41479b"
      />
      <path
        id="Path_20"
        data-name="Path 20"
        d="M3.256,262.911a.362.362,0,0,0,.233.089H24.12a.368.368,0,0,0,.368-.368V256H13.8Z"
        transform="translate(-3.12 -249)"
        fill="#ff4b55"
      />
      <path
        id="Path_21"
        data-name="Path 21"
        d="M3.256,88.365a.362.362,0,0,1,.233-.089H24.12a.368.368,0,0,1,.368.368v6.632H13.8Z"
        transform="translate(-3.12 -88.276)"
        fill="#f5f5f5"
      />
    </svg>
  </SvgIcon>
);
