import React from "react";

export const ArrowDownHistory = (): JSX.Element => {
  return (
    <svg width="18" height="53" viewBox="0 0 18 53" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.30273 52.9539L17.963 37.9539H0.64248L9.30273 52.9539ZM7.80273 0.814941L7.80273 39.4539H10.8027L10.8027 0.814941L7.80273 0.814941Z"
        fill="#062762"
      />
    </svg>
  );
};
