import { BusIcon } from "src/components/icons/BusIcon";
import { TramIcon } from "src/components/icons/TramIcon";

export const transport = [
  {
    header: "stop_station_one",
    icon: <TramIcon />,
    tName: "tram",
    numbers: [4, 8, 9],
  },
  {
    header: "stop_station_two",
    icon: <TramIcon />,
    tName: "tram",
    numbers: [3, 4, 5, 6, 8, 9, 10, 12],
  },
  {
    header: "",
    icon: <BusIcon />,
    tName: "trolleybus",
    numbers: [32, 34, 36],
  },
];
