export const styles = {
  timerNote: {
    color: "#FFFFFF",
    width: "100%",
    textAlign: "start",
    fontSize: "12px",
    whiteSpace: "nowrap",
  },
  box: {
    position: "fixed",
    top: "13%",
    left: "100%",
    transform: "translate(-95%, -50%)",
    zIndex: "100",
  },
  middleBox: {
    position: "fixed",
    top: "17%",
    left: "100%",
    transform: "translate(-95%, -50%)",
    zIndex: "100",
  },
  boxExtraSmall: {
    position: "fixed",
    top: "17%",
    left: "100%",
    transform: "translate(-95%, -50%)",
    zIndex: "100",
  },
  box_app: {
    position: "fixed",
    top: "4%",
    left: "100%",
    transform: "translate(-95%, -50%)",
    zIndex: "100",
  },
  relative: {
    position: "absolute",
    top: "-2px",
    left: "230px",
  },
  button: {
    opacity: "1",
    transition: " opacity 0.5s ease-in-out",
    textDecoration: "none",
    backgroundColor: "#062762",
    color: "#FFFFFF",
    "&:hover": {
      color: "#FFFFFF",
    },
    width: "134",
    height: "58px",
    padding: "10px 20px",
    display: "flex",
    borderRadius: "10px",
  },
  div: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  timerWrapper: {
    opacity: "1",
    transition: "opacity 0.5s ease-in-out",
  },
  desktopButton: {
    opacity: "1",
    transition: " opacity 0.5s ease-in-out",
    textDecoration: "none",
    backgroundColor: "#062762",
    color: "#FFFFFF",
    "&:hover": {
      color: "#FFFFFF",
    },
    width: "280px",
    height: "96px",
    padding: "10px 20px",
    display: "flex",
    borderRadius: "10px",
  },
  timerNoteDesktop: {
    color: "#FFFFFF",
    width: "100%",
    textAlign: "start",
    fontSize: "17px",
    whiteSpace: "nowrap",
  },
};
