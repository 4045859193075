import { FormControl, MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";
import i18n from "src/i18n/config";
import { languages } from "src/core/lists/languages";
import { useSelector } from "react-redux";
import { RootState } from "src/core/redux/store";

export const LocSelect = ({ style }: { style: any }) => {
  const [pickedLang, setPickedLang] = useState(i18n.language || "cs");
  const globalLang = useSelector((state: RootState) => state.isApp?.appLang);

  const handleLanguageChange = (language: string) => {
    setPickedLang(language);
    i18n.changeLanguage(language, (err, t) => {
      if (err) return console.log("something went wrong loading", err);
      t("key");
    });
    sessionStorage.setItem("loc", language);
  };

  useEffect(() => {
    if (globalLang) {
      setPickedLang(globalLang);
      i18n.changeLanguage(globalLang, (err, t) => {
        if (err) return console.log("something went wrong loading", err);
        t("key");
      });
      sessionStorage.setItem("loc", globalLang);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalLang]);

  return (
    <FormControl style={style}>
      <Select
        labelId="demo-controlled-open-select-label"
        id="demo-controlled-open-select"
        value={pickedLang}
        onChange={(e) => handleLanguageChange(e.target.value)}
        defaultValue={pickedLang}
      >
        {languages.map((lang) => (
          <MenuItem
            key={lang.name}
            value={lang.name}
            sx={{
              display: lang.name === pickedLang ? "none" : "grid",
              gridTemplateColumns: "auto 1fr",
              gap: "10px",
              backgroundColor: "white",
              fontWeight: 400,
              marginRight: "20px",
              fontSize: "16px",
              color: "#000000",
              padding: "10px 10px 10px 15px",
              "&:hover": {
                backgroundColor: "white",
              },
              "&:active": {
                backgroundColor: "white",
                color: "#062762",
              },
            }}
          >
            {lang.icon}
            {lang.name.toUpperCase()}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
