import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import timeIcon from "../../../assets/hodiny_cervene.svg";
import { useFormValuesContext } from "src/core/context";
import { useNavigate } from "react-router-dom";
import { Slot } from "src/core/types";
import styles from "./styles.module.css";
import ArrowSend from "src/components/icons/ArrowSend";
import PreferButtons from "src/components/molecules/PreferButtons";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/core/redux/store";
import { selectTicket } from "src/core/redux/actions/selectTicket";

export const Slots = ({
  fullSlots,
  onSubmit,
}: {
  selectedDate: Date;
  onClick: (value: Date) => void;
  onSubmit: () => void;
  fullSlots: {
    id: number;
    time: string;
    basic_price: number;
    capacity: number;
    visitors: number;
  }[];
}) => {
  const selectedFilters = useSelector((state: RootState) => state.ticketFilters?.selectedFilter);
  const filterSlotsByDayTime = (
    filter: string,
  ): {
    id: number;
    time: string;
    basic_price: number;
    capacity: number;
    visitors: number;
  }[] => {
    switch (filter) {
      case "morning":
        return fullSlots.filter((el) => {
          const hours = Number(el.time.slice(0, 2));
          const minutes = Number(el.time.slice(3));
          return hours < 12 || (hours === 12 && minutes === 0);
        });
      case "evening":
        return fullSlots.filter((el) => Number(el.time.slice(0, 2)) >= 12);
      default:
        return fullSlots;
    }
  };

  const style: Record<string, React.CSSProperties> = {
    button: {
      fontSize: "20px",
      fontWeight: 600,
      lineHeight: "24px",
      textAlign: "left",
      backgroundColor: "#FFFFFF",
      textDecoration: "none",
      borderRadius: "5px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
      padding: "10px",
      gap: "2px",
    },
    time: {
      fontSize: "20px",
      fontWeight: "600",
      marginRight: "20px",
    },
    line: {
      color: "red",
      width: "36px",
    },
    price: {
      fontSize: "20px",
      fontWeight: "600",
      color: "#E31340",
    },
    text: {
      color: "#062762",
      fontWeight: "bold",
      fontSize: "18px",
      display: "flex",
      alignItems: "center",
    },
  };
  const slotStyle = {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  };

  const slotStyleDesktop = {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-around",
  };

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { formValues, setFormValues } = useFormValuesContext();
  const [selectedSlot, setSelectedSlot] = useState<Slot | null>(null);
  const [, setSelectedSlotId] = useState<boolean | null>(null);
  const [, setSelectedSlotTime] = useState("");
  const [isDesktop, setIsDesktop] = useState<boolean>(window.innerWidth > 768);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 1023);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const notEnoughAvailableSpots = fullSlots.some(
    (slot) => slot.capacity - (slot.visitors || 0) < formValues.ticketsCount,
  );

  const handleSlotClick = (slot: Slot) => {
    const isSlotSelected = selectedSlot && selectedSlot.id === slot.id;
    dispatch(selectTicket(slot));

    if (isSlotSelected) {
      setFormValues({
        ...formValues,
        time_slot_id: null,
        free_capacity: null,
        time: "",
      });

      sessionStorage.setItem("time_slot_id", "");
      sessionStorage.setItem("freeCapacity", "");
      sessionStorage.setItem("time", "");
      sessionStorage.removeItem("selectedSlotId");

      setSelectedSlot(null);
      setSelectedSlotId(null);
      setSelectedSlotTime("");
    } else {
      setFormValues({
        ...formValues,
        time_slot_id: slot.id,
        time: slot.time,
        free_capacity: slot.capacity - slot.visitors,
      });

      sessionStorage.setItem("time_slot_id", String(slot.id));
      sessionStorage.setItem("free_capacity", String(slot.capacity - slot.visitors));
      sessionStorage.setItem("time", String(slot.time));
      sessionStorage.setItem("selectedSlotId", slot.id.toString());

      setSelectedSlot(slot);
      setSelectedSlotId(true);
      setSelectedSlotTime(slot.time);
    }
  };

  useEffect(() => {
    const savedSlotId = sessionStorage.getItem("selectedSlotId");
    if (savedSlotId) {
      const savedSlot = fullSlots.find((slot) => slot.id === Number(savedSlotId));
      if (savedSlot) {
        setSelectedSlot(savedSlot);
        setSelectedSlotId(true);
      }
    }
  }, [fullSlots]);

  useEffect(() => {
    if (selectedSlot) {
      onSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSlot]);

  return (
    <Box className={styles.container}>
      <Typography
        style={{
          ...style.text,
          marginRight: "10px",
          marginBottom: `${isDesktop ? "13px" : "0"}`,
          gap: "10px",
        }}
      >
        <img alt="timeIcon" src={timeIcon} />
        {[t("prefer_title_time")]}
      </Typography>
      <PreferButtons />
      <Box className={styles.wrapper}>
        {filterSlotsByDayTime(selectedFilters).map((slot) => {
          const soldOut = (slot.visitors || 0) >= slot.capacity;
          const onlyOneAvailableSpots = slot.capacity - (slot.visitors || 0) === 1;
          const fromTwoToFourAvailableSpots =
            slot.capacity - (slot.visitors || 0) === 2 ||
            slot.capacity - (slot.visitors || 0) === 4;

          const isSelected = selectedSlot && slot.id === selectedSlot.id;

          return (
            <Button
              key={slot.id}
              disabled={soldOut || notEnoughAvailableSpots}
              onClick={() => handleSlotClick(slot)}
              className={styles.button}
              style={{
                ...style.button,
                width: `${isDesktop ? "139px" : "100%"}`,
                color: isSelected ? "#E31340" : "#062762",
                border: isSelected ? "2px solid #E31340" : "1px solid #E1E1E1",
              }}
            >
              <div style={isDesktop ? slotStyleDesktop : slotStyle}>
                <span style={style.time}>{slot.time.slice(0, 5)}</span>
                <Typography
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "15px",
                    fontFamily: "Montserrat",
                    lineHeight: "20px",
                    textAlign: "left",
                    color: onlyOneAvailableSpots || soldOut || isSelected ? "#E31340" : "#062762",
                    fontWeight: onlyOneAvailableSpots || soldOut ? 600 : 300,
                  }}
                >
                  {soldOut
                    ? t("sold_out")
                    : fromTwoToFourAvailableSpots
                    ? `${slot.capacity - (slot.visitors || 0)} ${[
                        t("from_2_to_4_available_tickets"),
                      ]}`
                    : onlyOneAvailableSpots
                    ? `${slot.capacity - (slot.visitors || 0)} ${[t("1_available_ticket")]}`
                    : `${slot.capacity - (slot.visitors || 0)} ${[
                        t("5_and_more_available_tickets"),
                      ]}`}
                </Typography>
                <div style={{ margin: "0 auto" }}>
                  <ArrowSend />
                </div>
              </div>
            </Button>
          );
        })}
      </Box>
    </Box>
  );
};
