import React, { useEffect, useRef } from "react";
import { Box } from "@mui/material";

//components
import photo from "../../../assets/Visualove_puda_u_Jakuba-5.jpg";
import PurchasingTickets from "src/components/atoms/PurchasingTikets";
import styles from "./styles.module.css";
import style from "src/components/atoms/PurchasingTikets/styles.module.css";
import { useTranslation } from "react-i18next";
import MoneyCounter from "src/components/molecules/MoneyCounter";

const PageSetTypeOfCustomers = (): JSX.Element => {
  const { t } = useTranslation();
  const buttonsRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (buttonsRef && buttonsRef.current) {
        window.scrollTo({
          top: buttonsRef.current?.offsetTop - 750,
          behavior: "smooth",
        });
      }
    }, 1300);
    return () => clearTimeout(timer);
  }, []);
  return (
    <Box className={styles.wrapper}>
      <Box className={styles.content_wrapper}>
        <img className={styles.main_image} src={photo} alt="main_photo" />
        <Box className={styles.sub_content_wrapper}>
          <span className={`${style.title} ${styles.main_text}`}>
            {t("multimedial_exposition")}
          </span>
          <Box className={styles.footer}>
            <span className={`${styles.footer1}`}>{t("multimedial_exposition_show")}</span>
          </Box>
        </Box>
      </Box>
      <MoneyCounter />
      <Box ref={buttonsRef} className={styles.tickets_header}>
        {t("tickets2")}
      </Box>
      <PurchasingTickets />
    </Box>
  );
};

export default PageSetTypeOfCustomers;
