export const styles = {
  title: {
    color: "#062762",
    fontSize: "16px",
    fontWeight: "bold",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  partAgreement: {
    display: "flex",
    flexDirection: "column",
    padding: "0px 10px 15px 0px !important",
    maxHeight: "20%",
  },
  saleButton: {
    fontSize: "16px",
    fontWeight: "400",
    width: "70px",
    height: "40px",
    background: "#FFFFFF",
    border: "1px solid #E31340",
    color: "#E31340",
    textDecoration: "none",
    borderRadius: "20px",
    margin: "0px 0px 25px 10px",
  },
  saleButtonDE: {
    fontSize: "16px",
    fontWeight: "400",
    width: "120px",
    height: "40px",
    background: "#FFFFFF",
    border: "1px solid #E31340",
    color: "#E31340",
    textDecoration: "none",
    borderRadius: "20px",
    margin: "0px 0px 20px 10px",
  },
  saleButtonDEMob: {
    fontSize: "16px",
    fontWeight: "400",
    width: "120px",
    height: "40px",
    background: "#FFFFFF",
    border: "1px solid #E31340",
    color: "#E31340",
    textDecoration: "none",
    borderRadius: "20px",
    margin: "0px 0px 20px 10px",
  },
  rowSale: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  rowAgreement: {
    color: "#000000",
    fontSize: "22px",
    display: "flex",
    alignItems: "center",
    marginBottom: "15px",
  },
  buttonToGo: {
    position: "relative",
    padding: "6px 12px",
    backgroundColor: "#E31340",
    color: "white",
    display: "flex",
    justifyContent: "center",
    borderRadius: "25px",
  },
  closedBox: {
    width: "1260px",
    height: "84px",
    color: "#B9B9B9",
    backgroundColor: "#F8F8F8",
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0px 50px",
  },
  rowBox: {
    display: "flex",
    alignItems: "center",
  },
  pen: {
    width: "27px",
    height: "27px",
    cursor: "pointer",
  },
  saleAmount: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "5px 0x 10px 20px",
  },
};
