export const setMobileStatus = (data: boolean) => {
  return {
    type: "SET_MOBILE",
    payload: data,
  };
};

export const setLanguage = (data: string) => {
  return {
    type: "SET_LANGUAGE",
    payload: data,
  };
};
