import { Box } from "@mui/material";
import styles from "./styles.module.css";
import ArrowLeft from "src/components/icons/ArrowLeft";
import React from "react";
import { useTranslation } from "react-i18next";

interface ISeeMoreButton {
  isOpened: boolean;
  onClick: () => void;
}

const SeeMoreButton = ({ isOpened, onClick }: ISeeMoreButton): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Box className={styles.wrapper} onClick={onClick}>
      <span className={styles.text}>{t("read_more")}</span>
      <ArrowLeft rotate={isOpened ? "90deg" : "0deg"} color={"#E31240"} />
    </Box>
  );
};

export default SeeMoreButton;
