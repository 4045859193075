import { Box, Button } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.css";
import iconButton from "src/assets/formIcon.svg";

export const SubHeader = ({
  mainPhoto,
  title,
  subtitle,
  FormClick,
}: {
  mainPhoto: string;
  title: string;
  subtitle: string;
  FormClick: () => void;
}) => {
  const { t } = useTranslation();

  const style = {
    buttonToBuy: {
      minWidth: "200px",
      minHeight: "50px",
      width: "220px",
      height: "50px",
      padding: "5px",
      flexShrink: 0,
      backgroundColor: "#E31340",
      color: "white",
      display: "flex",
      justifyContent: "center",
      borderRadius: "25px",
    },
  };
  return (
    <Box className={styles.box}>
      <Box className={styles.overlay}></Box>
      <Box className={styles.imageContainer}>
        <img alt="mainPhoto" src={mainPhoto} className={styles.image} />
      </Box>
      <Box className={styles.titles}>
        <span className={styles.subTitleKrovy} dangerouslySetInnerHTML={{ __html: t(title) }} />
        <span className={styles.subTitle}>{t(subtitle)}</span>
        <div style={{ marginTop: "140px" }}>
          <Box className={styles.buttonBox}>
            <Button
              sx={{
                ...style.buttonToBuy,
                "&:hover": {
                  backgroundColor: "#E31340",
                  color: "white",
                },
              }}
              style={{ textDecoration: "none" }}
              onClick={FormClick}
            >
              {t("group_form")}{" "}
              <img alt="iconButton" src={iconButton} style={{ marginLeft: "20px" }} />
            </Button>
          </Box>
        </div>
      </Box>
    </Box>
  );
};
