import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import done from "../../../assets/checked.svg";
import mainPhoto from "../../../assets/2023_11_12_sv. Jakub_vizualizace_prechod2.jpg";
import style from "./styles.module.css";

import { useNavigate } from "react-router-dom";
import { HOMEPAGE_ROUTE } from "src/core/routing";
import CircleNumber from "src/components/atoms/CircleNumber";
import gplay from "../../../assets/gplay.svg";
import appstore from "../../../assets/Download_on_the_App_Store_Badge_CZ_RGB_blk_092917.svg";
import gplaySmall from "../../../assets/gplaySmall.svg";
import appstoreSmall from "../../../assets/appstoreSmall.svg";

export const PageSuccessfulResult = () => {
  const { t } = useTranslation();
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768);
  const navigate = useNavigate();
  const styles = {
    button: {
      backgroundColor: "#E31340",
      textDecoration: "none",
      borderRadius: "25px",
      fontSize: "16px",
    },
  };

  const updateMedia = (): void => {
    setIsMobile(window.innerWidth <= 768);
  };
  const HomeNavigate = () => {
    navigate(HOMEPAGE_ROUTE);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);
  return (
    <Box className={style.container}>
      <Box className={style.box}>
        <Box className={style.imageContainer}>
          <img src={mainPhoto} className={style.image} style={{ objectFit: "cover" }} />
        </Box>
        <Box className={style.overlayPic}></Box>
        <Box className={style.overlay}>
          <Box className={style.text_main}>
            <div>
              <span
                className={style.overlayText1}
                dangerouslySetInnerHTML={{ __html: t("payment_success") }}
              />
            </div>
            <img src={done} className={style.icon} />
          </Box>
          <Box className={style.end_purchase}>
            {/*<Box className={style.end_purchase_header}>{t("payment_download_end")}</Box>*/}
            {/*<Box className={style.end_purchase_sub_header}>{t("in_two_steps")}</Box>*/}
            {/*<Box className={style.download_tickets}>{t("download_tickets")}</Box>*/}
            <Box className={style.text_sub}>{t("tickets_on_email")}</Box>
            <Box className={style.text_block}>
              {t("next_payment_info")}
              <Box className={style.text_block}>tel. +420 736 117 117</Box>
            </Box>
          </Box>
          <Box className={style.additionalInfo}>
            <span
              className={style.additionalInfo2}
              dangerouslySetInnerHTML={{ __html: `${t("have_tickets")} ${t("mobile_and_app")}` }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
