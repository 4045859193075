import React from "react";
import { Modal, Box } from "@mui/material";
// eslint-disable-next-line import/no-named-as-default
import styled from "styled-components";

const StyledModal = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalBox = styled(Box)`
  max-width: 90vw;
  max-height: 90vh;
  background-color: #fff;
  border-radius: 12px;
  padding: 16px;
  overflow-y: auto;
  outline: none;
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const ModalCondition = ({
  open,
  handleClose,
  children,
}: {
  open: boolean;
  handleClose: () => void;
  children: (props: { handleClose: () => void }) => React.ReactNode;
}): JSX.Element => {
  return (
    <StyledModal open={open} onClose={handleClose}>
      <ModalBox>{children({ handleClose })}</ModalBox>
    </StyledModal>
  );
};

export default ModalCondition;
