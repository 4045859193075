import React, { useEffect, useState } from "react";
import { PageSuccessfulResult } from "../PageSuccessfulResult";
import { PageErrorResult } from "../PageErrorResult";
import { useGetPaymentResult } from "src/core/queries/useGetPaymentResult";
import "./spinner.css";
import { PageSpinner } from "../PageSpinner";
import { PageSuccessfullResultMobile } from "src/components/pages/PageSuccessfullResultMobile";
import PageSuccessfulResultDesktop from "src/components/pages/PageSuccessfulResultDesktop";

export const PageWaitingForResult: React.FC = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  // const { reservationId } = useParams<{ reservationId: string }>();
  const reservationId = sessionStorage.getItem("current_reservation_id") || "";
  const isApp = sessionStorage.getItem("isApp") || null;
  const isAppFromLocal = localStorage.getItem("isApp") || null;

  const { data: paymentData, error: fetchError } = useGetPaymentResult({
    reservationId: reservationId,
  });

  const updateMedia = (): void => {
    setIsMobile(window.innerWidth <= 768);
  };

  const getSuccessResult = (): JSX.Element => {
    return isMobile ? (
      isApp || isAppFromLocal ? (
        <PageSuccessfullResultMobile />
      ) : (
        <PageSuccessfulResult />
      )
    ) : (
      <PageSuccessfulResultDesktop />
    );
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
  }, []);

  if (fetchError) {
    return <PageErrorResult />;
  }
  const renderPage = () => {
    if (paymentData) {
      switch (paymentData.status) {
        case "COMPLETED":
          return getSuccessResult();
        case "CANCELED":
          return <PageErrorResult />;
        case "PENDING":
          return <PageSpinner />;
        default:
          return <PageSpinner />;
      }
    }
    return <PageSpinner />;
  };
  return <div>{renderPage()}</div>;
};
