//photos
import firstPhoto from "../../assets/Rectangle 25 (5).png";
import secondPhoto from "../../assets/Rectangle 26 (3).png";
import thirdPhoto from "../../assets/Rectangle 27.png";
import foPhooto from "../../assets/Rectangle 28.png";
import fiPhoto from "../../assets/BeardPerson.png";

//main text
const textOne = "persons_one";
const textTwo = "persons_two";
const textThree = "persons_three";
const textFour = "persons_four";
const textFive = "persons_five";

const getProcessedText = (text: string) => {
  return text.replace(/\n/g, "<br>");
};

export const Persons = [
  {
    header: "person_header_one",
    image: firstPhoto,
    main_text: getProcessedText(textOne),
    split_length: 259,
    flexDirection: "row",
    imgHeight: 600,
  },
  {
    header: "person_header_two",
    image: secondPhoto,
    main_text: getProcessedText(textTwo),
    split_length: 364,
    flexDirection: "row-reverse",
    imgHeight: 600,
  },
  {
    header: "person_header_three",
    image: thirdPhoto,
    main_text: getProcessedText(textThree),
    split_length: 465,
    flexDirection: "row",
    imgHeight: 600,
  },
  {
    header: "person_header_four",
    image: foPhooto,
    main_text: getProcessedText(textFour),
    split_length: 595,
    flexDirection: "row-reverse",
    imgHeight: 400,
  },
  {
    header: "person_header_five",
    image: fiPhoto,
    main_text: getProcessedText(textFive),
    split_length: 370,
    flexDirection: "row",
    imgHeight: 700,
  },
];
