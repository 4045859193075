import React, { useEffect, useState } from "react";
import { Content, Header, Image, Wrapper, Text, TextWrapper } from "./StyledComponents";
import SeeMoreButton from "src/components/atoms/SeeMoreButton";

const PersonsComponentDesktop = ({
  header,
  photo,
  text,
  split,
  flexDirection,
  imgHeight,
}: {
  header: string;
  photo: string;
  text: string | undefined;
  split: number;
  flexDirection: string;
  imgHeight: number;
}): JSX.Element => {
  const [splittedText, setSplittedText] = useState<number>(split);
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768);

  const updateMedia = () => {
    setIsMobile(window.innerWidth <= 768);
  };
  const seeMore = (): void => {
    if (isOpened) {
      setSplittedText(split);
      setIsOpened(false);
    } else {
      setSplittedText(text?.length || 0);
      setIsOpened(true);
    }
  };
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);
  return (
    <Wrapper flexDirection={flexDirection}>
      <Image src={photo} imgHeight={imgHeight} />
      <Content marginBottom={split === 370 ? 250 : 0}>
        <Header>{header}</Header>
        <TextWrapper>
          <Text
            dangerouslySetInnerHTML={{
              __html: isMobile ? (text ? text.slice(0, splittedText) : "") : text || "",
            }}
          />
          {!isOpened && isMobile && <SeeMoreButton isOpened={isOpened} onClick={seeMore} />}
        </TextWrapper>
      </Content>
    </Wrapper>
  );
};

export default PersonsComponentDesktop;
