import React from "react";

export const CIrcleCrossIcon = (): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
      <path
        d="M12.0016 22.373C10.6877 22.373 9.45268 22.1237 8.29655 21.625C7.1404 21.1263 6.13472 20.4496 5.2795 19.5947C4.42427 18.7399 3.74721 17.7347 3.24833 16.579C2.74944 15.4234 2.5 14.1886 2.5 12.8747C2.5 11.5608 2.74933 10.3257 3.248 9.1696C3.74667 8.01345 4.42342 7.00777 5.27825 6.15255C6.1331 5.29732 7.13834 4.62026 8.29398 4.12137C9.44959 3.62249 10.6844 3.37305 11.9983 3.37305C13.3122 3.37305 14.5473 3.62238 15.7034 4.12105C16.8596 4.61971 17.8652 5.29646 18.7205 6.1513C19.5757 7.00615 20.2527 8.01139 20.7516 9.16702C21.2505 10.3226 21.5 11.5574 21.5 12.8713C21.5 14.1853 21.2506 15.4203 20.752 16.5764C20.2533 17.7326 19.5765 18.7383 18.7217 19.5935C17.8669 20.4487 16.8616 21.1258 15.706 21.6247C14.5504 22.1236 13.3156 22.373 12.0016 22.373ZM12 20.873C12.9382 20.873 13.8417 20.7144 14.7104 20.397C15.5791 20.0797 16.3731 19.6205 17.0923 19.0192L5.85383 7.7807C5.25896 8.49993 4.80127 9.29391 4.48075 10.1626C4.16023 11.0313 3.99998 11.9348 3.99998 12.873C3.99998 15.1064 4.77498 16.998 6.32498 18.548C7.87498 20.098 9.76664 20.873 12 20.873ZM18.1461 17.9653C18.7474 17.2461 19.2067 16.4521 19.524 15.5834C19.8413 14.7147 20 13.8112 20 12.873C20 10.6397 19.225 8.74802 17.675 7.19802C16.125 5.64802 14.2333 4.87302 12 4.87302C11.0595 4.87302 10.1539 5.03007 9.28308 5.34417C8.41228 5.65829 7.62047 6.11919 6.90765 6.72687L18.1461 17.9653Z"
        fill="#E31240"
      />
    </svg>
  );
};
