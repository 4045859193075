import React, { useState } from "react";

//components
import styles from "./styles.module.css";
import { Box } from "@mui/material";
import ArrowLeft from "src/components/icons/ArrowLeft";

const ToggleComponent = ({
  children,
  headerText,
}: {
  children: React.ReactNode;
  headerText: string;
}): JSX.Element => {
  const [isContentVisible, setIsContentVisible] = useState<boolean>(false);
  const toggleContentVisible = (): void => {
    setIsContentVisible(!isContentVisible);
  };
  return (
    <Box className={styles.wrapper}>
      <Box className={styles.header} onClick={toggleContentVisible}>
        <span className={styles.header_text}>{headerText}</span>
        <ArrowLeft color={"#1D1B20"} rotate={isContentVisible ? "90deg" : "0deg"} />
      </Box>
      <Box className={styles.content}>{isContentVisible && children}</Box>
    </Box>
  );
};

export default ToggleComponent;
