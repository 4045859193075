import React from "react";

//components
import styles from "./styles.module.css";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import Tickets from "src/components/icons/Tickets";
import { TYPE_OF_CUSTOMERS } from "src/core/routing";
import { useTranslation } from "react-i18next";

const FastTicketBuy = (): JSX.Element => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <Box className={styles.wrapper} onClick={() => navigate(TYPE_OF_CUSTOMERS)}>
      <Tickets />
      <span>{t("purchase_button")}</span>
    </Box>
  );
};

export default FastTicketBuy;
