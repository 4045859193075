import React from "react";

const ArrowLeft = ({ rotate, color }: { rotate?: string; color?: string }): JSX.Element => {
  return (
    <svg
      style={{
        rotate: rotate || "180deg",
        color: color || "#000",
        cursor: "pointer",
        userSelect: "none",
        zIndex: 12,
      }}
      xmlns="http://www.w3.org/2000/svg"
      width="10.613"
      height="18"
      viewBox="0 0 10.613 18"
      fill={color || "#000"}
    >
      <path
        id="arrow_right_red"
        d="M249-617.387l-9-9L241.612-628,249-620.575l7.388-7.388L258-626.35Z"
        transform="translate(628 258) rotate(-90)"
      />
    </svg>
  );
};

export default ArrowLeft;
