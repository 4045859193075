import React from "react";

//components
import { Box } from "@mui/material";
import styles from "./styles.module.css";
import { useTranslation } from "react-i18next";

const schedule = [
  "faq_schedule_one",
  "faq_schedule_two",
  "faq_schedule_three",
  "faq_schedule_four",
];

const FAQSchedule = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Box className={styles.wrapper}>
      {schedule.map((el) => (
        <div key={el} dangerouslySetInnerHTML={{ __html: t(el) }} />
      ))}
    </Box>
  );
};

export default FAQSchedule;
