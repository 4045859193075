import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import calendarIcon from "../../../assets/calendar_blue.svg";
import styless from "./styles.module.css";

import { useFormValuesContext } from "src/core/context";
import { useGetSlots } from "src/core/queries/useGetSlots";
import { useGetSlotsMonthly } from "src/core/queries/useGetSlotsMonthly";
import { getMonthString } from "src/core/helpers";
import { CalendarDialog } from "src/components/atoms/CalendarDialog";
import { Slots } from "../../atoms/Slots";
import dayjs from "dayjs";
import { UsePostPreReservation } from "src/core/queries/usePostPreReservation";
import { CheckOutStep, Tab } from "src/core/types";
import LimitModalComponent from "src/components/atoms/LimitModalComponent";
import { useDispatch, useSelector } from "react-redux";
import { selectDate } from "src/core/redux/actions/dateActions";
import { RootState } from "src/core/redux/store";
import { resetTicket } from "src/core/redux/actions/selectTicket";

interface DateAndTimeProps {
  updateCheckOutState: (newTabsState: Tab[], newPanelsState: CheckOutStep[]) => void;
  tabs: Tab[];
  step: CheckOutStep;
  panels: CheckOutStep[];
  style: React.CSSProperties;
  isActiveTab: CheckOutStep;
  setTimer: (value: boolean) => void;
  childFunc?: any;
}

export const DateAndTime: React.FC<DateAndTimeProps> = ({
  updateCheckOutState,
  tabs,
  step,
  panels,
  style,
  isActiveTab,
  setTimer,
}) => {
  const dispatch = useDispatch();
  const { formValues, setFormValues } = useFormValuesContext();
  const { t } = useTranslation();
  const currentDate = new Date();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const reservationId = useSelector((state: RootState) => state.reservationID?.reservationID);
  const isRecapActive = panels.find((el) => el.key === "4")?.isEnabled || false;
  const isFromApp = sessionStorage.getItem("isApp");
  const selectedTimeText = sessionStorage.getItem("time");
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const getFormattedDate = (value: Date) => {
    return dayjs(value).format("YYYY-MM-DD");
  };
  const selectedDateText = sessionStorage.getItem("date") || getFormattedDate(new Date());
  const selectedDate = formValues.date || getFormattedDate(new Date());

  // queries
  const { data: slotsData, refetch: refetchSlots } = useGetSlots({
    id: formValues.order_type,
    date: sessionStorage.getItem("date") || getFormattedDate(new Date()),
    pos: isFromApp ? "app-webview" : "web",
  });

  const { data: unavailableDays, refetch: refetchSlotsMonth } = useGetSlotsMonthly({
    id: formValues.order_type,
    month: getMonthString(formValues.date),
    visitors: formValues.ticketsCount,
    pos: isFromApp ? "app-webview" : "web",
  });

  // eslint-disable-next-line no-debugger
  const { mutate: mutatePreReservation, data: dataPreReservation } = UsePostPreReservation();

  //pen click function
  const penClick = () => {
    sessionStorage.removeItem("time_slot_id");
    sessionStorage.removeItem("free_capacity");
    sessionStorage.removeItem("time");
    sessionStorage.removeItem("selectedSlotId");
    sessionStorage.removeItem("lastSubmittedSlotId");
    dispatch(resetTicket());
    const newState = panels.map((panel: CheckOutStep) => {
      if (panel.key === "1") {
        return { ...panel, isEnabled: true, isStepFinished: false };
      }
      return { ...panel, isEnabled: false, isStepFinished: false, isPen: false };
    });
    const newTabsState = tabs.map((tab: Tab) => {
      if (tab.key === "1") {
        return { ...tab, isTabActive: true, isTabDone: false };
      }
      return { ...tab, isTabActive: false, isTabDone: false };
    });
    updateCheckOutState(newTabsState, newState);
  };

  const onSubmit = () => {
    const formattedDate = dayjs(formValues.date).format("YYYY-MM-DD");
    let shouldSubmit = true;
    if (formValues.time_slot_id) {
      const lastSubmittedSlotId = sessionStorage.getItem("lastSubmittedSlotId");
      if (formValues.time_slot_id.toString() === lastSubmittedSlotId) {
        shouldSubmit = false;
        sessionStorage.setItem("date", formattedDate);
      }
    }
    if (shouldSubmit && formValues.time_slot_id) {
      mutatePreReservation({
        reservation: reservationId || sessionStorage.getItem("current_reservation_id"),
        date: String(sessionStorage.getItem("date")),
        time_slot_id: formValues.time_slot_id,
        pos: isFromApp ? "app-webview" : "web",
      });
      sessionStorage.setItem("lastSubmittedSlotId", formValues.time_slot_id.toString());
    }
    setTimer(true);
    const newTabsState = tabs.map((tab: Tab) => {
      if (tab.key === "2") {
        return { ...tab, isTabActive: true };
      } else if (tab.key === "1") {
        return { ...tab, isTabDone: true, isTabActive: false };
      } else {
        return { ...tab, isTabActive: false };
      }
    });
    const newPanelsState = panels.map((panel: CheckOutStep) => {
      if (panel.key === "1") {
        return { ...panel, isEnabled: false, isPen: true, isStepFinished: true };
      }
      if (panel.key === "2") {
        return { ...panel, isEnabled: true };
      }
      return { ...panel, isEnabled: false, isPen: false };
    });
    updateCheckOutState(newTabsState, newPanelsState);
  };

  const handleCalendarClick = (value: Date) => {
    const formattedDate = getFormattedDate(value);

    if (unavailableDays && unavailableDays.includes(formattedDate)) {
      return;
    }

    setFormValues({ ...formValues, date: value });
    sessionStorage.setItem("date", formattedDate);
    dispatch(selectDate(formattedDate));
    void refetchSlots();
  };

  useEffect(() => {
    sessionStorage.setItem("date", getFormattedDate(new Date()));
    void refetchSlots();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (formValues.date && formValues.order_type) void refetchSlots();
  }, [refetchSlots, formValues.date, formValues.order_type]);

  useEffect(() => {
    if (formValues.order_type) void refetchSlotsMonth();
  }, [refetchSlots, refetchSlotsMonth, formValues.order_type]);
  return (
    <Box style={style}>
      {step.isEnabled ? (
        <Box className={styless.box}>
          <Box className={styless.mainRow}>
            <Box className={styless.calendar}>
              <CalendarDialog
                onClick={handleCalendarClick}
                selectedValue={selectedDate}
                fullSlots={unavailableDays || []}
              />
            </Box>
            <Box className={styless.slots}>
              <Slots
                onSubmit={() => onSubmit()}
                selectedDate={selectedDate || currentDate}
                onClick={handleCalendarClick}
                fullSlots={slotsData ? slotsData.slots : []}
              />
            </Box>
          </Box>
          {dataPreReservation?.data.result === "TIMESLOT_EXPIRED" && (
            <LimitModalComponent open={openModal} handleClose={handleCloseModal} />
          )}
        </Box>
      ) : (
        !isRecapActive && (
          <Box className={styless.closedBox}>
            <div className={styless.rowBox}>
              {step.isStepFinished && (
                <>
                  <img src={calendarIcon} alt="calendar" />
                  <Typography sx={{ color: "#B9B9B9", fontWeight: "600", fontSize: "14px" }}>
                    {t("date_time")} {selectedDateText.replace(/-/g, ". ")}{" "}
                    {selectedTimeText ? selectedTimeText.slice(0, 5) : ""}
                  </Typography>
                </>
              )}
            </div>
            {step.isPen && (
              <span className={styless.pen} onClick={penClick}>
                {t("to_change")}
              </span>
            )}
          </Box>
        )
      )}
    </Box>
  );
};
