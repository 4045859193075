import React from "react";
import { useNavigate } from "react-router-dom";

//components
import { Box } from "@mui/material";
import styles from "./styles.module.css";
import ArrowLeft from "src/components/icons/ArrowLeft";
import { useTranslation } from "react-i18next";

const GalleryHeader = ({ pageName }: { pageName: string }): JSX.Element => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const navigateToHome = (): void => {
    navigate("/gallery");
  };
  return (
    <Box className={styles.wrapper}>
      <Box className={styles.sub_wrapper}>
        <Box className={styles.button_back} onClick={navigateToHome}>
          <ArrowLeft color={"#fff"} />
          <span className={styles.text_back}>{t("back")}</span>
        </Box>
        <span className={styles.text}>{pageName}</span>
      </Box>
    </Box>
  );
};

export default GalleryHeader;
