import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
//components
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import MapControl from "src/components/atoms/MapControl";
import { mapGallery } from "src/core/helpers/mapGallery";
import { Image } from "src/components/atoms/MapGallery/StyledComponents";
import MapImageControls from "src/components/atoms/MapImageControls";

const MapGallery = (): JSX.Element => {
  const [currentImage, setCurrentImage] = useState(0);
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 769);
  const { type, image } = mapGallery[currentImage];

  const mobileStyles = {
    width: "100vw",
    height: "41vh",
    marginLeft: "3%",
  };

  const desktopStyles = {
    width: "1000px",
    height: "880px",
  };
  const selectFirst = (): void => {
    setCurrentImage(0);
  };
  const selectSecond = (): void => {
    setCurrentImage(1);
  };
  const selectThird = (): void => {
    setCurrentImage(2);
  };
  const updateMedia = (): void => {
    setIsMobile(window.innerWidth <= 769);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);
  return (
    <div className={styles.wrapper}>
      <div className={styles.svg_wrapper}>
        <TransformWrapper centerOnInit={true} centerZoomedOut={true}>
          <TransformComponent contentStyle={isMobile ? mobileStyles : desktopStyles}>
            <Image ObjectFit={type} src={image} alt="" />
          </TransformComponent>
          <MapImageControls currentImage={currentImage} />
        </TransformWrapper>
      </div>
      <MapControl
        selectFirst={selectFirst}
        selectSecond={selectSecond}
        selectThird={selectThird}
        currentActive={currentImage}
      />
    </div>
  );
};

export default MapGallery;
