import React from "react";

export const HomeIcon = (): JSX.Element => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="roofing_24dp_FILL0_wght300_GRAD0_opsz24 1">
        <path
          id="Vector"
          d="M9.19225 20.1768V14.5613H14.8077V20.1768H9.19225ZM10.6923 18.6768H13.3077V16.0613H10.6923V18.6768ZM2.402 13.292L1.5 12.096L12 4.17676L16.173 7.31326V5.17676H18.6152V9.16151L22.5095 12.096L21.6077 13.292L12 6.06701L2.402 13.292Z"
          fill="#1D1B20"
        />
      </g>
    </svg>
  );
};
