import { Box, Button, MenuItem, Typography } from "@mui/material";
import React from "react";
import Menu from "@mui/material/Menu";
import hamburgerIcon from "../../../assets/hamburger.svg";
import Fade from "@mui/material/Fade";
import { useTranslation } from "react-i18next";
import castle from "../../../assets/kostel_bily.svg";
import phone from "../../../assets/phone-call (3).svg";
import facebook from "../../../assets/facebook_white (2).svg";
import close from "../../../assets/cross_close.svg";
import ticketIcon from "../../../assets/tickets_white.svg";
import { useNavigate } from "react-router-dom";

const MobileMenu: React.FC<{
  VisitClick?: () => void;
  onContactClick?: () => void;
  onButtonsClick?: () => void;
}> = ({ onContactClick, onButtonsClick, VisitClick }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const navigateToButtons = (route: string): void => {
    if (onButtonsClick) {
      onButtonsClick();
      handleMenuClose();
      navigate(route);
    }
  };

  const style = {
    menuItem: {
      display: "flex",
      alignItems: "flex-end",
      marginTop: "10px",
      fontSize: "20px",
      "&:hover": {
        backgroundColor: "#E31340",
      },
    },
    icon: {
      marginLeft: "auto",
    },
    container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  };
  return (
    <div>
      <Button
        onClick={handleMenuOpen}
        sx={{
          "&:hover": {
            backgroundColor: "transparent",
          },
        }}
      >
        {open ? (
          <img alt="close" src={close} style={{ cursor: "pointer" }} />
        ) : (
          <img alt="hamburgerIcon" src={hamburgerIcon} style={{ cursor: "pointer" }} />
        )}
      </Button>
      <Box sx={style.container}>
        <Menu
          sx={{
            "& .MuiPaper-root": {
              position: "absolute",
              top: "81px !important",
              left: "50%",
              transform: "translate(6%)",
              width: "344px",
              height: "380px",
              padding: "10px 25px",
              border: "none",
              backgroundColor: "#E31340",
              borderRadius: "1px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-end",
            },
            "& .MuiMenuItem-root": {
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              textAlign: "right",
              backgroundColor: "#E31340",
              color: "#FFFFFF",
              fontSize: "20px",
              "&:hover": {
                backgroundColor: "#E31340",
                color: "#FFFFFF",
              },
              "&.Mui-selected, &.Mui-selected:hover": {
                backgroundColor: "#E31340",
                color: "#FFFFFF",
              },
            },
          }}
          id="fade-menu"
          MenuListProps={{
            "aria-labelledby": "fade-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleMenuClose}
          TransitionComponent={Fade}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
          >
            <MenuItem
              sx={style.menuItem}
              onClick={() => {
                if (VisitClick) {
                  VisitClick();
                  handleMenuClose();
                }
              }}
            >
              <Typography
                margin={"0 20px 0 0"}
                sx={{ cursor: "pointer" }}
                dangerouslySetInnerHTML={{ __html: t("free_visits_mob") }}
              />

              <img alt="castle" src={castle} style={style.icon} />
            </MenuItem>
            <MenuItem sx={style.menuItem} onClick={() => navigateToButtons("/customer-type")}>
              <Typography margin={"0 20px 0 0"} sx={{ cursor: "pointer" }}>
                {t("purchase_button")}
              </Typography>
              <img alt="ticketIcon" src={ticketIcon} style={style.icon} />
            </MenuItem>
            <MenuItem sx={style.menuItem} onClick={() => navigateToButtons("/group")}>
              <Typography margin={"0 20px 0 0"} sx={{ cursor: "pointer" }}>
                {t("travel_action")}
              </Typography>
              <img alt="ticketIcon" src={ticketIcon} style={style.icon} />
            </MenuItem>
            <MenuItem sx={style.menuItem} onClick={() => navigateToButtons("/school")}>
              <Typography margin={"0 20px 0 0"} sx={{ cursor: "pointer" }}>
                {t("schools")}
              </Typography>
              <img alt="ticketIcon" src={ticketIcon} style={style.icon} />
            </MenuItem>
            <MenuItem sx={style.menuItem} onClick={() => navigateToButtons("/travel")}>
              <Typography margin={"0 20px 0 0"} sx={{ cursor: "pointer" }}>
                {t("travel_agencies")}
              </Typography>
              <img alt="ticketIcon" src={ticketIcon} style={style.icon} />
            </MenuItem>
            <MenuItem sx={style.menuItem}>
              <Typography margin={"0 20px 0 0"} sx={{ cursor: "pointer" }}>
                +420 736 117 117
              </Typography>
              <img alt="phone" src={phone} style={style.icon} />
            </MenuItem>
          </Box>
          <Box
            sx={{
              marginTop: "40px",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <a target={"_blank"} rel={"noreferrer"} href="https://www.facebook.com/visitjakubbrno">
              <img alt="facebook" src={facebook} style={{ marginRight: "20px" }} />
            </a>
          </Box>
        </Menu>
      </Box>
    </div>
  );
};
export default MobileMenu;
