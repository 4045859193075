import React, { useEffect, useRef, useState } from "react";

//components
import styles from "./styles.module.css";
import SubPageHeader from "src/components/atoms/SubPageHeader";
import { Box } from "@mui/material";
import { history } from "src/core/helpers/history";
import DefaultGalleryComponent from "src/components/atoms/DefaultGalleryComponent";
import InformationBlock from "src/components/atoms/InformationBlock";
import ArrowDown from "src/components/icons/ArrowDown";
import Footer from "src/components/molecules/Footer";
import PageHistoryDesktop from "src/components/pages/PageHistoryDesktop";
import { useTranslation } from "react-i18next";

const PageHistory = (): JSX.Element => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const topRef = useRef<HTMLDivElement | null>(null);
  const reversedHistory = [...history].reverse();
  const { t } = useTranslation();
  const updateMedia = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    if (topRef && topRef.current) {
      window.scrollTo({
        top: isMobile ? topRef?.current.offsetTop : topRef?.current.offsetTop - 130,
        behavior: "smooth",
      });
    }
  }, [isMobile, topRef]);

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);
  return isMobile ? (
    <Box className={styles.wrapper} ref={topRef}>
      <SubPageHeader pageName={t("main_page_history")} />
      <Box className={styles.content}>
        {reversedHistory.map((el, index) => {
          if (el.id === "img") {
            return (
              <>
                <DefaultGalleryComponent
                  key={el.header}
                  basicPhoto={el.content}
                  height={el.height && el.height}
                />
                {index + 1 < history.length && reversedHistory[index + 1].id !== "img" && (
                  <ArrowDown />
                )}
              </>
            );
          }
          return (
            <>
              <InformationBlock key={el.header} header={el.header} content={t(el.content)} />
              {index + 1 < history.length && reversedHistory[index + 1].id !== "img" && (
                <ArrowDown />
              )}
            </>
          );
        })}
      </Box>
      <Footer />
    </Box>
  ) : (
    <div ref={topRef}>
      <PageHistoryDesktop />
    </div>
  );
};

export default PageHistory;
