import React from "react";

const ArrowSend = (): JSX.Element => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginTop: "5px" }}
    >
      <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" stroke="#E31340" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 11.3333L17.06 12.2733L20.1134 15.3333H9.33337V16.6667H20.1134L17.0534 19.7267L18 20.6667L22.6667 16L18 11.3333Z"
        fill="#E31340"
      />
    </svg>
  );
};

export default ArrowSend;
