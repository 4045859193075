export const HOMEPAGE_ROUTE = "/";

export const PRE_RESERVATION_ROUTE = "/pre-reservation";
export const TYPE_OF_CUSTOMERS = "/customer-type";
export const PAYMENT_STATUS_ROUTE = "status";
export const SCHOOL_ROUTE = "/school";
export const TRAVEL_ROUTE = "/travel";
export const GROUP_ROUTE = "/group";
export const HISTORY_ROUTE = "/history";
export const GALLERY_ROUTE = "/gallery";
export const PERSONS_ROUTE = "/persons";
export const FAQ_ROUTE = "/faq";
export const RECONSTRUCTION_ROUTE = "/reconstruction";

export const EXPOSITION_ROUTE = "/exposition";

export const MAP_ROUTE = "/map";

export const OPEN_GALLERY = "/showroom";

export const PRIVACY_POLICY = "/privacy-policy";

export const PAGE_REFUND = "/refund";

export const PAGE_REFUND_TICKET = "/refund/ticket-refund";

export const PAGE_REFUND_OTHER = "/refund/other";
