import { useMutation } from "react-query";
import axios from "axios";
import { RESERVATION_API_ROUTE } from "../endpoints";
import { Reservation, ReservatonResponse } from "../types";
import { clearFormValues, useFormValuesContext } from "../context";

export const usePostReservation = () => {
  const { formValues, setFormValues } = useFormValuesContext();
  return useMutation(
    (submitData: Reservation) => {
      return axios.post(`${window.BASE_API_URL}/${RESERVATION_API_ROUTE}`, submitData);
    },
    {
      onSuccess: (response) => {
        const payUrl = response.data.pay_url;
        if (payUrl) {
          window.location.href = payUrl;
        }
      },
      onError: (error: { response: unknown }) => {
        setFormValues(clearFormValues);
        // sessionStorage.clear();
      },
    },
  );
};
