import React from "react";
import styles from "./styles.module.css";
import logo from "../../../assets/logo_jakub_pos.svg";
import { useTranslation } from "react-i18next";

const RecapPaymentTravelMobile = ({
  nameSurname,
  email,
  changeClick,
}: {
  nameSurname: string;
  email: string;
  changeClick: () => void;
}): JSX.Element => {
  const { t } = useTranslation();
  const finalPrice = sessionStorage.getItem("totalPrice");
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <img src={logo} className={styles.icon} alt="logo" />
      </div>
      <div className={styles.wrapper_part_one}>
        <div className={styles.personal_data}>
          <div className={styles.personal_data_desc}>{t("contact_information")}</div>
          <div className={styles.hoziontal_line} />
          <div className={styles.red_button_change} onClick={changeClick}>
            {t("to_change")}
          </div>
        </div>
        <div className={styles.personal_data_props_wrapper}>
          <div className={styles.personal_data_props_header}>{t("name_surname")}</div>
          <div className={styles.personal_data_props_content}>{nameSurname}</div>
        </div>
        <div className={styles.personal_data_props_wrapper}>
          <div className={styles.personal_data_props_header}>{t("email_address")}</div>
          <div className={styles.personal_data_props_content}>{email}</div>
        </div>
      </div>
      <div className={styles.horizontal_line_end} />
      <div className={styles.wrapper_price}>
        <div className={styles.sub_wrapper_price}>
          <div className={styles.price_description}>{t("fullPrice")}</div>
          <div className={styles.price}>
            {finalPrice}
            {t("cz_currency")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecapPaymentTravelMobile;
