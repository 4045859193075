import styled from "styled-components";

export const ActiveExposition = styled.img`
  position: absolute;
  width: 1136px;
  height: 685px;
  border-radius: 10px;
  z-index: 5;
`;

export const PassiveExpositionLeft = styled.img`
  position: absolute;
  top: 18%;
  left: 1%;
  width: 1053px;
  height: 635px;
  border-radius: 10px;
  flex-shrink: 0;
`;

export const PassiveExpositionRight = styled.img`
  position: absolute;
  top: 18%;
  left: 35%;
  width: 1053px;
  height: 635px;
  border-radius: 10px;
  flex-shrink: 0;
`;
