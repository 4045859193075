export const styles = {
  wrapper: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "300px",
    maxHeight: "300px",
    bgcolor: "background.paper",
    border: "1px solid #000",
    color: "#000",
    padding: "40px",

    h2: {
      marginBottom: "31px",
      "& > p": {
        margin: 0,
      },
    },

    "p:last-of-type": {
      marginBottom: 0,
    },
  },
  cancelButton: {
    backgroundColor: "transparent",
    padding: "27.5px",
    position: "absolute",
    top: 0,
    right: 0,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
};
