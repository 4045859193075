import React, { useEffect } from "react";
import styles from "./styles.module.css";
import { useControls } from "react-zoom-pan-pinch";

const MapImageControls = ({ currentImage }: { currentImage: number }): JSX.Element => {
  const { zoomIn, zoomOut, resetTransform } = useControls();

  useEffect(() => {
    resetTransform();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentImage]);
  return (
    <div className={styles.wrapper}>
      <button className={styles.button} onClick={() => zoomOut()}>
        -
      </button>
      <button className={styles.button} onClick={() => zoomIn()}>
        +
      </button>
    </div>
  );
};

export default MapImageControls;
