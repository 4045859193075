import { Box, Button } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.css";
import linePoint from "../../../assets/list_item_red.svg";
import iconButton from "../../../assets/formIcon.svg";
import groupIcon from "../../../assets/groupIconRed.svg";

export const TIcketConditionsGroup: React.FC<{
  formClick?: () => void;
}> = ({ formClick }) => {
  const style = {
    buttonToBuy: {
      minWidth: "200px",
      minHeight: "50px",
      width: "220px",
      height: "50px",
      padding: "5px",
      flexShrink: 0,
      backgroundColor: "#E31340",
      color: "white",
      display: "flex",
      justifyContent: "center",
      borderRadius: "25px",
    },
  };
  const { t } = useTranslation();
  return (
    <Box className={styles.container}>
      <Box className={styles.mainBox}>
        <Box className={styles.titleBox}>
          <img alt="img" src={groupIcon} style={{ margin: "0 20px 0 0" }} />
          <span className={styles.titleString}>{t("group_title")}</span>
        </Box>
        <Box className={styles.subtitleBox}>
          <span
            className={styles.subtitleText1}
            dangerouslySetInnerHTML={{ __html: t("group_title_text") }}
          />
        </Box>
        <Box className={styles.noticeBox}>
          <div className={styles.line}></div>
          <Box className={styles.noticeWrapper}>
            <Box className={styles.notice}>
              <img alt="img" src={linePoint} style={{ margin: "0 1.5vw 0 0" }} />
              <span
                className={styles.text}
                dangerouslySetInnerHTML={{
                  __html: t("group_condition_one", { phone_number: t("phone_number_group") }),
                }}
              />
            </Box>
            <Box className={styles.notice}>
              <img alt="img" src={linePoint} style={{ margin: "0 1.5vw 0 0" }} />
              <span
                className={styles.text}
                dangerouslySetInnerHTML={{
                  __html: t("group_condition_two"),
                }}
              />
            </Box>
            <Box className={styles.notice}>
              <img alt="img" src={linePoint} style={{ margin: "0 1.5vw 0 0" }} />
              <span
                className={styles.text}
                dangerouslySetInnerHTML={{
                  __html: t("group_condition_three"),
                }}
              />
            </Box>
          </Box>
          <div className={styles.line}></div>
        </Box>

        <Box className={styles.buttonBox}>
          <Button
            sx={{
              ...style.buttonToBuy,
              "&:hover": {
                backgroundColor: "#E31340",
                color: "white",
              },
            }}
            style={{ textDecoration: "none" }}
            onClick={formClick}
          >
            {t("group_form")} <img alt="img" src={iconButton} style={{ marginLeft: "20px" }} />
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
