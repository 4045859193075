import { Box } from "@mui/material";
import style from "./styles.module.css";
import { useTranslation } from "react-i18next";
import arrow from "../../../assets/arrow_right.svg";
import React, { SetStateAction, useEffect, useState } from "react";

export interface SimpleProps {
  withIcons?: boolean;
  onClick: React.Dispatch<SetStateAction<string>>;
  subTitle?: string;
  isMutated?: boolean;
  title: string;
  img1?: any;
  img2?: any;
  img3?: any;
  img4?: any;
  img5?: any;
  img6?: any;
}

export const ConditionBoxGreyMobile = ({
  onClick,
  title,
  subTitle,
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  isMutated,
  withIcons,
}: SimpleProps) => {
  const { t } = useTranslation();

  return (
    <Box
      className={`${
        withIcons ? (isMutated ? style.wrapper_mutated : style.wrapper) : style.boxWithoutIcons
      }`}
      style={{ cursor: "pointer" }}
      onClick={() => onClick("visible")}
    >
      <Box className={style.content}>
        <Box className={style.innerRow}>
          <span className={style.title}>{t(title)}</span>
          {subTitle && <span className={style.subTitle}>{t(subTitle)}</span>}
          <Box className={style.imgBox}>
            {img1 && <img src={img1} className={style.img} alt="img1" />}
            {img2 && <img src={img2} style={{ height: "32px" }} className={style.img} alt="img2" />}
            {img3 && <img src={img3} className={style.img} alt="img3" />}
            {img5 && <img src={img5} className={style.img} alt="img5" />}
            {img4 && <img src={img4} style={{ height: "32px" }} className={style.img} alt="img4" />}
          </Box>
        </Box>
        <img src={arrow} style={{ cursor: "pointer" }} />
      </Box>
    </Box>
  );
};
