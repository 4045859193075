import React from "react";

export const SuccessIcon = (): JSX.Element => {
  return (
    <svg
      id="checked"
      xmlns="http://www.w3.org/2000/svg"
      width="156.474"
      height="156.474"
      viewBox="0 0 156.474 156.474"
    >
      <g id="Group_19" data-name="Group 19" transform="translate(37.153 19.659)">
        <g id="Group_18" data-name="Group 18">
          <path
            id="Path_10"
            data-name="Path 10"
            d="M236.415,66.059a5.9,5.9,0,0,0-8.339-.01L157.329,136.61,131.8,108.887a5.9,5.9,0,1,0-8.677,7.989l29.683,32.236a5.89,5.89,0,0,0,4.213,1.9c.043,0,.085,0,.126,0a5.9,5.9,0,0,0,4.163-1.72L236.4,74.4A5.9,5.9,0,0,0,236.415,66.059Z"
            transform="translate(-121.568 -64.327)"
            fill="#e31340"
          />
        </g>
      </g>
      <g id="Group_21" data-name="Group 21">
        <g id="Group_20" data-name="Group 20">
          <path
            id="Path_11"
            data-name="Path 11"
            d="M150.576,72.34a5.9,5.9,0,0,0-5.9,5.9A66.442,66.442,0,1,1,78.237,11.795,5.9,5.9,0,1,0,78.237,0a78.237,78.237,0,1,0,78.237,78.237A5.9,5.9,0,0,0,150.576,72.34Z"
            fill="#e31340"
          />
        </g>
      </g>
    </svg>
  );
};

export default SuccessIcon;
