import React from "react";

//components
import styles from "./styles.module.css";
import { Box } from "@mui/material";

const FullPageImage = ({ photo }: { photo: string }): JSX.Element => {
  return (
    <Box className={styles.wrapper}>
      <img className={styles.image} src={photo} alt={`${photo}`} />
    </Box>
  );
};

export default FullPageImage;
