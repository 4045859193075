import { Slot } from "core/types";

export const selectTicket = (data: Slot) => {
  console.log("SELECTED");
  return {
    type: "SET_SELECTED_TICKET",
    payload: data,
  };
};

export const resetTicket = () => {
  return {
    type: "RESET_SELECTED_TICKET",
  };
};
