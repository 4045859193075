import React from "react";

export const ArrowMoreLonger = (): JSX.Element => {
  return (
    <svg width="50" height="21" viewBox="0 0 50 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2547_9654)">
        <path
          d="M46.1692 11.3711H1C0.716244 11.3711 0.478644 11.2754 0.2872 11.084C0.0957333 10.8925 0 10.6549 0 10.3712C0 10.0874 0.0957333 9.84981 0.2872 9.65837C0.478644 9.46692 0.716244 9.3712 1 9.3712H46.1692L39.2769 2.47887C39.0786 2.28058 38.9808 2.04853 38.9833 1.78273C38.9859 1.51691 39.0906 1.28059 39.2974 1.07377C39.5043 0.880611 39.7384 0.780611 40 0.773766C40.2615 0.766922 40.4957 0.866922 40.7025 1.07377L49.1563 9.5276C49.2811 9.65238 49.3691 9.784 49.4204 9.92247C49.4717 10.0609 49.4974 10.2105 49.4974 10.3712C49.4974 10.5319 49.4717 10.6814 49.4204 10.8199C49.3691 10.9583 49.2811 11.09 49.1563 11.2147L40.7025 19.6686C40.5179 19.8532 40.2893 19.9476 40.0166 19.9519C39.744 19.9562 39.5043 19.8617 39.2974 19.6686C39.0906 19.4617 38.9872 19.2241 38.9872 18.9558C38.9872 18.6874 39.0906 18.4498 39.2974 18.243L46.1692 11.3711Z"
          fill="#E31240"
        />
      </g>
      <defs>
        <clipPath id="clip0_2547_9654">
          <rect width="50" height="20" fill="white" transform="translate(0 0.185547)" />
        </clipPath>
      </defs>
    </svg>
  );
};
