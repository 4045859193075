import React, { SetStateAction, useEffect, useState } from "react";
import {
  ButtonCount,
  ButtonDecrement,
  ButtonIncrement,
  Buttons,
  DescriptionMessage,
  ErrorMessage,
  ErrorWrapper,
  Price,
  SubTitle,
  SubTitleBox,
  SubTitleTwo,
  Wrapper,
  WrapperWithError,
} from "src/components/atoms/AmountPeopleComponent/styledComponents";
import minus from "../../../assets/minus_gray.svg";
import activeMinus from "../../../assets/minus-red.svg";
import unActivePlus from "../../../assets/plus_color.svg";
import plus from "../../../assets/unActivePlus.svg";
import { useTranslation } from "react-i18next";

interface IAmountPeopleComponent {
  title: string;
  subtitle: string;
  price: number;
  count: number;
  increment: any;
  isButtonsAvailable: boolean;
  setIsButtonsAvailable: React.Dispatch<SetStateAction<boolean>>;
  decrement: any;
  isFetching: boolean;
  isLoadingCapacity: boolean;
  isMutating: boolean;
  status: string;
  isError: boolean | null;
  errorMessage: string | null;
  isPermanentMessage?: boolean;
}

const AmountPeopleComponent = ({
  title,
  subtitle,
  price,
  count,
  increment,
  decrement,
  isFetching,
  isButtonsAvailable,
  setIsButtonsAvailable,
  isMutating,
  status,
  isLoadingCapacity,
  isError,
  errorMessage,
  isPermanentMessage,
}: IAmountPeopleComponent): JSX.Element => {
  const { t } = useTranslation();
  const loc = sessionStorage.getItem("loc");

  const [isButtonIncrement, setIsButtonIncrementActive] = useState<boolean>(true);
  const [isButtonDecrement, setIsButtonDecrementActive] = useState<boolean>(true);

  const handleLocalIncrement = () => {
    if (
      !isButtonIncrement ||
      !isButtonsAvailable ||
      isFetching ||
      isMutating ||
      isLoadingCapacity ||
      status !== "success"
    )
      return;
    increment();
    setIsButtonIncrementActive(false);
    setIsButtonsAvailable(false);
  };

  const handleLocalDecrement = () => {
    if (
      !isButtonDecrement ||
      !isButtonsAvailable ||
      isFetching ||
      isMutating ||
      isLoadingCapacity ||
      status !== "success"
    )
      return;
    decrement();
    setIsButtonDecrementActive(false);
    setIsButtonsAvailable(false);
  };

  useEffect(() => {
    if (!isButtonIncrement) {
      setTimeout(() => {
        setIsButtonIncrementActive(true);
      }, 1000);
    }
  }, [isButtonIncrement]);

  useEffect(() => {
    if (!isButtonDecrement) {
      setTimeout(() => {
        setIsButtonDecrementActive(true);
      }, 1000);
    }
  }, [isButtonDecrement]);

  return (
    <WrapperWithError>
      <Wrapper error={isError || false}>
        <SubTitleBox isDE={loc === "de"}>
          <SubTitle isDE={loc === "de"}>{t(title)}</SubTitle>
          <SubTitleTwo>{t(subtitle)}</SubTitleTwo>
        </SubTitleBox>
        <Price isDE={loc === "de"}>{`${price} ${t("cz_currency")}`}</Price>
        <Buttons>
          <ButtonDecrement
            onClick={handleLocalDecrement}
            isActive={isButtonDecrement && isButtonsAvailable && count > 0}
          >
            <img
              src={count > 0 && isButtonDecrement && isButtonsAvailable ? activeMinus : minus}
              alt="minus"
            />
          </ButtonDecrement>
          <ButtonCount>{count}</ButtonCount>
          <ButtonIncrement
            onClick={handleLocalIncrement}
            isActive={isButtonIncrement && isButtonsAvailable}
          >
            <img src={isButtonIncrement && isButtonsAvailable ? plus : unActivePlus} alt="plus" />
          </ButtonIncrement>
        </Buttons>
      </Wrapper>
      <ErrorWrapper>
        {isPermanentMessage && !isError && (
          <DescriptionMessage>{errorMessage && errorMessage}</DescriptionMessage>
        )}
        {isError && <ErrorMessage>{errorMessage && errorMessage}</ErrorMessage>}
      </ErrorWrapper>
    </WrapperWithError>
  );
};

export default AmountPeopleComponent;
