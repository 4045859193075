import { useMutation } from "react-query";
import axios from "axios";
import { ASK_QUESTION } from "../endpoints";

interface IQuestion {
  sender: string;
  body: string;
}

export const useAskQuestion = () => {
  return useMutation((mutateData: IQuestion) => {
    return axios.post(`${window.BASE_API_URL}/${ASK_QUESTION}`, mutateData);
  });
};
