export const numberedContentIone = "data_security_one";
export const numberedContentIThree = "data_secutity_one_second";
export const numberedContentIIone = "data_security_two_two";

export const numberedContentIItwo = "data_security_two_three";

export const numberedContentIIIone = "data_security_three_list_head";

export const numberedContentIIItwo = "data_security_three_list_head_two";
export const rulesListIIIone: string[] = [
  "data_security_three_list_one",
  "data_security_three_list_two",
  "data_security_three_list_three",
];

export const rulesListIIItwo: string[] = [
  "data_security_three_list_two_one",
  "data_security_three_list_two_two",
];

export const numberedContentIIIthree = "data_security_three_list_head_three";

export const numberedContentIVone = "data_security_four_list_head";

export const rulesListIVone: string[] = [
  "data_security_four_list_one_one",
  "data_security_four_list_one_two",
];

export const numberedContentIVtwo = "data_security_four_two";

export const numberedContentVone = "data_security_five_list_head";

export const rulesListVone: string[] = [
  "data_security_five_list_one_one",
  "data_security_five_list_one_two",
  "data_security_five_list_one_three",
];

export const numberedContentVtwo = "data_security_five_two";

export const numberedContentVIone = "data_security_six_list_head";

export const rulesListVIone: string[] = [
  "data_security_six_list_one_one",
  "data_security_six_list_one_two",
  "data_security_six_list_one_three",
  "data_security_six_list_one_four",
  "data_security_six_list_one_five",
  "data_security_six_list_one_six",
];

export const numberedContentVItwo = "data_security_six_two";

export const numberedContentVIIone = "data_security_seven_one";

export const numberedContentVIItwo = "data_security_seven_two";

export const numberedContentVIIthree = "data_security_seven_three";

export const numberedContentVIIIone = "data_security_eight_one";

export const numberedContentVIIItwo = "data_security_eight_two";

export const numberedContentVIIIthree = "data_security_eight_three";
