import { Box, Checkbox, FormControlLabel } from "@mui/material";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styless from "./styles.module.css";
import ModalCondition from "src/components/atoms/ModalCondition";
import SecurityData from "src/components/atoms/SecurityData";

export const GroupCheckBox: React.FC<{
  onConsentChange: (consent: boolean) => void;
  isConsent?: boolean;
}> = ({ onConsentChange, isConsent }) => {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState<boolean>(false);

  const agreementForm = useForm({
    defaultValues: {
      consent: false,
    },
    reValidateMode: "onChange",
    mode: "onSubmit",
  });
  const { control } = agreementForm;

  const onCheckboxChange = () => {
    const consentChecked = agreementForm.getValues("consent");

    onConsentChange(consentChecked);
  };

  const handleCheckboxChange =
    (value: "consent") => (event: React.ChangeEvent<HTMLInputElement>) => {
      agreementForm.setValue(value, event.target.checked);
      onCheckboxChange();
    };

  return (
    <Box style={{ display: "flex", alignItems: "start" }}>
      <form>
        <Box className={styless.rowAgreement}>
          <Controller
            name="consent"
            control={control}
            rules={{
              required: t("must_agree_to_terms_conditions"),
              validate: (v) => v === true || t("must_agree_to_terms_conditions"),
            }}
            render={() => (
              <FormControlLabel
                label={
                  <div style={{ display: "flex" }} className={styless.textBlock}>
                    <span className={styless.text}>
                      {`${t("agree_with")} ${t("with_se")}`}
                      <span
                        className={styless.underlineText}
                        onClick={() => setOpenModal(true)}
                        dangerouslySetInnerHTML={{ __html: t("agreement_with_personal_data") }}
                      />
                    </span>
                  </div>
                }
                control={
                  <Checkbox checked={isConsent} onChange={handleCheckboxChange("consent")} />
                }
              />
            )}
          />
        </Box>
      </form>
      <ModalCondition open={openModal} handleClose={() => setOpenModal(false)}>
        {({ handleClose }) => <SecurityData handleClose={handleClose} isModal={true} />}
      </ModalCondition>
    </Box>
  );
};
