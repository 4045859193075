import React from "react";
import FAQSchedule from "src/components/atoms/FAQSchedule";

interface IFAQ {
  id: string;
  header: string;
  content: string | React.ReactNode;
}

export interface IFAQTABLE {
  month: string;
  from: number | string;
  to: number | string;
}

export const FAQ_TABLE: IFAQTABLE[] = [
  {
    month: "Otevírací doba",
    from: "Od",
    to: "Do",
  },
  {
    month: "Leden-duben",
    from: "10:00",
    to: "18:00",
  },
  {
    month: "Květen",
    from: "10:00",
    to: "20:00",
  },
  {
    month: "Červen - září",
    from: "10:00",
    to: "22:00",
  },
  {
    month: "Říjen",
    from: "10:00",
    to: "21:00",
  },
  {
    month: "Listopad",
    from: "10:00",
    to: "20:00",
  },
  {
    month: "Prosinec",
    from: "10:00",
    to: "21:00",
  },
];
export const FAQ: IFAQ[] = [
  {
    id: "text",
    header: "faq_header_one",
    content: "faq_answer_one",
  },
  {
    id: "text",
    header: "faq_header_two",
    content: "faq_answer_two",
  },
  {
    id: "text",
    header: "faq_header_three",
    content: "faq_answer_three",
  },
  {
    id: "text",
    header: "faq_header_four",
    content: "faq_answer_four",
  },
  {
    id: "text",
    header: "faq_header_five",
    content: "faq_answer_five",
  },
  {
    id: "text",
    header: "faq_header_six",
    content: "faq_answer_six",
  },
  {
    id: "text",
    header: "faq_header_seven",
    content: "faq_answer_seven",
  },
  {
    id: "text",
    header: "faq_header_eight",
    content: "faq_answer_eight",
  },
  {
    id: "text",
    header: "faq_header_nine",
    content: <FAQSchedule />,
  },
  {
    id: "table",
    header: "faq_header_ten",
    content: "faq_answer_ten",
  },
  {
    id: "text",
    header: "faq_header_elev",
    content: "faq_answer_elev",
  },
  {
    id: "text",
    header: "faq_header_twelve",
    content: "faq_answer_twelve",
  },
  {
    id: "text",
    header: "faq_header_thirt",
    content: "faq_answer_thirt",
  },
  {
    id: "text",
    header: "faq_header_fourth",
    content: "faq_answer_fourth",
  },
  {
    id: "text",
    header: "faq_header_fiveth",
    content: "faq_answer_fiveth",
  },
];
