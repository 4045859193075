import React, { useEffect, useRef, useState } from "react";

//images
import reconstructionPhoto from "../../../assets/Jakub_rekonstrukce-425.jpg";
import person from "../../../assets/owner.png";

//components
import styles from "./styles.module.css";
import { Box } from "@mui/material";
import HorizontalGallery from "src/components/atoms/HorizontalGallery";
import { horizontalGallery } from "src/core/helpers/horizontalGallery";
import SubPageHeader from "src/components/atoms/SubPageHeader";
import Footer from "src/components/molecules/Footer";
import MoneyCounterReconstrution from "src/components/atoms/MoneyCountReconstructionPage";
import ReconstructionBlock from "src/components/atoms/ReconstructionBlock";
import { HomeIcon } from "src/components/icons/HomeIcon";
import { GraphicIcon } from "src/components/icons/GraphicIcon";
import { AskIcon } from "src/components/icons/AskIcon";
import { GiftHandIcon } from "src/components/icons/GiftHandIcon";
import DonationBlock from "src/components/atoms/DonationBlock";
import { useTranslation } from "react-i18next";

const PageDonate = (): JSX.Element => {
  const topRef = useRef<any>(null);
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768);
  const { t } = useTranslation();
  const updateMedia = () => {
    setIsMobile(window.innerWidth <= 768);
  };
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);

  useEffect(() => {
    if (topRef) {
      window.scrollTo({ top: topRef.current.offsetTop - 150, behavior: "smooth" });
    }
  }, [topRef]);
  return (
    <>
      <Box className={styles.wrapper} ref={topRef}>
        <SubPageHeader pageName={t("main_page_restoration")} />
        <img className={styles.image} src={reconstructionPhoto} />
        {isMobile ? (
          <>
            <span className={styles.header}>{t("main_page_restoration")}</span>
            <MoneyCounterReconstrution />
            <HorizontalGallery photos={horizontalGallery} />
            <span className={styles.sub_header}>{t("funriser")}</span>
            <img src={person} className={styles.person} alt="" />
            <span
              className={styles.description}
              dangerouslySetInnerHTML={{ __html: t("donation_people") }}
            />
          </>
        ) : (
          <DonationBlock />
        )}
        <div className={styles.info_wrapper}>
          <ReconstructionBlock>
            <div className={styles.rec_header}>
              <HomeIcon />
              <span className={styles.block_header}>{t("new_truss")}</span>
            </div>
            <span
              className={styles.sub_description}
              dangerouslySetInnerHTML={{ __html: t("donation_block_one") }}
            />
            <span
              className={styles.block_bold}
              dangerouslySetInnerHTML={{ __html: t("donation_block_one_bottom") }}
            />
          </ReconstructionBlock>
          <ReconstructionBlock>
            <div className={styles.rec_header}>
              <GraphicIcon />
              <span className={styles.block_header}>{t("donation_block_problem")}</span>
            </div>
            <span className={styles.sub_description}>
              {t("donation_block_two")}
              <br />
              <br />
              <span className={styles.block_bold}>{t("donation_block_two_bottom")}</span>
            </span>
          </ReconstructionBlock>
          <ReconstructionBlock>
            <div className={styles.rec_header}>
              <AskIcon />
              <span className={styles.block_header}>{t("debt")}</span>
            </div>
            <span
              className={styles.description}
              dangerouslySetInnerHTML={{ __html: t("donation_block_three") }}
            />
            <span className={styles.block_bold}>{t("donation_block_three_one")}</span>
            <br />
            <span className={styles.block_bold}>{t("donation_block_three_two")}</span>
          </ReconstructionBlock>
          <ReconstructionBlock>
            <div className={styles.rec_header}>
              <GiftHandIcon />
              <span className={styles.block_header}>{t("donation_thanks")}</span>
            </div>
            <span
              className={styles.sub_description}
              dangerouslySetInnerHTML={{ __html: t("donation_block_four") }}
            />
          </ReconstructionBlock>
        </div>
      </Box>
      <Footer />
    </>
  );
};

export default PageDonate;
