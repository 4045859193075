export const selectForm = (data: any) => {
  console.log("data");
  console.log(data);
  console.log("data");
  return {
    type: "SET_USER_FORM",
    payload: data,
  };
};

export const setTruePromo = () => {
  return {
    type: "SET_PROMO",
    payload: true,
  };
};

export const setFalsePromo = () => {
  return {
    type: "SET_PROMO",
    payload: false,
  };
};

export const setDiscount = (discount: string) => {
  return {
    type: "SET_DISCOUNT",
    payload: discount,
  };
};
