import React from "react";

export const TShirtIcon = (): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
      <path
        d="M6.50003 10.9864L5.10578 11.7576C4.89168 11.8781 4.67053 11.9098 4.44233 11.8528C4.21413 11.7957 4.03977 11.6601 3.91926 11.446L2.30391 8.61917C2.18339 8.40507 2.15166 8.18232 2.20871 7.95092C2.26576 7.71951 2.40133 7.54355 2.61543 7.42305L7.86541 4.37305H9.34616C9.51539 4.37305 9.65065 4.42369 9.75193 4.52497C9.85322 4.62626 9.90386 4.76151 9.90386 4.93075V5.2769C9.90386 5.84613 10.1109 6.3378 10.525 6.7519C10.9391 7.166 11.4308 7.37305 12 7.37305C12.5692 7.37305 13.0609 7.166 13.475 6.7519C13.8891 6.3378 14.0962 5.84613 14.0962 5.2769V4.93075C14.0962 4.76151 14.1468 4.62626 14.2481 4.52497C14.3494 4.42369 14.4846 4.37305 14.6539 4.37305H16.1346L21.3846 7.42305C21.5987 7.54355 21.7343 7.71951 21.7913 7.95092C21.8484 8.18232 21.8166 8.40507 21.6961 8.61917L20.0808 11.446C19.9602 11.6601 19.7885 11.7932 19.5654 11.8451C19.3423 11.897 19.1186 11.8627 18.8942 11.7422L17.5 11.0018V20.4884C17.5 20.7397 17.4154 20.9499 17.2461 21.1191C17.0769 21.2884 16.8666 21.373 16.6154 21.373H7.38466C7.13337 21.373 6.92312 21.2884 6.75388 21.1191C6.58465 20.9499 6.50003 20.7397 6.50003 20.4884V10.9864ZM8.00001 8.47302V19.873H16V8.47302L19.0808 10.1634L20.1115 8.42302L15.8212 5.9038H15.5038C15.35 6.74738 14.9513 7.45315 14.3077 8.0211C13.6641 8.58905 12.8949 8.87302 12 8.87302C11.1051 8.87302 10.3359 8.58905 9.69233 8.0211C9.04875 7.45315 8.65003 6.74738 8.49618 5.9038H8.17886L3.88848 8.42302L4.91923 10.1634L8.00001 8.47302Z"
        fill="#E31240"
      />
    </svg>
  );
};
