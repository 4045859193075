import React from "react";
import styles from "./styles.module.css";
import TransportNumber from "src/components/atoms/TransportNumber";

const Transport = ({
  header,
  icon,
  tName,
  numbers,
}: {
  header: string;
  icon: React.ReactNode;
  tName: string;
  numbers: number[];
}): JSX.Element => {
  return (
    <div>
      <span className={styles.schedule_text}>{header}</span>
      <div className={styles.content}>
        <div className={styles.transport_wrapper}>{icon}</div>
        <div className={styles.transport_wrapper}>{tName}</div>
        <div className={styles.numbers}>
          {numbers.map((number) => (
            <TransportNumber key={number} number={number} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Transport;
