import { Box, Button } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import style from "./styles.module.css";
import individual from "../../../assets/osoba_white.svg";
import caret from "../../../assets/caret.svg";
import caretHovered from "../../../assets/caretHovered.svg";
import { useNavigate } from "react-router-dom";
import { GROUP_ROUTE, PRE_RESERVATION_ROUTE, SCHOOL_ROUTE, TRAVEL_ROUTE } from "src/core/routing";
import { clearFormValues, useFormValuesContext } from "src/core/context";
import schoolIcon from "../../../assets/sova (1).svg";
import travelIcon from "../../../assets/travel.svg";
import groupIcon from "../../../assets/groupButton.svg";
import { useDispatch, useSelector } from "react-redux";
import { resetTicket } from "src/core/redux/actions/selectTicket";
import { RootState } from "src/core/redux/store";

const PurchasingTickets = React.forwardRef((_props, ref) => {
  const [isHovered1, setIsHovered1] = useState<boolean>(false);
  const [isHovered2, setIsHovered2] = useState<boolean>(false);
  const [isHovered3, setIsHovered3] = useState<boolean>(false);
  const [isHovered4, setIsHovered4] = useState<boolean>(false);

  const dipatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isApp = useSelector((state: RootState) => state.isApp?.isApp);
  const { setFormValues } = useFormValuesContext();
  const currentLoc = sessionStorage.getItem("loc") || "cs";

  const navigateToSchool = () => {
    navigate(SCHOOL_ROUTE);
  };

  const navigateToPrereservation = () => {
    navigate(PRE_RESERVATION_ROUTE);
  };

  const navigateToTravel = () => {
    navigate(TRAVEL_ROUTE);
  };

  const navigateToGroup = () => {
    navigate(GROUP_ROUTE);
  };

  const styles = {
    button: {
      display: "flex",
      justifyContent: "start",
      alignItems: "center",
      background: "#062762",
      borderRadius: "5px",
      cursor: "pointer",
    },
  };
  return (
    <Box className={style.container} ref={ref}>
      <Button
        sx={{
          ...styles.button,
          textDecoration: "none !important",
          transition: "all 0.05s ease",
          "&:hover": {
            backgroundColor: "#2F4D82",
            color: "#FFFFFF !important",
            textDecoration: "none !important",
          },
        }}
        className={style.button}
        onClick={() => {
          sessionStorage.clear();
          sessionStorage.setItem("loc", currentLoc);
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          isApp ? sessionStorage.setItem("isApp", isApp) : null;
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          isApp ? localStorage.setItem("isApp", isApp) : null;
          dipatch(resetTicket());
          setFormValues({
            ...clearFormValues,
            order_type: "single",
          });
          navigateToPrereservation();
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
        }}
        onMouseEnter={() => setIsHovered1(true)}
        onMouseLeave={() => setIsHovered1(false)}
      >
        <img alt="button_icon" src={individual} className={style.iconIndividual} />
        <Box className={style.buttonContent}>
          <span className={style.buttonText}>{t("individual_title1")}</span>
          <span className={style.buttonSubtext}>{t("individual_subtitle")}</span>
        </Box>
        <img
          alt="button_icon"
          className={style.caret}
          src={isHovered1 ? caretHovered : caret}
          style={{
            position: "absolute",
            top: "50%",
            left: "100%",
            transform: "translate(-50%, -50%)",
            transition: "all 0.05s ease",
          }}
        />
      </Button>
      <Button
        sx={{
          ...styles.button,
          textDecoration: "none !important",
          transition: "all 0.05s ease",
          "&:hover": {
            backgroundColor: "#2F4D82",
            color: "#FFFFFF !important",
            textDecoration: "none !important",
          },
        }}
        className={style.button}
        onClick={() => {
          sessionStorage.clear();
          sessionStorage.setItem("loc", currentLoc);
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          isApp ? sessionStorage.setItem("isApp", isApp) : null;
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          isApp ? localStorage.setItem("isApp", isApp) : null;
          dipatch(resetTicket());
          setFormValues({
            ...clearFormValues,
            order_type: "multi",
          });
          navigateToGroup();
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
        }}
        onMouseEnter={() => setIsHovered2(true)}
        onMouseLeave={() => setIsHovered2(false)}
      >
        <img alt="button_icon" src={groupIcon} className={style.groupIcon} />
        <Box className={style.buttonContent}>
          <span className={style.buttonText}>{t("travel_action")}</span>
          <span className={style.buttonSubtext}>{t("group_subtitle")}</span>
        </Box>
        <img
          alt="button_icon"
          className={style.caret}
          src={isHovered2 ? caretHovered : caret}
          style={{
            position: "absolute",
            top: "50%",
            left: "100%",
            transform: "translate(-50%, -50%)",
            transition: "all 0.05s ease",
          }}
        />
      </Button>
      <Button
        sx={{
          ...styles.button,
          textDecoration: "none !important",
          transition: "all 0.05s ease",
          "&:hover": {
            backgroundColor: "#2F4D82",
            color: "#FFFFFF !important",
            textDecoration: "none !important",
          },
        }}
        className={style.button}
        onClick={() => {
          sessionStorage.clear();
          sessionStorage.setItem("loc", currentLoc);
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          isApp ? sessionStorage.setItem("isApp", isApp) : null;
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          isApp ? localStorage.setItem("isApp", isApp) : null;
          dipatch(resetTicket());
          setFormValues(clearFormValues);
          navigateToSchool();
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
        }}
        onMouseEnter={() => setIsHovered3(true)}
        onMouseLeave={() => setIsHovered3(false)}
      >
        <img alt="button_icon" src={schoolIcon} className={style.schoolIcon} />
        <Box className={style.buttonContent}>
          <span className={style.buttonText}>{t("schools")} </span>
          <span className={style.buttonSubtext}>{t("school_subtitle")}</span>
        </Box>
        <img
          alt="button_icon"
          className={style.caret}
          src={isHovered3 ? caretHovered : caret}
          style={{
            position: "absolute",
            top: "50%",
            left: "100%",
            transform: "translate(-50%, -50%)",
            transition: "all 0.05s ease",
          }}
        />
      </Button>
      <Button
        sx={{
          ...styles.button,
          textDecoration: "none !important",
          transition: "all 0.05s ease",
          "&:hover": {
            backgroundColor: "#2F4D82",
            color: "#FFFFFF !important",
            textDecoration: "none !important",
          },
        }}
        className={style.button}
        onClick={() => {
          sessionStorage.clear();
          sessionStorage.setItem("loc", currentLoc);
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          isApp ? sessionStorage.setItem("isApp", isApp) : null;
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          isApp ? localStorage.setItem("isApp", isApp) : null;
          setFormValues(clearFormValues);
          navigateToTravel();
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
        }}
        onMouseEnter={() => setIsHovered4(true)}
        onMouseLeave={() => setIsHovered4(false)}
      >
        <img alt="button_icon" src={travelIcon} className={style.travelIcon} />
        <Box className={style.buttonContent}>
          <span className={style.buttonText}>{t("travel_info")}</span>
          <span className={style.buttonSubtext}>{t("travel_subtitle")}</span>
        </Box>
        <img
          alt="button_icon"
          className={style.caret}
          src={isHovered4 ? caretHovered : caret}
          style={{
            position: "absolute",
            top: "50%",
            left: "100%",
            transform: "translate(-50%, -50%)",
            transition: "all 0.05s ease",
          }}
        />
      </Button>
    </Box>
  );
});

PurchasingTickets.displayName = "PurchasingTickets";
export default PurchasingTickets;
