import React from "react";

//components
import { Box } from "@mui/material";
import styles from "./styles.module.css";
import NoPhoto from "src/components/icons/NoPhoto";

const HorizontalGallery = ({ photos }: { photos: string[] }): JSX.Element => {
  return (
    <Box className={styles.wrapper}>
      {photos.map((photo, index) => {
        if (photo) {
          return <img key={index} src={photo} alt="" />;
        } else {
          return <NoPhoto key={index} />;
        }
      })}
    </Box>
  );
};

export default HorizontalGallery;
