import React, { useEffect, useRef } from "react";
import styles from "./styles.module.css";
import SubPageHeader from "src/components/atoms/SubPageHeader";
import Footer from "src/components/molecules/Footer";
import SecurityData from "src/components/atoms/SecurityData";
import { useTranslation } from "react-i18next";

const PrivacyPolicy = (): JSX.Element => {
  const { t } = useTranslation();
  const topRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (topRef.current) {
      window.scrollTo({
        behavior: "smooth",
        top: topRef.current.offsetTop - 150,
      });
    }
  }, []);

  return (
    <>
      <div className={styles.wrapper} ref={topRef}>
        <SubPageHeader pageName={`${t("data_security_head")}`} />
        <SecurityData />
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
