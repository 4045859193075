import { Box } from "@mui/material";
import { useEffect, useState } from "react";

import icon7 from "../../../assets/no drinking.svg";
import icon8 from "../../../assets/No food.svg";
import icon9 from "../../../assets/no smoking.svg";
import icon10 from "../../../assets/No sandals.svg";
import icon11 from "../../../assets/no trash.svg";
import icon14 from "../../../assets/Silente.png";

import icon12 from "../../../assets/no cap.svg";
import icon13 from "../../../assets/no-swimwear.svg";

import VisitRules from "src/components/atoms/VisitRules";
import { TShirtIcon } from "src/components/icons/TShirtIcon";

import styles from "./styles.module.css";

import { t } from "i18next";
import { ConditionBoxGreyMobile } from "../ConditionBoxGreyMobile";
import ModalVisitingFour from "src/components/atoms/ModalVisitingFour";
import { CIrcleCrossIcon } from "src/components/icons/CIrcleCrossIcon";

export const PreFooter = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [openModal1, setOpenModal1] = useState<string>("hidden");
  const [openModal2, setOpenModal2] = useState<string>("hidden");
  const [openModal3, setOpenModal3] = useState<string>("hidden");

  const circleText1 = t("modal_visit_rules_one_list_one");
  const circleText2 = t("modal_visit_rules_one_list_two");
  const circleText3 = t("modal_visit_rules_one_list_three");

  const modalRules = {
    second: {
      headerIcon: <CIrcleCrossIcon />,
      headerText: t("modal_visit_rules_one_header"),
      iconsArr: [icon8, icon9, icon14, icon11],
      mainText: `${t("modal_visit_rules_one")} ${t("modal_visit_rules_one_cont")}`,
      textWithCircle: [circleText1, circleText2, circleText3],
    },
    third: {
      headerIcon: <TShirtIcon />,
      headerText: t("clothes_rules"),
      iconsArr: [icon12, icon13, icon10],
      mainText: `${t("modal_visit_rules_two")} ${t("modal_visit_rules_three")} ${t(
        "modal_visit_rules_four",
      )} ${t("modal_visit_rules_five")}`,
      textWithCircle: [],
    },
  };

  const editWrapperStyle = (): string => {
    if (location.pathname === "/pre-reservation" && !isMobile) {
      return styles.wrapper_mutated;
    } else {
      return styles.wrapper;
    }
  };
  const updateMedia = () => {
    setIsMobile(window.innerWidth <= 768);
  };
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);

  return (
    <Box className={editWrapperStyle()}>
      <div className={styles.mobile_only}>
        <Box className={styles.container}>
          <div className={styles.box}>
            <ConditionBoxGreyMobile
              title={t("visit_info_footer")}
              withIcons={false}
              onClick={setOpenModal1}
            />
            <ModalVisitingFour
              open={openModal1 === "visible"}
              handleClose={() => setOpenModal1("hidden")}
            />
          </div>
          <div className={styles.box}>
            <ConditionBoxGreyMobile
              onClick={() => setOpenModal2("visible")}
              title={t("safe_rules")}
              img1={icon7}
              img2={icon14}
              img3={icon9}
              img5={icon11}
              withIcons={true}
            />
            <VisitRules
              open={openModal2 === "visible"}
              handleClose={() => setOpenModal2("hidden")}
              anchorEl={"rules2"} //need to connect ref
              headerIcon={modalRules.second.headerIcon}
              headerText={modalRules.second.headerText}
              iconsArr={modalRules.second.iconsArr}
              mainText={modalRules.second.mainText}
              textWithCircle={modalRules.second.textWithCircle}
            />
          </div>

          <div className={styles.box}>
            <ConditionBoxGreyMobile
              title={t("clothes_rules")}
              subTitle={t("clothes_sub_rules")}
              img1={icon12}
              img2={icon13}
              img4={icon10}
              onClick={() => setOpenModal3("visible")}
              withIcons={true}
              isMutated={true}
            />
            <VisitRules
              open={openModal3 === "visible"}
              handleClose={() => setOpenModal3("hidden")}
              anchorEl={"rules3"} //need to connect ref
              headerIcon={modalRules.third.headerIcon}
              headerText={modalRules.third.headerText}
              iconsArr={modalRules.third.iconsArr}
              mainText={modalRules.third.mainText}
              textWithCircle={modalRules.third.textWithCircle}
            />
          </div>
        </Box>
      </div>
    </Box>
  );
};
