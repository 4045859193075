// eslint-disable-next-line import/no-named-as-default
import styled from "styled-components";

interface IButtonNextStep {
  isActive: boolean;
}

export const ButtonNextStep = styled.button<IButtonNextStep>`
  display: flex;
  height: 50px;
  padding: 13px 44px 13px 44px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  color: #e8ebf0;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  background: ${(props) => (props.isActive ? "#e31340" : "#ccc")};
`;
