import React from "react";
import { HeadphonesIcon } from "src/components/icons/HeadphonesIcon";
import { TacktickIcon } from "src/components/icons/TacktickIcon";
import { KostelIcon } from "src/components/icons/KostelIcon";

export interface IMainPageList {
  icon: React.ReactNode;
  text: string;
}

export const MainPageList: IMainPageList[] = [
  {
    icon: <TacktickIcon />,
    text: "main_page_rule_one",
  },
  {
    icon: <KostelIcon />,
    text: "main_page_rule_two",
  },
  {
    icon: <HeadphonesIcon />,
    text: "main_page_rule_three",
  },
];
