import { useQuery } from "react-query";
import axios from "axios";
import { DISCOUNT_API_ROUTE } from "../endpoints";
import { Discount } from "../types";
import { useFormValuesContext } from "../context";
import { useState } from "react";

export const useGetDiscount = ({ code }: { code: string | null }) => {
  const { formValues, setFormValues } = useFormValuesContext();

  const fetchDiscount: () => Promise<Discount> = async () => {
    try {
      const res = await axios.get(`${window.BASE_API_URL}/${DISCOUNT_API_ROUTE}/${code}`);
      const data = res.data;

      if (data.result === "OK") {
        if (data.type === "Percent" && formValues.totalPrice !== null) {
          const discountPercentAmount = (formValues.totalPrice * data.amount) / 100;
          const finalPrice = formValues.totalPrice - discountPercentAmount;

          setFormValues((prevFormValues) => ({
            ...prevFormValues,
            finalPrice: finalPrice,
            discount_percent_amount: data.amount,
          }));
        } else if (data.type === "Flat" && formValues.totalPrice !== null) {
          let finalPrice = formValues.totalPrice;
          if (data.amount <= formValues.totalPrice) {
            finalPrice = formValues.totalPrice - data.amount;
          } else if (data.amount > formValues.totalPrice) {
            finalPrice = 0;
          }
          setFormValues({
            ...formValues,
            finalPrice: finalPrice,
            discount_amount: data.amount,
          });
        }
      } else if (data.result === "ALREADY_USED") {
        console.log("ALREADY_USED");
      } else if (data.result === "INVALID") {
        setFormValues({ ...formValues, discount_percent_amount: 0, discount_amount: 0 });
        console.log("INVALID");
      }
      return data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  };
  return useQuery(`fetchDiscount-${code}`, fetchDiscount, {
    refetchOnWindowFocus: false,
    enabled: false,
    refetchInterval: 2,
  });
};
