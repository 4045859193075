import React from "react";

//components
import styles from "./styles.module.css";
import { useTranslation } from "react-i18next";

const OrComponent = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div className={styles.wrapper}>
      <div className={styles.line}></div>
      <span className={styles.text}>{t("or")}</span>
      <div className={styles.line}></div>
    </div>
  );
};

export default OrComponent;
