import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import React, { forwardRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import personIcon from "../../../assets/kontaktni_udaje_cervene.svg";
import { styles } from "./styles";
import arrowDown from "../../../assets/arrow_white.svg";
import { useFormValuesContext } from "src/core/context";
import { Controller, useForm } from "react-hook-form";
import styless from "./style.module.css";
import checkmarkIcon from "../../../assets/checkmark (1).svg";
import call from "../../../assets/call.svg";
import mail from "../../../assets/mail (2).svg";
import osoba from "../../../assets/osoba_cerna.svg";
import errorIcon from "../../../assets/error (1).svg";
import groupIcon from "../../../assets/group_ikon.svg";
import { GroupCheckBox } from "../GroupCheckBox";
import { AdvertisingBoxSmall } from "../AdvertisingBoxSmall";
import { useSentGroupForm } from "src/core/queries/useSentForm";

const GroupForm = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const { mutate: mutateGroupForm } = useSentGroupForm();
  const [isDesktop, setIsDesktop] = useState<boolean>(window.innerWidth > 768);
  const [consentOneChecked, setConsentOneChecked] = useState(false);
  const [isFormSent, setIsFormSent] = useState<boolean>(false);
  const loc = sessionStorage.getItem("loc");

  const { formValues, setFormValues } = useFormValuesContext();

  const ticketsForm = useForm({
    defaultValues: {
      name: formValues.name,
      phone_number: formValues.phone_number,
      email: formValues.email,
      validEmail: formValues.validEmail,
      amount: formValues.amount,
    },
    reValidateMode: "onChange",
    mode: "all",
  });

  const {
    control,
    getValues,
    formState: { isValid },
    reset,
  } = ticketsForm;

  const handleContinue = (formData: {
    name: string;
    phone_number: string | number;
    email: string;
    validEmail: string;
    amount: string;
  }) => {
    const newFormValues = { ...formValues, ...formData };
    setFormValues(newFormValues);
    mutateGroupForm(
      {
        name: newFormValues.name,
        email: newFormValues.email,
        phone_number: Number(newFormValues.phone_number),
        amount: Number(newFormValues.amount),
      },
      {
        onSuccess: () => {
          reset();
          setConsentOneChecked(false);
          setIsFormSent(true);
        },
      },
    );
  };

  // CheckboxComponent

  const handleConsentChange = (consentOne: boolean) => {
    setConsentOneChecked(consentOne);
  };

  const mutateCss = (): string => {
    if ((loc === "de" || loc === "en") && isDesktop) {
      return styless.box_mutated;
    }
    return styless.box;
  };

  const mutateFooterCss = (): string => {
    if ((loc === "de" || loc === "en") && isDesktop) {
      return styless.footerItemsMutated;
    }
    return styless.footerItems;
  };

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 1023);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Box className={styless.container} ref={ref}>
      <Box className={styless.groupTitleBox}>
        <img alt="groupIcon" src={groupIcon} style={{ width: "32px" }} />
        <span className={styless.groupTitle}>{t("group_title_second")}</span>
      </Box>
      <Box className={styless.wrapper}>
        <form onSubmit={ticketsForm.handleSubmit(handleContinue)}>
          <Box className={mutateCss()}>
            <span style={styles.title}>
              <img src={personIcon} style={{ marginRight: "8px" }} />
              <span>{t("contant_button")}</span>
            </span>
            <Box className={styless.innerBoxRow}>
              <Box className={styless.innerBox}>
                <Box className={styless.textfieldRow}>
                  <Box className={styless.textfieldBox}>
                    <img alt="osoba" src={osoba} />
                    <FormControl
                      className={styless.textField}
                      sx={{
                        marginLeft: "10px",
                      }}
                    >
                      <InputLabel
                        htmlFor="name_surname"
                        sx={{
                          "&.MuiFormLabel-colorPrimary.MuiFormLabel-filled": {
                            fontSize: "18px",
                            fontWeight: "400",
                            color: "#000000",
                          },
                          "&.MuiFormLabel-colorPrimary.MuiFormLabel-filled:hover": {
                            fontSize: "18px",
                            fontWeight: "400",
                            color: "#000000",
                          },
                          "&.Mui-focused": {
                            fontSize: "18px",
                            fontWeight: "400",
                            color: "#000000",
                          },
                          "&.Mui-error": {
                            fontSize: "18px",
                            fontWeight: "400",
                            color: "#E31340",
                          },
                        }}
                      >
                        {t("name_surname")}
                      </InputLabel>
                      <Controller
                        name="name"
                        control={control}
                        rules={{
                          required: t("fill_in_name_and_surname"),
                        }}
                        render={({ field, fieldState: { isTouched, error } }) => (
                          <React.Fragment>
                            <OutlinedInput
                              id="name_surname"
                              value={field.value}
                              onChange={field.onChange}
                              endAdornment={
                                <InputAdornment position="end">
                                  {error ? (
                                    <img src={errorIcon} style={{ width: "16px" }} />
                                  ) : isTouched && !error ? (
                                    <img src={checkmarkIcon} style={{ width: "16px" }} />
                                  ) : null}
                                </InputAdornment>
                              }
                              error={!!error}
                              label={t("name_surname")}
                              sx={{
                                "& input": {
                                  color: error ? "#E31340" : "#000000",
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: error ? "#E31340" : "#79747E",
                                },
                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                  borderColor: error ? "#E31340" : "#062762",
                                },
                                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                  border: "1px solid #000000",
                                  outline: "none",
                                  boxShadow: "none",
                                },
                              }}
                            />
                            {error && <FormHelperText error>{error.message}</FormHelperText>}
                          </React.Fragment>
                        )}
                      />
                    </FormControl>
                  </Box>
                  <Box className={styless.textfieldBox}>
                    <img src={call} />
                    <FormControl
                      className={styless.textField}
                      sx={{
                        marginLeft: "10px",
                      }}
                    >
                      <InputLabel
                        sx={{
                          "&.MuiFormLabel-colorPrimary.MuiFormLabel-filled": {
                            fontSize: "18px",
                            fontWeight: "400",
                            color: "#000000",
                          },
                          "&.MuiFormLabel-colorPrimary.MuiFormLabel-filled:hover": {
                            fontSize: "18px",
                            fontWeight: "400",
                            color: "#000000",
                          },
                          "&.Mui-focused": {
                            fontSize: "18px",
                            fontWeight: "400",
                            color: "#000000",
                          },
                          "&.Mui-error": {
                            fontSize: "18px",
                            fontWeight: "400",
                            color: "#E31340",
                          },
                        }}
                        htmlFor="phone_number"
                      >
                        {t("phone_number")}
                      </InputLabel>
                      <Controller
                        name="phone_number"
                        control={control}
                        rules={{
                          validate: (value) => {
                            const isValidNumber =
                              /^\+?\d{1,4}?[\s.-]?\(?\d{1,4}?\)?[\s.-]?\d{1,4}[\s.-]?\d{1,4}[\s.-]?\d{1,9}$/.test(
                                String(value),
                              );
                            return isValidNumber || t("fill_in_valid_phone_number");
                          },
                          required: t("fill_in_valid_phone_number"),
                        }}
                        render={({
                          field: { onChange, value },
                          fieldState: { isTouched, error },
                        }) => (
                          <React.Fragment>
                            <OutlinedInput
                              id="phone_number"
                              label={t("phone_number")}
                              value={value}
                              onChange={onChange}
                              endAdornment={
                                <InputAdornment position="end">
                                  {error ? (
                                    <img src={errorIcon} style={{ width: "16px" }} />
                                  ) : isTouched && !error ? (
                                    <img src={checkmarkIcon} style={{ width: "16px" }} />
                                  ) : null}
                                </InputAdornment>
                              }
                              error={!!error}
                              sx={{
                                "& input": {
                                  color: error ? "#E31340" : "#000000",
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: error ? "#E31340" : "#79747E",
                                },
                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                  borderColor: error ? "#E31340" : "#062762",
                                },
                                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                  border: "1px solid #000000",
                                  outline: "none",
                                  boxShadow: "none",
                                },
                              }}
                            />
                            {error && <FormHelperText error>{error.message}</FormHelperText>}
                          </React.Fragment>
                        )}
                      />
                    </FormControl>
                  </Box>
                </Box>
                <Box className={styless.textfieldRow}>
                  <Box className={styless.textfieldBox}>
                    <img src={mail} />
                    <FormControl
                      className={styless.textField}
                      sx={{
                        marginLeft: "10px",
                      }}
                    >
                      <InputLabel
                        sx={{
                          "&.MuiFormLabel-colorPrimary.MuiFormLabel-filled": {
                            fontSize: "18px",
                            fontWeight: "400",
                            color: "#000000",
                          },
                          "&.MuiFormLabel-colorPrimary.MuiFormLabel-filled:hover": {
                            fontSize: "18px",
                            fontWeight: "400",
                            color: "#000000",
                          },
                          "&.Mui-focused": {
                            fontSize: "18px",
                            fontWeight: "400",
                            color: "#000000",
                          },
                          "&.Mui-error": {
                            fontSize: "18px",
                            fontWeight: "400",
                            color: "#E31340",
                          },
                        }}
                        htmlFor="email_address"
                      >
                        {t("email_address")}
                      </InputLabel>
                      <Controller
                        name="email"
                        control={control}
                        rules={{
                          required: t("fill_in_valid_email_address"),
                          validate: (v) =>
                            /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                            t("fill_in_valid_email_address"),
                        }}
                        render={({ field, fieldState: { isTouched, error } }) => (
                          <React.Fragment>
                            <OutlinedInput
                              id="email"
                              value={field.value}
                              onChange={field.onChange}
                              endAdornment={
                                <InputAdornment position="end">
                                  {error ? (
                                    <img src={errorIcon} style={{ width: "16px" }} />
                                  ) : isTouched && !error ? (
                                    <img src={checkmarkIcon} style={{ width: "16px" }} />
                                  ) : null}
                                </InputAdornment>
                              }
                              error={!!error}
                              label={t("email_address")}
                              sx={{
                                "& input": {
                                  color: error ? "#E31340" : "#000000",
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: error ? "#E31340" : "#79747E",
                                },
                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                  borderColor: error ? "#E31340" : "#062762",
                                },
                                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                  border: "1px solid #000000",
                                  outline: "none",
                                  boxShadow: "none",
                                },
                              }}
                            />
                            {error && <FormHelperText error>{error.message}</FormHelperText>}
                          </React.Fragment>
                        )}
                      />
                    </FormControl>
                  </Box>
                  <Box className={styless.textfieldBox}>
                    <img src={mail} />
                    <FormControl
                      className={styless.textField}
                      sx={{
                        marginLeft: "10px",
                      }}
                    >
                      <InputLabel
                        sx={{
                          "&.MuiFormLabel-colorPrimary.MuiFormLabel-filled": {
                            fontSize: "18px",
                            fontWeight: "400",
                            color: "#000000",
                          },
                          "&.MuiFormLabel-colorPrimary.MuiFormLabel-filled:hover": {
                            fontSize: "18px",
                            fontWeight: "400",
                            color: "#000000",
                          },
                          "&.Mui-focused": {
                            fontSize: "18px",
                            fontWeight: "400",
                            color: "#000000",
                          },
                          "&.Mui-error": {
                            fontSize: "18px",
                            fontWeight: "400",
                            color: "#E31340",
                          },
                        }}
                        htmlFor="email_address_confirmation"
                      >
                        {t("email_address_confirmation")}
                      </InputLabel>
                      <Controller
                        name="validEmail"
                        control={control}
                        rules={{
                          required: t("fill_in_valid_email_address"),
                          validate: (value) =>
                            value === getValues("email") || t("fill_in_valid_email_address"),
                        }}
                        render={({
                          field: { onChange, value },
                          fieldState: { isTouched, error },
                        }) => (
                          <React.Fragment>
                            <OutlinedInput
                              id="email_address_confirmation"
                              label={t("email_address_confirmation")}
                              value={value}
                              onChange={onChange}
                              endAdornment={
                                <InputAdornment position="end">
                                  {error ? (
                                    <img src={errorIcon} style={{ width: "16px" }} />
                                  ) : isTouched && !error ? (
                                    <img src={checkmarkIcon} style={{ width: "16px" }} />
                                  ) : null}
                                </InputAdornment>
                              }
                              error={!!error}
                              sx={{
                                "& input": {
                                  color: error ? "#E31340" : "#000000",
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: error ? "#E31340" : "#79747E",
                                },
                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                  borderColor: error ? "#E31340" : "#062762",
                                },
                                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                  border: "1px solid #000000",
                                  outline: "none",
                                  boxShadow: "none",
                                },
                              }}
                            />
                            {error && <FormHelperText error>{error.message}</FormHelperText>}
                          </React.Fragment>
                        )}
                      />
                    </FormControl>
                  </Box>
                </Box>
                <Box className={styless.textfieldBox} sx={{ marginLeft: isDesktop ? "-50%" : "0" }}>
                  <img src={osoba} />
                  <FormControl
                    className={styless.textField}
                    sx={{
                      marginLeft: "10px",
                    }}
                  >
                    <InputLabel
                      htmlFor="name_surname"
                      sx={{
                        "&.MuiFormLabel-colorPrimary.MuiFormLabel-filled": {
                          fontSize: "18px",
                          fontWeight: "400",
                          color: "#000000",
                        },
                        "&.MuiFormLabel-colorPrimary.MuiFormLabel-filled:hover": {
                          fontSize: "18px",
                          fontWeight: "400",
                          color: "#000000",
                        },
                        "&.Mui-focused": {
                          fontSize: "18px",
                          fontWeight: "400",
                          color: "#000000",
                        },
                        "&.Mui-error": {
                          fontSize: "18px",
                          fontWeight: "400",
                          color: "#E31340",
                        },
                      }}
                    >
                      {t("how_many_people")}
                    </InputLabel>
                    <Controller
                      name="amount"
                      control={control}
                      rules={{
                        validate: (value) => {
                          const isValidNumber = /^[1-9]\d*$/.test(String(value));
                          return isValidNumber || t("amount_of_people");
                        },

                        required: t("amount_of_people"),
                      }}
                      render={({ field, fieldState: { isTouched, error } }) => (
                        <React.Fragment>
                          <OutlinedInput
                            id="amount_of_people"
                            value={field.value}
                            onChange={field.onChange}
                            endAdornment={
                              <InputAdornment position="end">
                                {error ? (
                                  <img src={errorIcon} style={{ width: "16px" }} />
                                ) : isTouched && !error ? (
                                  <img src={checkmarkIcon} style={{ width: "16px" }} />
                                ) : null}
                              </InputAdornment>
                            }
                            error={!!error}
                            label={t("amount_of_people")}
                            sx={{
                              "& input": {
                                color: error ? "#E31340" : "#000000",
                              },
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: error ? "#E31340" : "#79747E",
                              },
                              "&:hover .MuiOutlinedInput-notchedOutline": {
                                borderColor: error ? "#E31340" : "#062762",
                              },
                              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                border: "1px solid #000000",
                                outline: "none",
                                boxShadow: "none",
                              },
                            }}
                          />
                          {error && <FormHelperText error>{error.message}</FormHelperText>}
                        </React.Fragment>
                      )}
                    />
                  </FormControl>
                </Box>
              </Box>
              <Box className={mutateFooterCss()}>
                <GroupCheckBox
                  isConsent={consentOneChecked}
                  onConsentChange={handleConsentChange}
                />
                <Button
                  disabled={!isValid || !consentOneChecked || isFormSent}
                  type="submit"
                  className={styless.buttonToGo}
                  sx={{
                    ...styles.buttonToGo,
                    "&:hover": {
                      backgroundColor: "#E31340",
                      color: "white",
                    },
                    "&:disabled": {
                      backgroundColor: isFormSent ? "#58BC51" : "grey",
                      color: "white",
                    },
                  }}
                  style={{ textDecoration: "none" }}
                >
                  <span style={{ marginRight: "5px" }}>
                    {isFormSent ? t("group_sent_ready") : t("group_sent")}
                  </span>
                  <img src={arrowDown} />
                </Button>
              </Box>
            </Box>
          </Box>
        </form>
        <AdvertisingBoxSmall />
      </Box>
    </Box>
  );
});
GroupForm.displayName = "GroupForm";
export default GroupForm;
