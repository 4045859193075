import React from "react";
import styles from "./styles.module.css";
import { useTranslation } from "react-i18next";

const MainPageListRuleItem = ({
  icon,
  text,
}: {
  icon: React.ReactNode;
  text: string;
}): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div className={styles.wrapper}>
      {icon}
      <div className={styles.text} dangerouslySetInnerHTML={{ __html: t(text) }} />
    </div>
  );
};

export default MainPageListRuleItem;
