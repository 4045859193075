import React, { CSSProperties } from "react";
import noPhoto from "../../assets/Rectangle 26 (2).png";

const style: CSSProperties = {
  width: "120px",
  margin: "5px",
  height: "90px",
  objectFit: "cover",
};

const NoPhoto = (): JSX.Element => {
  return <img style={style} src={noPhoto} alt="" />;
};

export default NoPhoto;
