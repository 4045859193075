import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { desktopHistory } from "src/core/helpers/desktopHistory";
import InformationBlockDesktop from "src/components/atoms/InformationBlockDesktop";
import SubPageHeader from "src/components/atoms/SubPageHeader";
import { TimeArrow } from "src/components/icons/TimeArrow";
import { ArrowDownHistory } from "src/components/icons/ArrowDownHistory";
import { useTranslation } from "react-i18next";

const PageHistoryDesktop = (): JSX.Element => {
  const { t } = useTranslation();
  const reversedHistory = [...desktopHistory].reverse();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1330);
  const updateMedia = () => {
    setIsMobile(window.innerWidth <= 1330);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);

  const iconStyle = {
    width: "136px",
    display: "contents",
    top: "-200px",
  };

  return (
    <div className={styles.wrapper}>
      <SubPageHeader pageName={t("main_page_history")} />
      <div className={styles.header}>{t("history_notable")}</div>
      <div className={styles.arrow_down}>
        <ArrowDownHistory />
      </div>
      <VerticalTimeline lineColor={"#062762"} className={styles.line}>
        {reversedHistory.map((el, index) => (
          <VerticalTimelineElement
            key={index}
            contentStyle={{
              border: "none",
              boxShadow: "none",
              marginBottom:
                index !== 0 &&
                index !== desktopHistory.length - 1 &&
                index !== desktopHistory.length - 3
                  ? "-270px"
                  : "0",
              left: `${index % 2 == 0 ? "-64px" : "55px"}`,
            }}
            iconStyle={iconStyle}
            icon={!isMobile && <TimeArrow reverse={index % 2 == 0} />}
          >
            <InformationBlockDesktop
              key={el.header}
              content={t(el.content)}
              header={el.header}
              images={el.images}
              withImages={el.withImages}
              imageCSS={el.imageCSS}
            />
          </VerticalTimelineElement>
        ))}
      </VerticalTimeline>
    </div>
  );
};

export default PageHistoryDesktop;
