import { useQuery } from "react-query";
import axios from "axios";
import { GET_DEBT } from "../endpoints";

export const useGetDebt = () => {
  const fetchDebt: () => Promise<{ debt: number; paid_debt: number; date: string }> = async () => {
    const res = await axios.get(`${window.BASE_API_URL}/${GET_DEBT}`);
    return res.data;
  };

  const { data, status } = useQuery(`fetchDebt`, fetchDebt, {
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    cacheTime: 5000,
    enabled: true,
  });
  return { data, status };
};
