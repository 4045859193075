import React from "react";

//components
import { Box } from "@mui/material";
import styles from "./styles.module.css";
import { useNavigate } from "react-router-dom";
import RedButtonMore from "src/components/atoms/RedButtonMore";

const InfoComponent = ({
  header,
  mainText,
  navigateTo,
}: {
  header: string;
  mainText: string;
  navigateTo: string;
}): JSX.Element => {
  const navigate = useNavigate();
  const navigateToFAQ = (): void => {
    navigate(`/${navigateTo}`);
  };
  return (
    <Box className={styles.wrapper}>
      <span className={styles.main_text}>{header}</span>
      <span className={styles.red_text} onClick={navigateToFAQ}>
        {mainText}
        <RedButtonMore size={{ w: "20", h: "20" }} />
      </span>
    </Box>
  );
};

export default InfoComponent;
