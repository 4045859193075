import React from "react";
import styles from "./styles.module.css";
import { PreFooter } from "src/components/atoms/PreFooter";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const VisitConditions = (): JSX.Element => {
  const location = useLocation();
  const { t } = useTranslation();
  const getStyle = (): string => {
    switch (location.pathname) {
      case "/group":
        return styles.wrapper_group;
      case "/travel":
        return styles.wrapper_travel;
      default:
        return styles.wrapper;
    }
  };
  return (
    <div className={getStyle()}>
      <div className={styles.header}>{t("how_does_it_work")}</div>
      <span className={styles.description}>
        {t("marketplace_rules")} <br />
        <a
          target={"_blank"}
          rel={"noreferrer"}
          href={
            "https://docs.google.com/document/d/1TUK5Rj3bMdfYg6bGi7KFPE6nGPQqiCaG1Oki8PDwQcw/edit?usp=sharing"
          }
          className={styles.click_description}
        >
          {t("marketplace")}
        </a>
      </span>
      <PreFooter />
    </div>
  );
};

export default VisitConditions;
