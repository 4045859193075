import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import { style } from "./styles";

export const PageDefaltPayment = () => {
  return (
    <Box sx={style.container}>
      <Outlet />
    </Box>
  );
};
