import { Box } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { SchoolContentText } from "src/components/atoms/SchoolContentText";
import Footer from "src/components/molecules/Footer";
import { ContentPicture } from "src/components/atoms/ContentPicture";
import desktopPhoto from "../../../assets/Mask Group 9@2x.png";
import mobilePhoto from "../../../assets/G0010210.png";
import phoneIcon from "../../../assets/phone-call.svg";

export const PageSchool = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const isApp = sessionStorage.getItem("isApp");
  const loc = sessionStorage.getItem("loc");

  const updateMedia = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);

  const numberRef = useRef<HTMLDivElement | null>(null);
  const phoneButtonCLick = (): void => {
    window.scrollTo({
      top: numberRef.current?.offsetTop,
      behavior: "smooth",
    });
  };

  const mainPhoto = isMobile ? mobilePhoto : desktopPhoto;
  const style = {
    container: {
      transform: isMobile ? "translateX(-10px)" : "translateX(0px)",
      marginTop: isApp ? "-50px" : "0px",
    },
  };
  return (
    <Box sx={style.container}>
      <ContentPicture
        title={isMobile ? "main_title_mob" : "main_title"}
        buttonText={
          loc === "de" ? (isMobile ? "call_invitation_mob" : "call_invitation") : "call_invitation"
        }
        subtitle={"for_school"}
        mainPhoto={mainPhoto}
        click={phoneButtonCLick}
        buttonIcon={phoneIcon}
      />
      <SchoolContentText numref={numberRef} />
      <Footer />
    </Box>
  );
};
