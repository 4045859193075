import history from "../../assets/CeilImage.jpg";
import persons from "../../assets/PersonChanged.png";
import photo1 from "../../assets/foto-pozadi2.png";
import photo4 from "../../assets/Visualove_puda_u_Jakuba-50.jpg";
import photo2 from "../../assets/Náhled Galerie 4.png";
import photo3 from "../../assets/GFX56095-Enhanced-NR.jpg";
import reconstructionPhoto from "../../assets/Jakub_rekonstrukce-425.jpg";
export interface IGallery {
  id: string;
  header: string;
  image: string | string[];
  usual: boolean;
  href: string;
}
export const galleryDesktop: IGallery[] = [
  {
    id: "galery",
    header: "main_page_gallery",
    image: [photo1, photo4, photo3, photo2],
    usual: false,
    href: "/gallery",
  },
  {
    id: "persons",
    header: "main_page_persons",
    image: persons,
    usual: true,
    href: "/persons",
  },
];

export const galleryDesktopNext: IGallery[] = [
  {
    id: "history",
    header: "main_page_history",
    image: history,
    usual: true,
    href: "/history",
  },
  {
    id: "reconstruction",
    header: "main_page_restoration",
    image: reconstructionPhoto,
    usual: true,
    href: "/reconstruction",
  },
];
