import { Action, MobilePayload, MobileType } from "src/core/types/reduxTypes";

interface MobileState {
  isApp: boolean;
  appLang: string;
}

const initialState: MobileState = {
  isApp: false,
  appLang: "cs",
};

//eslint-disable-next-line @typescript-eslint/default-param-last
export const MobileReducer = (state = initialState, action: Action<MobileType, MobilePayload>) => {
  switch (action.type) {
    case "SET_MOBILE":
      return {
        ...state,
        isApp: action.payload,
      };
    case "SET_LANGUAGE":
      return {
        ...state,
        appLang: action.payload,
      };
    default:
      return state;
  }
};
