import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const CrossIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon {...props} width="11.414" height="11.414" viewBox="0 0 11.414 11.414">
    <svg xmlns="http://www.w3.org/2000/svg">
      <g transform="translate(0.707 0.707)">
        <line x1="10" y2="10" fill="none" stroke="#E6264C" strokeLinecap="round" strokeWidth="1" />
        <line x2="10" y2="10" fill="none" stroke="#E6264C" strokeLinecap="round" strokeWidth="1" />
      </g>
    </svg>
  </SvgIcon>
);
