import React from "react";
import { Box, Typography } from "@mui/material";
import { DateCalendar } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import style from "./styles.module.css";
import { useTranslation } from "react-i18next";
import colorCalendar from "../../../assets/kalendar_cerveny.svg";

export interface SimpleDialogProps {
  selectedValue: Date;
  fullSlots?: string[];
  onClick: (date: Date) => void;
}

export const CalendarDialog = ({ selectedValue, fullSlots, onClick }: SimpleDialogProps) => {
  //middle version
  const [isMiddle, setIsMiddle] = React.useState(
    window.innerWidth < 1280 && window.innerWidth > 420,
  );
  const updateisMiddle = () => {
    setIsMiddle(window.innerWidth < 1280 && window.innerWidth > 420);
  };
  React.useEffect(() => {
    window.addEventListener("resize", updateisMiddle);
    return () => window.removeEventListener("resize", updateisMiddle);
  }, []);

  //mobile version
  const [isMobile, setIsMobile] = React.useState(window.innerWidth <= 420);
  const updateMedia = () => {
    setIsMobile(window.innerWidth <= 420);
  };
  React.useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);

  const styles = {
    dialog: {
      width: isMobile ? "250px" : isMiddle ? "333px" : "343px",
      height: "438px",
      border: "1px solid #E1E1E1",
      color: "#000",
      padding: "10px 14px 10px 14px",
      position: "relative",
      backgroundColor: "#FFFFFF",
    },
    text: {
      color: "#062762",
      variant: "body2",
      fontWeight: "bold",
      fontSize: "18px",
      marginBottom: "10px",
      display: "flex",
      alignItems: "center",
      alignSelf: "flex-start",
    },
    calendar: {
      width: isMobile ? "250px" : isMiddle ? "333px" : "343px",
      height: "438px",
      maxHeight: "unset",
      "& .MuiPickersCalendarHeader-root": {
        position: "relative",
        padding: "3px",
        width: "calc(100% + 10px)",
        margin: "4px 0 20px -10px",
      },
      "& .MuiPickersCalendarHeader-labelContainer": {
        color: "#383838",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        textTransform: "capitalize",
        fontSize: "16px",
        fontWeight: 600,
      },
      "& .MuiPickersArrowSwitcher-root": {
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        "& button": {
          backgroundColor: "transparent",
          color: "#555555",
          padding: "5px",
          "&:disabled": {
            color: "#E1E1E1",
          },
        },
      },
      "& .MuiDayCalendar-slideTransition": {
        height: "314px",
      },
      "& .MuiDayCalendar-header": {
        color: "#383838",
        gap: "1px",
        marginBottom: "20px",
        "& .MuiDayCalendar-weekDayLabel": {
          color: "#383838",
          backgroundColor: "#F6F6F6",
          margin: 0,
          width: isMobile ? "35px" : isMiddle ? "40px" : "44px",
          height: isMobile ? "35px" : isMiddle ? "40px" : "44px",
        },
      },
      "& .MuiDayCalendar-monthContainer": {
        display: "grid",
        gap: "20px",
        width: "333px",
        "& button": {
          borderRadius: 0,
        },
        "& .MuiDayCalendar-weekContainer": {
          gap: "1px",
          margin: isMobile ? "0 0 0 -80px" : 0,
        },
        "& .MuiPickersDay-root": {
          width: isMobile ? "35px" : isMiddle ? "40px" : "44px",
          height: isMobile ? "35px" : isMiddle ? "40px" : "44px",
          margin: 0,
        },
        ".MuiPickersDay-current, .MuiPickersDay-daySelected": {
          backgroundColor: "#E31340 !important",
          color: "#FFFFFF !important",
        },
      },
    },
  };

  const { t } = useTranslation();

  const disableDate = (date: Dayjs) => {
    const dateString = date.format("YYYY-MM-DD");
    return !!fullSlots && fullSlots.includes(dateString);
  };
  return (
    <Box className={style.wrapper}>
      <Typography sx={styles.text}>
        <img alt="colorCalendar" src={colorCalendar} style={{ marginRight: "10px" }} />{" "}
        {t("calendar_title_data")}
      </Typography>
      <Box className={style.dialog}>
        <DateCalendar
          value={selectedValue ? dayjs(selectedValue) : null}
          onChange={(value: Dayjs | null) => value && onClick(value.toDate())}
          disablePast={true}
          shouldDisableDate={(date: Dayjs) => disableDate(date)}
          views={["day"]}
          sx={styles.calendar}
          className={style.calendar}
        />
      </Box>
    </Box>
  );
};
