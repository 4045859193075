import React, { useEffect, useState } from "react";

//components
import { Box } from "@mui/material";
import styles from "./styles.module.css";

import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getTranslatedBadges } from "src/core/helpers/getTranslatedBadges";
import { Loc } from "src/core/types/anyTypes";

const Addvert = ({ background }: { background?: string }): JSX.Element | null => {
  const location = useLocation();
  const loc = sessionStorage.getItem("loc");
  const [isDesktop, setIsDesktop] = useState<boolean>(window.innerWidth > 768);
  const [appStore, setAppStore] = useState(
    getTranslatedBadges({
      isMobile: !isDesktop,
      language: loc as Loc,
      icon: "appstore",
    }),
  );
  const [gplay, setGplay] = useState(
    getTranslatedBadges({
      isMobile: !isDesktop,
      language: loc as Loc,
      icon: "gplay",
    }),
  );
  const { t } = useTranslation();
  const isFromApp = sessionStorage.getItem("isApp");
  const header = t("visit_free_title");
  const contentOne = t("visit_free_cont_one");
  const contentTwo = t("visit_free_cont_two");
  const contentThree = t("visit_free_cont_three");

  useEffect(() => {
    setAppStore(
      getTranslatedBadges({
        isMobile: !isDesktop,
        language: loc as Loc,
        icon: "appstore",
      }),
    );
    setGplay(
      getTranslatedBadges({
        isMobile: !isDesktop,
        language: loc as Loc,
        icon: "gplay",
      }),
    );
  }, [isDesktop, loc]);
  const getStyle = (): string => {
    if (location.pathname === "/map") {
      return styles.wrapper_map;
    } else if (location.pathname === "/travel" && isDesktop) {
      return styles.wrapper_travel;
    } else {
      return styles.wrapper;
    }
  };
  const getIconsStyle = (): string => {
    if (location.pathname === "/map") {
      return styles.buttons_map;
    } else {
      return styles.buttons;
    }
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 1023);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return !isFromApp ? (
    <Box className={getStyle()} sx={{ background: background || "#FFFF" }}>
      <Box className={styles.addvert_sub_wrapper}>
        <span className={styles.header} dangerouslySetInnerHTML={{ __html: header }} />
        <br />
        <span className={styles.content_text} dangerouslySetInnerHTML={{ __html: contentOne }} />
        <br />
        <span className={styles.content_text}>{contentTwo}</span>
        <br />
        <span className={styles.sub_context}>{contentThree}</span>
      </Box>
      <Box className={getIconsStyle()}>
        <a
          target="_blank"
          href="https://play.google.com/store/apps/details?id=cz.VisitJakubBrno.officialapp"
          rel="noreferrer"
        >
          <img src={gplay} alt="gplay" />
        </a>
        <a
          target="_blank"
          href="https://apps.apple.com/app/visit-jakub-brno/id6503604487"
          rel="noreferrer"
        >
          <img alt="appstore" src={appStore} />
        </a>
      </Box>
    </Box>
  ) : (
    <div></div>
  );
};

export default Addvert;
