import { Box, Link, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import logo from "../../../assets/logo_jakub_neg.svg";
import { useTranslation } from "react-i18next";
import phoneIcon from "../../../assets/phone-call.svg";
import mailIcon from "../../../assets/mail.svg";
import mailSmall from "../../../assets/smallMail.svg";
import facebookIconFooter from "../../../assets/facebook_white.svg";
import styles from "./styles.module.css";
import background from "../../../assets/foto-pozadi2@2x.png";
import ModalCondition from "src/components/atoms/ModalCondition";
import SecurityData from "src/components/atoms/SecurityData";
import { useNavigate } from "react-router-dom";
import { getTranslatedBadges } from "src/core/helpers/getTranslatedBadges";
import { Loc } from "core/types/anyTypes";

const Footer = React.forwardRef((props, ref) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const loc = sessionStorage.getItem("loc") || "cs";
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [, setOpenSecondModal] = useState<boolean>(false);
  const isFromApp = sessionStorage.getItem("isApp");
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [appStore, setAppStore] = useState(
    getTranslatedBadges({
      isMobile: isMobile,
      language: loc as Loc,
      icon: "appstore",
    }),
  );
  const [gplay, setGplay] = useState(
    getTranslatedBadges({
      isMobile: isMobile,
      language: loc as Loc,
      icon: "gplay",
    }),
  );
  const updateMedia = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);
  useEffect(() => {
    setAppStore(
      getTranslatedBadges({
        isMobile: isMobile,
        language: loc as Loc,
        icon: "appstore",
      }),
    );
    setGplay(
      getTranslatedBadges({
        isMobile: isMobile,
        language: loc as Loc,
        icon: "gplay",
      }),
    );
  }, [isMobile, loc]);

  const modileNotice = isMobile ? t("download_application2") : t("download_application1");
  const email = "info@visitjakub.cz";
  const phone = "+420 736 117 117";
  if (isFromApp || (isFromApp && location.pathname.includes("/status"))) {
    return null;
  }
  return (
    <Box className={styles.box} ref={ref}>
      <Box className={styles.imageContainer}>
        <img src={background} className={styles.image} style={{ backgroundRepeat: "repeat-x" }} />
      </Box>
      <Box className={styles.overlayPic}></Box>

      <Box className={styles.overlay}>
        <div className={styles.boxHeader}>
          <div className={styles.boxHeaderLogo}>
            <img src={logo} className={styles.logo} />
            <div className={styles.socialIconsMobile}>
              <a
                target={"_blank"}
                rel={"noreferrer"}
                href="https://www.facebook.com/visitjakubbrno"
              >
                <img src={facebookIconFooter} style={{ marginRight: "20px" }} />
              </a>
            </div>
          </div>
          <div className={styles.phoneEmail}>
            <div style={{ cursor: "pointer" }}>
              <a
                href={`tel:${phone}`}
                className={styles.icons}
                style={{ marginRight: "50px", cursor: "pointer" }}
              >
                <img src={phoneIcon} style={{ marginRight: "15px" }} />
                <Typography
                  style={{ textDecoration: "none !important", color: "white", cursor: "pointer" }}
                  variant="h2"
                >
                  {phone}
                </Typography>
              </a>
            </div>
            <div style={{ cursor: "pointer" }}>
              <a
                href={`mailto:${email}`}
                className={styles.icons}
                style={{ marginRight: "50px", cursor: "pointer" }}
              >
                <img src={isMobile ? mailSmall : mailIcon} style={{ marginRight: "15px" }} />
                <Typography
                  variant="h2"
                  style={{ textDecoration: "none !important", color: "white", cursor: "pointer" }}
                  sx={{ textDecoration: "none !important" }}
                >
                  {email}
                </Typography>
              </a>
            </div>
            <div className={styles.socialIconsDesktop}>
              <a
                target={"_blank"}
                rel={"noreferrer"}
                href="https://www.facebook.com/visitjakubbrno"
              >
                <img src={facebookIconFooter} style={{ marginRight: "20px" }} />
              </a>
            </div>
          </div>
        </div>
        <Box className={styles.boxBody}>
          <div className={styles.line}></div>
          <Box className={styles.gridItem}>
            <div className={styles.textBox}>
              <span
                dangerouslySetInnerHTML={{ __html: t("footer_title") }}
                className={styles.text1}
              />
              <span
                dangerouslySetInnerHTML={{ __html: t("footer_address") }}
                className={styles.text}
              />
              <span dangerouslySetInnerHTML={{ __html: t("footer_ico") }} className={styles.text} />
            </div>
            <Box className={styles.helpDesk}>
              <span className={styles.text1}>{t("need_help")}</span>
              <a
                href={"https://visitjakubhelpdesk.hipporello.net/desk"}
                target={"_blank"}
                rel={"noreferrer"}
                className={styles.helpDesk_hypertext}
              >
                {t("customer_service")}
              </a>
            </Box>

            <div className={styles.textBox}>
              <span
                className={styles.subtitle}
                dangerouslySetInnerHTML={{ __html: modileNotice }}
              />
              <Box className={styles.ikony}>
                <a
                  target="_blank"
                  href="https://play.google.com/store/apps/details?id=cz.VisitJakubBrno.officialapp"
                  rel="noreferrer"
                >
                  <img src={gplay} className={styles.ikon} />
                </a>
                <a
                  target="_blank"
                  href="https://apps.apple.com/app/visit-jakub-brno/id6503604487"
                  rel="noreferrer"
                >
                  <img src={appStore} />
                </a>
              </Box>
            </div>
          </Box>
          <div className={styles.line}></div>
        </Box>
        <Box className={styles.boxFooter}>
          <Box className={styles.gridItem_bottom}>
            <Box className={styles.rights}>
              <Link
                className={styles.right}
                color="inherit"
                style={{ textDecoration: "none", cursor: "pointer", fontSize: "14px" }}
                href={
                  "https://docs.google.com/document/d/1LnHoNo55yx8gXgawdA9cq-gku8rHzbbpeEiye-0WbQc/edit#heading=h.4iw50apfwhgj"
                }
                target={"_blank"}
              >
                {t("operating_rules")}
              </Link>
              <Typography
                className={styles.right}
                color="inherit"
                style={{ textDecoration: "none", cursor: "pointer", fontSize: "14px" }}
                onClick={() => setOpenModal(true)}
              >
                {[t("cookies")]}
              </Typography>
              {/*<ModalComponent*/}

              <Typography
                className={styles.right}
                color="inherit"
                style={{ textDecoration: "none", cursor: "pointer", fontSize: "14px" }}
                onClick={() => navigate("/privacy-policy")}
              >
                {[t("personal_info")]}
              </Typography>
              <ModalCondition open={openModal} handleClose={() => setOpenModal(false)}>
                {({ handleClose }) => <SecurityData handleClose={handleClose} isModal={true} />}
              </ModalCondition>

              <Typography
                className={styles.right}
                color="inherit"
                style={{ textDecoration: "none", cursor: "pointer", fontSize: "14px" }}
                onClick={() => setOpenSecondModal(true)}
              >
                <a
                  className={styles.link}
                  href="https://docs.google.com/document/d/1nAL-uK1yiYK_QTyyxRDXfOo6ZrevjXBPLOUYQ6SEiw8/edit?usp=sharing"
                  target="_blank"
                  rel="noreferrer"
                >
                  {[t("conditions")]}
                </a>
              </Typography>
            </Box>
            <Typography variant="body2" className={styles.rights_reserved}>
              {[t("rights_reserved")]}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
});

Footer.displayName = "Footer";
export default Footer;
