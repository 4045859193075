import React from "react";

export const InfoIcon = (): JSX.Element => {
  return (
    <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.0004 22.8333C16.2839 22.8333 16.5214 22.7375 16.7128 22.5458C16.9043 22.3542 17 22.1167 17 21.8333V16.1666C17 15.8833 16.9041 15.6458 16.7124 15.4542C16.5206 15.2625 16.283 15.1667 15.9996 15.1667C15.7161 15.1667 15.4787 15.2625 15.2872 15.4542C15.0958 15.6458 15 15.8833 15 16.1666V21.8333C15 22.1167 15.0959 22.3542 15.2876 22.5458C15.4794 22.7375 15.717 22.8333 16.0004 22.8333ZM16 12.8846C16.3051 12.8846 16.5609 12.7814 16.7673 12.575C16.9737 12.3686 17.0769 12.1129 17.0769 11.8077C17.0769 11.5026 16.9737 11.2469 16.7673 11.0404C16.5609 10.834 16.3051 10.7308 16 10.7308C15.6949 10.7308 15.4391 10.834 15.2327 11.0404C15.0263 11.2469 14.9231 11.5026 14.9231 11.8077C14.9231 12.1129 15.0263 12.3686 15.2327 12.575C15.4391 12.7814 15.6949 12.8846 16 12.8846ZM16.0022 29.1666C14.2503 29.1666 12.6036 28.8342 11.0621 28.1693C9.52057 27.5044 8.17966 26.6021 7.03937 25.4623C5.89906 24.3225 4.99632 22.9822 4.33114 21.4413C3.66596 19.9005 3.33337 18.2542 3.33337 16.5022C3.33337 14.7503 3.66582 13.1036 4.33071 11.5621C4.9956 10.0206 5.89793 8.67967 7.03771 7.53938C8.17751 6.39906 9.51783 5.49632 11.0587 4.83114C12.5995 4.16596 14.2459 3.83337 15.9978 3.83337C17.7497 3.83337 19.3964 4.16582 20.9379 4.83071C22.4794 5.4956 23.8204 6.39793 24.9606 7.53771C26.101 8.67751 27.0037 10.0178 27.6689 11.5587C28.3341 13.0995 28.6666 14.7459 28.6666 16.4978C28.6666 18.2497 28.3342 19.8964 27.6693 21.4379C27.0044 22.9794 26.1021 24.3204 24.9623 25.4606C23.8225 26.601 22.4822 27.5037 20.9413 28.1689C19.4005 28.8341 17.7542 29.1666 16.0022 29.1666ZM16 27.1667C18.9778 27.1667 21.5 26.1333 23.5667 24.0667C25.6333 22 26.6667 19.4778 26.6667 16.5C26.6667 13.5222 25.6333 11 23.5667 8.93334C21.5 6.86667 18.9778 5.83334 16 5.83334C13.0222 5.83334 10.5 6.86667 8.43334 8.93334C6.36667 11 5.33334 13.5222 5.33334 16.5C5.33334 19.4778 6.36667 22 8.43334 24.0667C10.5 26.1333 13.0222 27.1667 16 27.1667Z"
        fill="#E31240"
      />
    </svg>
  );
};
