import React from "react";
import styles from "./styles.module.css";
import { useTranslation } from "react-i18next";

const DottedComponent = ({ items }: { items: string[] }): JSX.Element => {
  const { t } = useTranslation();
  return (
    <ul className={styles.wrapper}>
      {items.map(
        (item, index) =>
          t(item).length > 0 && (
            <li
              key={index}
              className={styles.list_item}
              dangerouslySetInnerHTML={{ __html: t(item) }}
            />
          ),
      )}
    </ul>
  );
};

export default DottedComponent;
