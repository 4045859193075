import React from "react";
import styles from "./styles.module.css";
import { horizontalGallery } from "src/core/helpers/horizontalGallery";

const UnsualGallery = (): JSX.Element => {
  return (
    <div className={styles.wrapper}>
      <img alt="big_photo" className={styles.big_photo} src={horizontalGallery[0]}></img>
      <div className={styles.small_img_container}>
        <img className={styles.small_photo} src={horizontalGallery[1]} alt="" />
        <img className={styles.small_photo} src={horizontalGallery[2]} alt="" />
      </div>
    </div>
  );
};

export default UnsualGallery;
