import { useMutation } from "react-query";
import axios from "axios";
import { GROUP_FORM } from "src/core/endpoints";
interface IForm {
  name: string;
  email: string;
  phone_number: string | number;
  amount: string | number;
}

export const useSentGroupForm = () => {
  return useMutation((mutateData: IForm) => {
    return axios.post(`${window.BASE_API_URL}/${GROUP_FORM}`, mutateData);
  });
};
