import { Action, DateType } from "src/core/types/reduxTypes";
import { getFormattedDate } from "src/core/helpers/formatDate";

const initialState: { selectedDate: string | Date } = {
  selectedDate: getFormattedDate(new Date()),
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export const DateReducer = (state = initialState, action: Action<DateType, any>) => {
  switch (action.payload) {
    case "SELECT_DATE":
      return {
        ...state,
        selectedDate: action.payload,
      };
    default:
      return state;
  }
};
