import style from "./styles.module.css";
import React, { useState } from "react";
import logo from "../../../assets/logo_jakub_pos.svg";
import { clearFormValues, useFormValuesContext } from "src/core/context";
import { useGetRemoveReservation } from "src/core/queries/useGetRemoveReservation";
import { useNavigate } from "react-router-dom";
import { HOMEPAGE_ROUTE } from "src/core/routing";
import { resetTicket } from "src/core/redux/actions/selectTicket";
import { useDispatch } from "react-redux";

export const Logo: React.FC = () => {
  const dipatch = useDispatch();
  const { formValues, setFormValues } = useFormValuesContext();
  const navigate = useNavigate();
  const [executeQuery, setExecuteQuery] = useState<boolean>(false);
  const reservationId = formValues.reservation || "";
  const { refetch } = useGetRemoveReservation({ reservationId, executeQuery });
  const currentLoc = sessionStorage.getItem("loc") || "cs";
  const handleClick = () => {
    setExecuteQuery(true);
    void refetch();
    setFormValues(clearFormValues);
    sessionStorage.clear();
    sessionStorage.setItem("loc", currentLoc);
    dipatch(resetTicket());
    navigate(HOMEPAGE_ROUTE);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className={style.logoWrapper} onClick={handleClick} style={{ cursor: "pointer" }}>
      <img src={logo} alt="logo" className={style.logo} />
    </div>
  );
};
