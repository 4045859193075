import React from "react";
import { Box } from "@mui/material";
import styles from "./styles.module.css";

interface ITimePreferButtonProps extends React.ComponentProps<"div"> {
  isSelected: boolean;
  children: React.ReactNode;
  onClick?: () => void;
}

const TimePreferButton: React.FC<ITimePreferButtonProps> = ({ children, isSelected, onClick }) => {
  return (
    <Box className={`${styles.wrapper} ${isSelected ? styles.active : ""}`} onClick={onClick}>
      {children}
    </Box>
  );
};

export default TimePreferButton;
