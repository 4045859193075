import { Box, Checkbox, FormControlLabel } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { useFormValuesContext } from "src/core/context";
import styless from "./styles.module.css";
import ModalCondition from "src/components/atoms/ModalCondition";
import SecurityVisit from "src/components/atoms/SecurityVisit";
import SecurityData from "src/components/atoms/SecurityData";
import SecurityVisitMobile from "src/components/atoms/SecurityVisitMobile";

export const CheckboxComponent: React.FC<{
  onConsentChange: (consentOne: boolean, consentTwo: boolean) => void;
}> = ({ onConsentChange }) => {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openSecondModal, setOpenSecondModal] = useState<boolean>(false);
  const { formValues, setFormValues } = useFormValuesContext();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const updateMedia = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  const agreementForm = useForm({
    defaultValues: {
      consentOne: false,
      consentTwo: false,
      consentThree: false,
    },
    reValidateMode: "onChange",
    mode: "onSubmit",
  });
  const { control } = agreementForm;

  const handleConsentThreeChange = useCallback(
    (value: boolean) => {
      const newFormValues = {
        ...formValues,
        send_promo: value,
      };
      setFormValues(newFormValues);
      sessionStorage.setItem("send_promo", value.toString());
    },
    [formValues, setFormValues],
  );

  const onCheckboxChange = () => {
    const consentOneChecked = agreementForm.getValues("consentOne");
    const consentTwoChecked = agreementForm.getValues("consentTwo");
    sessionStorage.setItem("consentOne", String(agreementForm.getValues("consentOne")));
    sessionStorage.setItem("consentTwo", String(agreementForm.getValues("consentTwo")));
    onConsentChange(consentOneChecked, consentTwoChecked);
  };

  const handleCheckboxChange =
    (value: "consentOne" | "consentTwo" | "consentThree") =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      agreementForm.setValue(value, event.target.checked);
      onCheckboxChange();
    };

  const isConsentOneChecked = () => {
    const isConsent = sessionStorage.getItem("consentOne");
    return isConsent === "true";
  };

  const isConsentTwoChecked = () => {
    const isConsent = sessionStorage.getItem("consentTwo");
    return isConsent === "true";
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);

  return (
    <Box>
      <form>
        <Box className={styless.rowAgreement}>
          <Controller
            name="consentOne"
            control={control}
            rules={{
              required: t("must_agree_to_terms_conditions"),
              validate: (v) => v === true || t("must_agree_to_terms_conditions"),
            }}
            render={() => (
              <FormControlLabel
                label={
                  <div
                    style={{ display: "flex", flexDirection: "column", flexWrap: "wrap" }}
                    className={styless.textBlock}
                  >
                    <span className={styless.text}>
                      {`${t("agree_with")} ${t("with")}`}
                      <a
                        target="_blank"
                        href={
                          "https://docs.google.com/document/d/1nAL-uK1yiYK_QTyyxRDXfOo6ZrevjXBPLOUYQ6SEiw8/edit?usp=sharing"
                        }
                        className={styless.underlineText}
                        rel="noreferrer"
                        dangerouslySetInnerHTML={{ __html: t("agreement_with_conditions") }}
                      />
                      <span> {t("and")} </span>
                      <br />
                      <span
                        className={styless.underlineText}
                        onClick={() => setOpenModal(true)}
                        dangerouslySetInnerHTML={{ __html: t("agreement_visit_conditions") }}
                      />
                    </span>
                  </div>
                }
                control={
                  <Checkbox
                    checked={isConsentOneChecked()}
                    onChange={handleCheckboxChange("consentOne")}
                  />
                }
              />
            )}
          />
        </Box>
        <ModalCondition open={openModal} handleClose={() => setOpenModal(false)}>
          {({ handleClose }) =>
            isMobile ? (
              <SecurityVisitMobile handleClose={handleClose} />
            ) : (
              <SecurityVisit handleClose={handleClose} />
            )
          }
        </ModalCondition>
        <Box className={styless.rowAgreement}>
          <Controller
            name="consentTwo"
            control={control}
            rules={{
              required: t("must_agree_to_terms_conditions"),
              validate: (v) => v === true || t("must_agree_to_terms_conditions"),
            }}
            render={() => (
              <FormControlLabel
                label={
                  <div style={{ display: "flex" }} className={styless.textBlock}>
                    <span className={styless.text}>
                      {`${t("agree_with")} ${t("with_se")}`}
                      <span
                        className={styless.underlineText}
                        onClick={() => setOpenSecondModal(true)}
                        dangerouslySetInnerHTML={{ __html: t("agreement_with_personal_data") }}
                      />
                    </span>
                  </div>
                }
                control={
                  <Checkbox
                    checked={isConsentTwoChecked()}
                    onChange={handleCheckboxChange("consentTwo")}
                  />
                }
              />
            )}
          />
        </Box>
        <ModalCondition open={openSecondModal} handleClose={() => setOpenSecondModal(false)}>
          {({ handleClose }) => <SecurityData handleClose={handleClose} isModal={true} />}
        </ModalCondition>

        <Box className={styless.rowAgreement}>
          <Controller
            name="consentThree"
            control={control}
            render={({ field: { onChange, value } }) => (
              <FormControlLabel
                label={
                  <div className={styless.textBlock}>
                    <span
                      className={styless.text}
                      dangerouslySetInnerHTML={{ __html: t("dicline_from_commercial_offers") }}
                    />
                  </div>
                }
                control={
                  <Checkbox
                    checked={value}
                    onChange={(e) => {
                      onChange(e);
                      handleConsentThreeChange(e.target.checked);
                    }}
                  />
                }
              />
            )}
          />
        </Box>
      </form>
    </Box>
  );
};
