import React from "react";

//components
import styles from "./styles.module.css";
import { Box } from "@mui/material";

const InformationBlock = ({
  header,
  content,
}: {
  header: string;
  content: string;
}): JSX.Element => {
  return (
    <Box className={styles.wrapper}>
      <Box className={styles.header}>
        <span className={styles.text_white}>{header}</span>
      </Box>
      <Box className={styles.content}>
        <span className={styles.text_black}>{content}</span>
      </Box>
    </Box>
  );
};

export default InformationBlock;
