import React from "react";
import styles from "./styles.module.css";
import logo from "../../../assets/logo_pos.svg";
import { useTranslation } from "react-i18next";
const ReceiptDesktop = (): JSX.Element => {
  const totalTickets = sessionStorage.getItem("totalTickets");
  const totalPrice = sessionStorage.getItem("totalPrice");
  const { t } = useTranslation();
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <img className={styles.logo} src={logo} alt="logo" />
        <span className={styles.header_text}>{t("tickets2")}</span>
      </div>
      <div className={styles.container_wrapper}>
        <div className={styles.container}>
          <span className={styles.container_text}>{t("count_of_tickets")}</span>
          <span className={styles.container_text}>{totalTickets}</span>
        </div>
        <div className={styles.line} />
        <div className={styles.footer}>
          <span className={styles.footer_text}>{t("payment_overall")}</span>
          <span className={styles.footer_text}>{totalPrice}</span>
        </div>
      </div>
    </div>
  );
};

export default ReceiptDesktop;
