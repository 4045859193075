import React from "react";

const ArrowDown = (): JSX.Element => {
  return (
    <svg
      version="1.0"
      style={{ rotate: "90deg" }}
      xmlns="http://www.w3.org/2000/svg"
      width="42px"
      height="42px"
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="#B9B9B9"
        stroke="none"
      >
        <path
          d="M3840 2730 l0 -170 -1735 0 -1735 0 0 -130 0 -130 1735 0 1735 0 0
-170 0 -170 472 236 473 236 -465 234 c-256 128 -468 233 -472 233 -5 1 -8
-75 -8 -169z"
        />
      </g>
    </svg>
  );
};

export default ArrowDown;
