import React from "react";

//components
import { Box } from "@mui/material";
import styles from "./styles.module.css";
import ArrowRight from "src/components/icons/ArrowRight";

const RedButtonMore = ({
  color,
  size,
}: {
  color?: string;
  size?: { w: string; h: string };
}): JSX.Element => {
  return (
    <Box className={styles.button}>
      <ArrowRight color={color} size={size} />
    </Box>
  );
};

export default RedButtonMore;
