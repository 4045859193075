import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const CrossThinIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon {...props} width="25.414" height="25.414" viewBox="0 0 25.414 25.414">
    <svg xmlns="http://www.w3.org/2000/svg">
      <g id="close" transform="translate(-360.793 -593.793)">
        <line
          id="Line_10"
          data-name="Line 10"
          x1="24"
          y2="24"
          transform="translate(361.5 594.5)"
          fill="none"
          stroke="#e6264c"
          strokeLinecap="round"
          strokeWidth="1"
        />
        <line
          id="Line_11"
          data-name="Line 11"
          x2="24"
          y2="24"
          transform="translate(361.5 594.5)"
          fill="none"
          stroke="#e6264c"
          strokeLinecap="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  </SvgIcon>
);
