import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import styles from "src/components/atoms/GalleryComponent/styles.module.css";

const DefaultGalleryComponent = ({
  basicPhoto,
  objectFit,
  height,
}: {
  basicPhoto: string;
  objectFit?: "fill" | "contain" | "cover" | "none" | "scale-down";
  height?: number;
}): JSX.Element => {
  const [isDesktop, setIsDesktop] = useState<boolean>(window.innerWidth > 768);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 1023);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Box>
      <img
        className={styles.basic_image}
        src={basicPhoto}
        style={{
          objectFit: `${objectFit ? objectFit : "cover"}`,
          height: `${height ? height : isDesktop ? 340 : 165}px`,
        }}
        alt="basic_image"
      />
    </Box>
  );
};

export default DefaultGalleryComponent;
