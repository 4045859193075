import React from "react";

export const HeadphonesIcon = (): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32" fill="none">
      <path
        d="M9.47433 27.3333H7.57697C6.90345 27.3333 6.33337 27.1 5.8667 26.6333C5.40003 26.1666 5.1667 25.5965 5.1667 24.923V16C5.1667 14.4273 5.46414 12.9538 6.05903 11.5795C6.6539 10.2051 7.46243 9.00684 8.48463 7.98462C9.50685 6.96242 10.7051 6.15389 12.0795 5.55902C13.4539 4.96413 14.9274 4.66669 16.5 4.66669C18.0726 4.66669 19.5461 4.96413 20.9205 5.55902C22.2949 6.15389 23.4931 6.96242 24.5154 7.98462C25.5376 9.00684 26.3461 10.2051 26.941 11.5795C27.5359 12.9538 27.8333 14.4273 27.8333 16V24.923C27.8333 25.5965 27.6 26.1666 27.1333 26.6333C26.6666 27.1 26.0965 27.3333 25.423 27.3333H23.5257C22.8607 27.3333 22.2928 27.0978 21.8218 26.6269C21.3509 26.1559 21.1154 25.588 21.1154 24.923V20.3077C21.1154 19.6428 21.3509 19.0748 21.8218 18.6039C22.2928 18.1329 22.8607 17.8975 23.5257 17.8975H25.8333V16C25.8333 13.4 24.9278 11.1944 23.1167 9.38332C21.3056 7.57221 19.1 6.66665 16.5 6.66665C13.9 6.66665 11.6944 7.57221 9.88333 9.38332C8.07222 11.1944 7.16666 13.4 7.16666 16V17.8975H9.47433C10.1393 17.8975 10.7072 18.1329 11.1782 18.6039C11.6491 19.0748 11.8846 19.6428 11.8846 20.3077V24.923C11.8846 25.588 11.6491 26.1559 11.1782 26.6269C10.7072 27.0978 10.1393 27.3333 9.47433 27.3333ZM9.47433 19.8974H7.16666V24.923C7.16666 25.0256 7.2094 25.1196 7.29486 25.2051C7.38035 25.2906 7.47439 25.3333 7.57697 25.3333H9.47433C9.594 25.3333 9.6923 25.2949 9.76923 25.2179C9.84617 25.141 9.88463 25.0427 9.88463 24.923V20.3077C9.88463 20.188 9.84617 20.0897 9.76923 20.0128C9.6923 19.9359 9.594 19.8974 9.47433 19.8974ZM23.1154 20.3077V24.923C23.1154 25.0427 23.1538 25.141 23.2308 25.2179C23.3077 25.2949 23.406 25.3333 23.5257 25.3333H25.423C25.5256 25.3333 25.6196 25.2906 25.7051 25.2051C25.7906 25.1196 25.8333 25.0256 25.8333 24.923V19.8974H23.5257C23.406 19.8974 23.3077 19.9359 23.2308 20.0128C23.1538 20.0897 23.1154 20.188 23.1154 20.3077ZM9.47433 19.8974H9.88463H7.16666H9.47433Z"
        fill="#E31240"
      />
    </svg>
  );
};
