import { TicketFilterPayload, TicketFilterType } from "src/core/types/reduxTypes";

export const ticketFilterAction = (
  data: TicketFilterPayload,
): { type: TicketFilterType; payload: TicketFilterPayload } => {
  return {
    type: "SELECT_FILTER",
    payload: data,
  };
};
