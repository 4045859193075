import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";

//components
import { Box } from "@mui/material";
import RedButtonMore from "src/components/atoms/RedButtonMore";
import { useNavigate } from "react-router-dom";
import UnusualGalleryComponent from "src/components/atoms/UnusualGalleryComponent";
import DefaultGalleryComponent from "src/components/atoms/DefaultGalleryComponent";
import { ArrowMoreLonger } from "src/components/icons/ArrowMoreLonger";
import { useTranslation } from "react-i18next";
// eslint-disable-next-line import/no-unresolved
import noPhoto from "../../../assets/Rectangle 26 (2).png";

const GalleryComponent = ({
  usual = false,
  basicPhoto,
  gallery,
  headerText,
  href,
  headerStyle,
  additionalCSS,
}: {
  usual?: boolean;
  basicPhoto?: string;
  gallery?: string[];
  headerText?: string;
  headerStyle?: React.CSSProperties;
  href: string;
  additionalCSS?: React.CSSProperties;
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 768);

  const updateMedia = (): void => {
    setIsMobile(window.innerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);
  const navigateTo = () => {
    navigate(href);
  };
  return (
    <Box
      style={{ marginBottom: "10px", ...headerStyle, ...additionalCSS, cursor: "pointer" }}
      onClick={navigateTo}
    >
      <Box className={styles.header}>
        <span className={styles.header_text}>{headerText}</span>
        <Box className={styles.more_wrapper}>
          {isMobile ? (
            <>
              <span className={styles.text_red}>{t("main_page_arrow_more")}</span>
              <RedButtonMore size={{ w: "30", h: "31" }} />
            </>
          ) : (
            <ArrowMoreLonger />
          )}
        </Box>
      </Box>
      {usual ? (
        <DefaultGalleryComponent basicPhoto={basicPhoto || noPhoto} />
      ) : (
        <UnusualGalleryComponent gallery={gallery} />
      )}
    </Box>
  );
};

export default GalleryComponent;
