import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import Footer from "src/components/molecules/Footer";
import mainPhoto from "../../../assets/Visualove_puda_u_Jakuba-5.jpg";
import appstore from "../../../assets/Download_on_the_App_Store_Badge_CZ_RGB_blk_092917.svg";
import { useNavigate } from "react-router-dom";
import SuccessIcon from "src/components/icons/SuccessIcon";
import { useTranslation } from "react-i18next";
import { getTranslatedBadges } from "src/core/helpers/getTranslatedBadges";
import { Loc } from "src/core/types/anyTypes";

const PageSuccessfulResultDesktop = (): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const loc = sessionStorage.getItem("loc") || "cs";

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const [appStore, setAppStore] = useState(
    getTranslatedBadges({
      isMobile: isMobile,
      language: loc as Loc,
      icon: "appstore",
    }),
  );

  const [gplay, setGplay] = useState(
    getTranslatedBadges({
      isMobile: isMobile,
      language: loc as Loc,
      icon: "gplay",
    }),
  );

  const updateMedia = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    setAppStore(
      getTranslatedBadges({
        isMobile: isMobile,
        language: loc as Loc,
        icon: "appstore",
      }),
    );
    setGplay(
      getTranslatedBadges({
        isMobile: isMobile,
        language: loc as Loc,
        icon: "gplay",
      }),
    );
  }, [isMobile, loc]);

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);
  return (
    <>
      <img className={styles.image} src={mainPhoto} alt="" />
      <div className={styles.wrapper}>
        <div className={styles.header} dangerouslySetInnerHTML={{ __html: t("payment_success") }} />
        <div className={styles.middle_icon}>
          <SuccessIcon />
        </div>
        <div className={styles.sub_descirption}>
          {t("next_info")}
          <br />
          {t("additional_info2")}
        </div>
        <div className={styles.dotted_line} />
        <div className={styles.description}>{t("download_application")}</div>
        <div className={styles.sub_descirption}>
          {t("have_tickets")} <br />
          {t("mobile_and_app")}
        </div>
        <div className={styles.icons}>
          <a
            target="_blank"
            href="https://play.google.com/store/apps/details?id=cz.VisitJakubBrno.officialapp"
            rel="noreferrer"
          >
            <img src={gplay} />
          </a>
          <a
            target="_blank"
            href="https://apps.apple.com/app/visit-jakub-brno/id6503604487"
            rel="noreferrer"
          >
            <img src={appStore} />
          </a>
        </div>
        <div className={styles.dotted_line} />
        <div className={styles.button_home} onClick={() => navigate("/")}>
          {t("home")}
        </div>
        <Footer />
      </div>
    </>
  );
};

export default PageSuccessfulResultDesktop;
