import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./App.css";

// INFO: declaration is in public/env.js
declare global {
  // tslint:disable-next-line
  interface Window {
    BASE_API_URL: string;
  }
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
