import styled from "styled-components";

interface IWrapper {
  flexDirection: string;
}

interface IContent {
  marginBottom?: number;
}

interface IPhoto {
  imgHeight: number;
}

export const Wrapper = styled.div<IWrapper>`
  display: flex;
  max-width: 1400px;
  margin-top: 80px;
  flex-direction: ${(props) => props.flexDirection};
  justify-content: center;
  align-items: flex-start;
  gap: 50px;
`;

export const Image = styled.img<IPhoto>`
  width: 550px;
  height: ${(props) => `${props.imgHeight}px`};
`;

export const Content = styled.div<IContent>`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px 15px 15px -5px;
  margin-bottom: ${(props) => `${props.marginBottom}px` || 0};
`;

export const Header = styled.span`
  color: #000;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 10px;
`;

export const TextWrapper = styled.div`
  width: 100%;
  max-height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

export const Text = styled.div`
  color: #2b2b2b;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
