import React from "react";
import styles from "./styles.module.css";
import { useTranslation } from "react-i18next";

const NumberedComponent = ({
  number,
  content,
}: {
  number: number;
  content: string | React.ReactNode;
}): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      {t(content as string) && <div className={styles.number}>{`${number}.`}</div>}
      {typeof content === "string" ? (
        <div className={styles.content} dangerouslySetInnerHTML={{ __html: content }} />
      ) : (
        content
      )}
    </div>
  );
};

export default NumberedComponent;
