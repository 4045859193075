import { useQuery } from "react-query";
import axios from "axios";
import { SLOTS_API_ROUTE } from "../endpoints";
import { Slot } from "../types";
import { getDateString } from "../helpers";

interface UseGetSlotsParams {
  id: string | null;
  date: Date | string;
  pos: string;
}

export const useGetSlots = ({ id, date, pos }: UseGetSlotsParams) => {
  const dateString = date instanceof Date ? getDateString(date) : date;

  const fetchSlots = async (): Promise<{
    slots: Slot[];
    requestDate: Date | string;
    pos: string;
  }> => {
    const res = await axios.get(
      `${window.BASE_API_URL}/${SLOTS_API_ROUTE}/${dateString}?pos=${pos}`,
    );
    return { slots: res.data.slots, requestDate: date, pos };
  };

  return useQuery(`fetchSlots-${id}-${date}-${pos}`, fetchSlots, {
    refetchOnWindowFocus: false,
    enabled: true,
  });
};
