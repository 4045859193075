import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { getTranslatedBadges } from "src/core/helpers/getTranslatedBadges";
import { Loc } from "src/core/types/anyTypes";

export const AdvertisingBoxSmall = () => {
  const { t } = useTranslation();
  const isFromApp = sessionStorage.getItem("isApp") || null;
  const loc = sessionStorage.getItem("loc");

  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768);
  const [appStore, setAppStore] = useState(
    getTranslatedBadges({
      isMobile: isMobile,
      language: loc as Loc,
      icon: "appstore",
    }),
  );
  const [gplay, setGplay] = useState(
    getTranslatedBadges({
      isMobile: isMobile,
      language: loc as Loc,
      icon: "gplay",
    }),
  );

  useEffect(() => {
    setAppStore(
      getTranslatedBadges({
        isMobile: isMobile,
        language: loc as Loc,
        icon: "appstore",
      }),
    );
    setGplay(
      getTranslatedBadges({
        isMobile: isMobile,
        language: loc as Loc,
        icon: "gplay",
      }),
    );
  }, [isMobile, loc]);
  const updateMedia = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  const mutateCss = (): string => {
    if ((loc === "en" || loc === "de") && !isMobile) {
      return styles.advertisingBoxMutated;
    }
    return styles.advertisingBox;
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);

  if (isFromApp || isMobile) {
    return null;
  }

  return (
    <Box className={mutateCss()}>
      <Box className={styles.titleBox}>
        <span
          className={styles.title}
          dangerouslySetInnerHTML={{ __html: t("visit_free_title") }}
        />
      </Box>
      <Box className={styles.subtitleBox}>
        <Box className={styles.boxNotice}>
          <span
            className={styles.subtitle}
            dangerouslySetInnerHTML={{ __html: t("visit_free_cont_one") }}
          />
          <br />
          <br />
          <span
            className={styles.subtitle2}
            dangerouslySetInnerHTML={{ __html: t("visit_free_cont_two") }}
          />
          <br />
          <br />
          <span
            className={styles.subtitle3}
            dangerouslySetInnerHTML={{ __html: t("visit_free_cont_three") }}
          />
        </Box>
      </Box>
      <Box className={styles.ikony}>
        <a href="https://play.google.com/store/apps/details?id=cz.VisitJakubBrno.officialapp">
          <img alt="gplay" src={gplay} />
        </a>
        <a href="https://apps.apple.com/app/visit-jakub-brno/id6503604487">
          <img alt="appstore" src={appStore} />
        </a>
      </Box>
    </Box>
  );
};
