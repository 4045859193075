import React, { useEffect, useRef } from "react";
import styles from "./styles.module.css";
import SubPageHeader from "src/components/atoms/SubPageHeader";
import MapGallery from "src/components/atoms/MapGallery";
import Footer from "src/components/molecules/Footer";
import Addvert from "src/components/atoms/Addvertisment";
import { useTranslation } from "react-i18next";

const PageMap = (): JSX.Element => {
  const { t } = useTranslation();
  const topRef = useRef<any>(null);

  useEffect(() => {
    if (topRef) {
      window.scrollTo({ top: topRef?.current.offsetTop - 150, behavior: "smooth" });
    }
  }, []);

  return (
    <div ref={topRef}>
      <SubPageHeader pageName={t("discover_church")} background={"#F8F8F8"} />
      <div className={styles.wrapper}>
        <Addvert background={"#F8F8F8"} />
        <MapGallery />
        <Footer />
      </div>
    </div>
  );
};

export default PageMap;
