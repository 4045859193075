import React from "react";

export const TacktickIcon = (): JSX.Element => {
  return (
    <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0137 27.7021L10.0273 18.7637L11.2812 13.75H7.5V11.75H9.5V9.75H7.5V7.75H5.5V9.75H3.5V11.75H5.5V13.75H1.7188L2.9727 18.7637L0.9863 27.7021L1.3673 30.75H0V32.75H13V30.75H11.6327L12.0137 27.7021ZM4.8021 19.75H8.1979L9.7535 26.75H3.2465L4.8021 19.75ZM8.7188 15.75L8.2188 17.75H4.7809L4.2809 15.75H8.7188ZM3.1327 28.75H9.8673L9.6173 30.75H3.3827L3.1327 28.75Z"
        fill="#B9B9B9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 0.75C16.4742 0.75 11.9959 5.23172 12 10.7575C12.0042 16.2833 16.4892 20.7583 22.015 20.75C27.5408 20.7417 32.0124 16.2533 32 10.7275C31.9876 5.21344 27.514 0.74999 22 0.75ZM29.9241 11.7725C29.7394 13.1895 29.178 14.5309 28.2983 15.657L26.9492 14.3086L25.5352 15.7227L26.8819 17.0688C25.7561 17.9448 24.4152 18.5014 23 18.68V16.75H21V18.68C19.5847 18.5012 18.2438 17.9445 17.1181 17.0684L18.4648 15.7223L17.0508 14.3082L15.7017 15.657C14.822 14.5309 14.2605 13.1895 14.0759 11.7725H16V9.7725H14.063C14.2356 8.34678 14.7928 6.99488 15.675 5.8616L17.0508 7.2373L18.4648 5.8232L17.0869 4.4453C18.2204 3.55981 19.5727 2.99788 21 2.8192V4.75H23V2.8192C24.4273 2.99788 25.7795 3.55981 26.9131 4.4453L25.5352 5.8232L26.9492 7.2373L28.325 5.8616C29.2071 6.99488 29.7643 8.34678 29.937 9.7725H28V11.7725H29.9241Z"
        fill="#E31340"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23 6.75H21V11.75H26V9.75H23V6.75Z"
        fill="#E31340"
      />
    </svg>
  );
};
