import React from "react";

//components
import {
  ArrowWrapper,
  MiddleContent,
  PageCounter,
  PageDot,
  PageDotWrapper,
  Wrapper,
  // eslint-disable-next-line import/namespace
} from "src/components/atoms/FullPagePagination/styledComponents";
import ArrowLeft from "src/components/icons/ArrowLeft";

const FullPagePagination = ({
  pages,
  currentPage,
  nextPage,
  prevPage,
}: {
  pages: number;
  currentPage: number;
  nextPage: () => void;
  prevPage: () => void;
}): JSX.Element => {
  const pageArray = Array.from(Array(pages).keys());

  return (
    <Wrapper>
      <ArrowWrapper active={currentPage !== 1} onClick={prevPage}>
        <ArrowLeft color={(currentPage === 1 && "#707070") || ""} />
      </ArrowWrapper>
      <MiddleContent>
        <PageCounter>
          {currentPage}/{pages}
        </PageCounter>
        <PageDotWrapper>
          {pageArray.map((index) => (
            <PageDot key={index} active={index + 1 === currentPage} />
          ))}
        </PageDotWrapper>
      </MiddleContent>
      <ArrowWrapper active={currentPage < pages} onClick={nextPage}>
        <ArrowLeft rotate={"0deg"} color={(currentPage >= pages && "#707070") || ""} />
      </ArrowWrapper>
    </Wrapper>
  );
};

export default FullPagePagination;
