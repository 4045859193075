import React from "react";

export const DownloadIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="41" height="40" viewBox="0 0 41 40" fill="none">
      <path
        d="M20.7979 30.053L8.29786 17.7704L11.7979 14.2084L18.2979 20.5953V0.574707H23.2979V20.5953L29.7979 14.2084L33.2979 17.7704L20.7979 30.053ZM5.79786 39.8791C5.14011 39.8884 4.4874 39.7652 3.87999 39.517C3.27257 39.2689 2.72334 38.9011 2.26619 38.4363C1.79318 37.9871 1.41885 37.4474 1.16631 36.8506C0.913773 36.2537 0.788384 35.6123 0.797863 34.966V27.5964H5.79786V34.966H35.7979V27.5964H40.7979V34.966C40.8073 35.6123 40.682 36.2537 40.4294 36.8506C40.1769 37.4474 39.8025 37.9871 39.3295 38.4363C38.8724 38.9011 38.3232 39.2689 37.7157 39.517C37.1083 39.7652 36.4556 39.8884 35.7979 39.8791H5.79786Z"
        fill="white"
      />
    </svg>
  );
};
