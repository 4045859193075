import axios from "axios";
import { CHECK_CAPACITY_API_ROUTE } from "../endpoints";
import { checkCapacity } from "../types/checkCapacity";
import { useQuery } from "react-query";

export const useGetCapacity = ({ reservationId, pos }: { reservationId: string; pos: string }) => {
  const fetchCapacity: () => Promise<checkCapacity> = async () => {
    if (reservationId.length > 0 && reservationId !== "null") {
      const res = await axios.get(
        `${window.BASE_API_URL}/${CHECK_CAPACITY_API_ROUTE}/${reservationId}?pos=${pos}`,
      );
      return res.data;
    }
  };

  return useQuery(`fetchCapacity-${reservationId}`, fetchCapacity, {
    refetchOnWindowFocus: false,
    retry: 4,
    enabled: true,
  });
};
