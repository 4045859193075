// eslint-disable-next-line import/no-named-as-default
import styled from "styled-components";

export const MainMiddleContentDesktop = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: 2000px) {
    width: 1920px;
  }
`;
