import styled from "styled-components";

interface IImage {
  ObjectFit: string;
}

export const Image = styled.img<IImage>`
  width: 90%;
  height: 380px;
  object-fit: ${(props) => props.ObjectFit || "contain"};
  @media (min-width: 1023px) {
    height: 100%;
  }
`;
