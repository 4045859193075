import React from "react";

export const GraphicIcon = (): JSX.Element => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="streamline:money-graph-arrow-increase-ascend-growth-up-arrow-stats-graph-right-grow">
        <g id="Group">
          <path
            id="Vector"
            d="M16.2852 6.67676H23.1423V13.5339"
            stroke="black"
            strokeWidth="1.71429"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_2"
            d="M23.1431 6.67676L13.4574 16.3625C13.2972 16.5195 13.0818 16.6075 12.8574 16.6075C12.6331 16.6075 12.4176 16.5195 12.2574 16.3625L8.31456 12.4196C8.15434 12.2626 7.93892 12.1746 7.71456 12.1746C7.49021 12.1746 7.27479 12.2626 7.11456 12.4196L0.857422 18.6768"
            stroke="black"
            strokeWidth="1.71429"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </svg>
  );
};
